import { Card, CardContent, CardMedia, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    stepCardMediaHolder: {
        padding: theme.spacing(2, 2, 0),
    },
    stepCardMedia: {
        backgroundSize: 'contain',
        height: 100,
    },
    stepCard: {
        maxWidth: 200,
        margin: 'auto',
    },
}));

export interface StepCardProps {
    image: string;
    imageAlt?: string;
    title: string;
    description: string;
}

const StepCard = (props: StepCardProps) => {
    const { image, imageAlt, title, description } = props;

    const classes = useStyles();

    return (
        <Card className={classes.stepCard} elevation={20}>
            <div className={classes.stepCardMediaHolder}>
                <CardMedia className={classes.stepCardMedia} image={image} title={imageAlt} />
            </div>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default StepCard;
