import { selectAuth } from './selectors';
import { AxiosConfig } from './../../models/axios-config';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { delay, put, select, take, takeEvery } from 'redux-saga/effects';
import i18n from '../../i18n';
import { LoginDTO, SignupDTO, Token } from '../../models';
import api, { setAuthorizationHeader, wakeUp } from '../../utils/api';
import { STORAGE } from '../../constants';
import { showMessage } from '../ui/actions';
import * as actions from './actions';
import { AuthActionTypes, ChangePasswordPayload, LoginPayload, SignupPayload } from './types';
import * as userActions from './user/actions';
import { UserActionTypes } from './user/types';

const loginApiURL = `/Token`;
const signupApiURL = `/Account/Register`;
const refreshTokenApiURL = '/Token/RefreshToken';
const revokeTokenApiURL = '/Account/Logout';
const changePasswordApiURL = '/Account/ChangePassword';

function* storeToken(tokenData: Token) {
    const expires = new Date(new Date().getTime() + tokenData.ExpiresIn * 1000);

    yield localStorage.setItem(STORAGE.token, tokenData.AccessToken);
    yield localStorage.setItem(STORAGE.expirationDate, expires.toISOString());
}

function* fetchUserSync() {
    yield put(userActions.getUser());
    const getUserResultAction: Action | PayloadAction<Error> = yield take([UserActionTypes.GET_USER_SUCCESS, UserActionTypes.GET_USER_FAIL]);
    if (getUserResultAction.type === UserActionTypes.GET_USER_FAIL) {
        throw (getUserResultAction as PayloadAction<Error>).payload;
    }
}

export function* loginSaga(action: PayloadAction<LoginPayload>) {
    yield put(actions.loginStart());

    try {
        const requestBody: LoginDTO = {
            Username: action.payload.email,
            Password: action.payload.password,
        };
        const response: AxiosResponse<Token> = yield api.post(loginApiURL, requestBody, { handleError: false } as AxiosConfig);
        yield storeToken(response.data);

        yield setAuthorizationHeader(response.data.AccessToken);

        yield put(actions.setTokenExpirationTimeout(response.data.ExpiresIn * 1000));
        yield put(actions.setToken(response.data.AccessToken));

        yield fetchUserSync();

        yield put(actions.loginSuccess());
    } catch (error) {
        i18n.loadNamespaces('serverError');

        yield put(actions.loginFail({ status: error.status, message: i18n.t('serverError:incorrect_email_password') }));
    }
}

export function* setTokenExpTimeoutSaga(action: PayloadAction<number>) {
    yield delay(action.payload);
    yield put(actions.refreshToken());
}

export function* refreshTokenSaga() {
    try {
        const response: AxiosResponse<Token> = yield api.post(refreshTokenApiURL);
        yield storeToken(response.data);

        yield setAuthorizationHeader(response.data.AccessToken);

        yield put(actions.setTokenExpirationTimeout(response.data.ExpiresIn * 1000));
        yield put(actions.setToken(response.data.AccessToken));
        return true;
    } catch (e) {
        yield put(actions.logout());
        return false;
    }
}

export function* revokeTokenSaga() {
    const token = yield localStorage.getItem(STORAGE.token);

    if (token) {
        api.post(revokeTokenApiURL);
    }
}

export function* logoutSaga() {
    yield revokeTokenSaga();

    yield setAuthorizationHeader(null);
    yield localStorage.removeItem(STORAGE.token);
    yield localStorage.removeItem(STORAGE.expirationDate);
    yield put(actions.logoutDone());
}

export function* initAuthSaga() {
    const token = yield localStorage.getItem(STORAGE.token);
    if (!token) {
        yield put(actions.logout());
    } else {
        yield setAuthorizationHeader(token);

        const expirationDate = yield new Date(localStorage.getItem(STORAGE.expirationDate) || '');
        if (expirationDate > new Date()) {
            try {
                yield put(actions.initAuthStart());

                yield put(actions.setToken(token));
                yield put(actions.setTokenExpirationTimeout(expirationDate.getTime() - new Date().getTime()));

                yield fetchUserSync();
            } catch (e) {
                yield put(actions.logout());
            }
        } else {
            const refreshed = yield refreshTokenSaga();
            if (refreshed) {
                yield fetchUserSync();
            }
        }
    }
    yield put(actions.initAuthDone());
}

export function* ensureServer() {
    const { isAuthenticated } = yield select(selectAuth);
    if (!isAuthenticated) {
        wakeUp();
    }
}

export function* signupSaga(action: PayloadAction<SignupPayload>) {
    yield put(actions.signupStart());
    try {
        const requestBody: SignupDTO = {
            UserName: action.payload.userName,
            Email: action.payload.email,
            Password: action.payload.password,
            ConfirmPassword: action.payload.confirmPassword,
        };

        const response: AxiosResponse<Token> = yield api.post(signupApiURL, requestBody, { handleError: false } as AxiosConfig);
        yield storeToken(response.data);

        yield setAuthorizationHeader(response.data.AccessToken);

        yield put(actions.setTokenExpirationTimeout(response.data.ExpiresIn * 1000));
        yield put(actions.setToken(response.data.AccessToken));

        yield fetchUserSync();

        yield put(actions.signupSuccess());
    } catch (error) {
        if (error.isAxiosError) {
            const status = error.response && error.response.status;
            if (error.response) {
                if (error.response.data && error.response.data.DuplicateEmail) {
                    yield put(actions.signupFail({ status: 400, message: i18n.t('serverError:email_already_taken') }));
                } else {
                    const message = error.response ? error.response.data.Message : error.message;
                    yield put(actions.signupFail({ status, message }));
                }
            }
        } else {
            yield put(actions.signupFail({ status: 500, message: 'Something went wrong.' }));
        }
    }
}

function* changePasswordSaga(action: PayloadAction<ChangePasswordPayload>) {
    try {
        yield put(actions.changePasswordStart());

        yield api.post(changePasswordApiURL, action.payload, { handleError: false } as AxiosConfig);

        yield put(push('/'));

        yield put(showMessage('success', i18n.t('password_updated')));
        yield put(actions.changePasswordDone());
    } catch ({ response }) {
        i18n.loadNamespaces('serverError');

        if (response.status === 400) {
            yield put(showMessage('error', i18n.t('serverError:incorrect_password')));
        }
        yield put(actions.changePasswordDone());
    }
}

export function* watchAuth() {
    yield takeEvery(AuthActionTypes.INIT, initAuthSaga);
    yield takeEvery(AuthActionTypes.LOGIN, loginSaga);
    yield takeEvery(AuthActionTypes.REFRESH_TOKEN, refreshTokenSaga);
    yield takeEvery(AuthActionTypes.SIGNUP, signupSaga);
    yield takeEvery(AuthActionTypes.SET_TOKEN_EXP_TIMEOUT, setTokenExpTimeoutSaga);
    yield takeEvery(AuthActionTypes.INIT_DONE, ensureServer);
    yield takeEvery(AuthActionTypes.LOGOUT, logoutSaga);
    yield takeEvery(AuthActionTypes.CHANGE_PASSWORD, changePasswordSaga);
}
