import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BooksFilter from '../../components/BooksFilter';
import Title from '../../components/Title';
import { MAX_SAMPLE_SIZE } from '../../constants';
import useDebounce from '../../hooks/debounce';
import { BooksOrder, BOOKS_NAMESPACE_KEY, Language } from '../../models';
import { selectAuth } from '../../store/auth/selectors';
import { uploadSample } from '../../store/auth/user/actions';
import { getBooks, resetBooks, searchBooks, setBooksLanguage, setBooksOrder } from '../../store/books/actions';
import {
    selectAllBooksIds,
    selectBooks,
    selectBooksLanguage,
    selectBooksOrderBy,
    selectBooksPage,
    selectBooksPerPage,
} from '../../store/books/selectors';
import { setError } from '../../store/error/actions';
import { setPage } from '../../store/paging/actions';
import { selectSamplesByIds } from '../../store/samples/selectors';
import AvailableBooks from './AvailableBooks';
import NotConfirmedSection from './NotConfirmedSection';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        position: 'relative',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    bookList: {
        marginBottom: theme.spacing(2),
    },
    select: {
        minWidth: 200,
        margin: theme.spacing(2),
    },
}));

const Dashboard = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { t, i18n } = useTranslation(['translation', 'dashboard', 'forms', 'book']);
    const { user } = useSelector(selectAuth);
    const userSamples = useSelector((state) => selectSamplesByIds(state, user?.Samples || []));
    const { listLoading: booksLoading, error } = useSelector(selectBooks);
    const bookIds = useSelector(selectAllBooksIds);

    const page = useSelector(selectBooksPage);
    const perPage = useSelector(selectBooksPerPage);
    const orderBy = useSelector(selectBooksOrderBy);
    const selectedLanguage = useSelector(selectBooksLanguage);

    const fixedHeightPaper = clsx('lbx-paper', classes.paper, classes.fixedHeight);

    const [ignoreQueryChange, setIgnoreQueryChange] = useState(false);

    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const searchQuery = useDebounce(searchInputValue, 350);

    useEffect(() => {
        if (!ignoreQueryChange) {
            if (searchQuery) {
                if (searchQuery.trim().length > 1) {
                    dispatch(searchBooks(searchQuery));
                }
            } else {
                dispatch(getBooks());
            }
        } else {
            setIgnoreQueryChange(false);
        }

        return () => {
            dispatch(resetBooks());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, searchQuery]);

    const handleLanguageChange = (event: ChangeEvent<{ value: unknown }>) => {
        setSearchInputValue('');
        setIgnoreQueryChange(true);
        dispatch(setBooksLanguage(event.target.value as Language));
    };

    const handleOrderByChange = (event: ChangeEvent<{ value: unknown }>) => {
        setSearchInputValue('');
        setIgnoreQueryChange(true);
        dispatch(setBooksOrder(event.target.value as BooksOrder));
    };

    const handleLoadMoreBooks = () => {
        dispatch(setPage(BOOKS_NAMESPACE_KEY)(page + 1));
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.size > MAX_SAMPLE_SIZE) {
                const message = t('forms:file_oversize', { sizeLimit: Math.floor(MAX_SAMPLE_SIZE / 1000000) });
                dispatch(setError({ message }));
                event.target.value = '';
                return;
            }

            dispatch(uploadSample(event.target.files[0]));
        }
    };

    const handleSearchPrefixChange = (event: ChangeEvent<{ value: unknown }>) => {
        setSearchInputValue(event.target.value as string);
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                {user?.Roles.length === 0 && (
                    <Grid item xs={12}>
                        <Paper className={fixedHeightPaper}>
                            <NotConfirmedSection
                                uploadLoading={user.samplesLoading}
                                progress={user.uploadProgress}
                                onUploadSample={handleFileChange}
                                samples={userSamples}
                            />
                        </Paper>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Paper className={['lbx-paper', classes.paper].join(' ')}>
                        <Title gutterBottom>{t('dashboard:available_books')}</Title>

                        <BooksFilter
                            selectedLanguage={selectedLanguage}
                            orderBy={orderBy}
                            searchPrefix={searchInputValue}
                            onClearSearch={() => setSearchInputValue('')}
                            onSearchPrefixChange={handleSearchPrefixChange}
                            onOrderChange={handleOrderByChange}
                            onLanguageChange={handleLanguageChange}
                        />
                        <AvailableBooks
                            listClassName={classes.bookList}
                            isLoading={booksLoading}
                            bookIds={bookIds}
                            onLoadMore={handleLoadMoreBooks}
                            showLoadMore={bookIds.length === (page + 1) * perPage && !error}
                        />
                        {!booksLoading && bookIds.length === 0 && <Typography color="textSecondary">{t('dashboard:no_books')}</Typography>}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;
