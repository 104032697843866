import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import Title from '../../components/Title';
import UploadFile from '../../components/UploadFile';
import { Sample, SampleState } from '../../models';

const useStyles = makeStyles((theme) => ({
    uploadProgress: {
        marginLeft: theme.spacing(2),
    },
}));

interface NotConfirmedSectionProps {
    samples?: Sample[];
    progress: number;
    uploadLoading: boolean;
    onUploadSample: (event: any) => void;
}

const NotConfirmedSection = ({ samples, onUploadSample, ...props }: NotConfirmedSectionProps) => {
    const classes = useStyles();
    const { t } = useTranslation('dashboard');
    let showUploadButton = true;
    let message = t('dashboard:not_confirmed.upload_sample');

    if (samples && samples?.length > 0) {
        const hasPending = samples.some((sample) => sample.State === SampleState.PENDING);
        const isAllDisapproved = new Set(samples.map((sample) => sample.State)).size === 1 && samples[0].State === SampleState.DISAPPROVED;

        if (hasPending) {
            showUploadButton = false;
            message = t('dashboard:not_confirmed.in_review');
        } else if (isAllDisapproved) {
            showUploadButton = true;
            message = t('dashboard:not_confirmed.denied');
        }
    }

    return (
        <React.Fragment>
            <Title gutterBottom>{t('dashboard:not_confirmed.title')}</Title>
            <Typography component="p">{message}</Typography>

            {showUploadButton && (
                <React.Fragment>
                    <Box flex="1"></Box>
                    <Box display="flex" alignItems="center">
                        <UploadFile
                            component={Button}
                            componentProps={{ component: 'span', color: 'primary', disabled: props.uploadLoading }}
                            accept=".mp3"
                            fileChange={onUploadSample}>
                            {t('dashboard:upload_sample_btn')}
                        </UploadFile>

                        {props.uploadLoading && (
                            <div className={classes.uploadProgress}>
                                <CircularProgressWithLabel value={props.progress} />
                            </div>
                        )}
                    </Box>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default NotConfirmedSection;
