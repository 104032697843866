export type SnackbarMessageType = 'success' | 'error' | 'info' | 'warning' | undefined;

export type UiNamespaceShape = {
    snackbarType: SnackbarMessageType;
    snackbarIsOpen: boolean;
    snackbarMessage: string;
    headerTitle: string;
    drawerOpened: boolean;
    uiLoading: boolean;
    sentSupportMessage: boolean;
    supportMessageLoading: boolean;
};

export enum UiActionTypes {
    INIT = '@@ui/INIT',
    SET_HEADER_TITLE = '@@ui/SET_HEADER_TITLE',
    SHOW_MESSAGE = '@@ui/SHOW_MESSAGE',
    HIDE_MESSAGE = '@@ui/HIDE_MESSAGE',
    GLOBAL_LOADING_START = '@@ui/GLOBAL_LOADING_START',
    GLOBAL_LOADING_END = '@@ui/GLOBAL_LOADING_END',
    SET_DRAWER_OPEN = '@@ui/SET_DRAWER_OPEN',

    SEND_SUPPORT_MESSAGE = '@@ui/SEND_SUPPORT_MESSAGE',
    SEND_SUPPORT_MESSAGE_START = '@@ui/SEND_SUPPORT_MESSAGE_START',
    SEND_SUPPORT_MESSAGE_SUCCESS = '@@ui/SEND_SUPPORT_MESSAGE_SUCCESS',
    SEND_SUPPORT_MESSAGE_FAIL = '@@ui/SEND_SUPPORT_MESSAGE_FAIL',
}

export interface ShowMessagePayload {
    type: SnackbarMessageType;
    message: string;
}

export interface SupportMessagePayload {
    Email: string;
    Text: string;
}
