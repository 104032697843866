import { Action, PayloadAction } from '@reduxjs/toolkit';
import { Book, BookDetail, Reservation } from '../../models';
import { createNamespacedReducer } from '../../utils/create-namespaced-reducer';
import reduceReducers from '../../utils/reduce-reducers';
import pagingReducer from '../paging/reducer';
import { BooksOrder } from './../../models/books-order';
import { Language } from './../../models/language';
import { BOOKS_NAMESPACE_KEY } from './../../models/store';
import { createReducer } from './../../utils/create-reducer';
import { BooksActionTypes, BooksNamespaceShape } from './types';

const getBooksStart = (state: BooksNamespaceShape, action: Action) => {
    const updatedState = {
        ...state,
        error: null,
        listLoading: true,
    };

    if (state.page === 0) {
        updatedState.entities = {
            byId: {},
            allIds: [],
        };
    }
    return updatedState;
};

const getBooksSuccess = (state: BooksNamespaceShape, action: PayloadAction<Book[]>) => {
    const allEntities = action.payload.reduce((acc, book: Book) => ({ ...acc, [book.BookId]: book }), { ...state.entities.byId });
    const allIds = [...state.entities.allIds, ...action.payload.map((book) => book.BookId)];
    return {
        ...state,
        listLoading: false,
        entities: {
            ...state.entities,
            byId: allEntities,
            allIds,
        },
    };
};

const getBooksFail = (state: BooksNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        listLoading: false,
        entities: { byId: {}, allIds: [] },
        error: action.payload,
    };
};

const resetBooks = (state: BooksNamespaceShape, action: Action) => {
    return {
        ...state,
        entities: { byId: {}, allIds: [] },
        page: 0,
    };
};

const searchBooks = (state: BooksNamespaceShape, action: Action) => {
    return {
        ...state,
        page: 0,
    };
};

const setBooksLanguage = (state: BooksNamespaceShape, action: PayloadAction<Language>) => {
    return {
        ...state,
        language: action.payload,
        entities: {
            byId: {},
            allIds: [],
        },
        page: 0,
    };
};

const setBooksOrder = (state: BooksNamespaceShape, action: PayloadAction<BooksOrder>) => {
    return {
        ...state,
        orderBy: action.payload,
        entities: {
            byId: {},
            allIds: [],
        },
        page: 0,
    };
};

const clearBookDetail = (state: BooksNamespaceShape, action: Action) => {
    return { ...state, bookDetail: null };
};

const getBookDetailStart = (state: BooksNamespaceShape, action: Action) => {
    return { ...state, detailLoading: true, error: null };
};

const getBookDetailSuccess = (state: BooksNamespaceShape, action: PayloadAction<BookDetail>) => {
    return { ...state, detailLoading: false, bookDetail: action.payload };
};

const getBookDetailFail = (state: BooksNamespaceShape, action: PayloadAction<Error>) => {
    return { ...state, detailLoading: false, error: action.payload };
};

const reserveBookPartStart = (state: BooksNamespaceShape, action: Action) => {
    return { ...state, reservationLoading: true };
};

// todo add to separate reducer
const reserveBookPartSuccess = (state: BooksNamespaceShape, action: PayloadAction<Reservation>) => {
    if (state.bookDetail) {
        const reservation = action.payload;
        const reservedPartIndex = state.bookDetail.Parts.findIndex((part) => part.RecordableBookPartId === reservation.BookPart.RecordableBookPartId);
        const newParts = [...state.bookDetail.Parts];

        return {
            ...state,
            bookDetail: {
                ...state.bookDetail,
                Parts: [
                    ...newParts.slice(0, reservedPartIndex),
                    { ...newParts[reservedPartIndex], IsAvailableToReserve: false },
                    ...newParts.slice(reservedPartIndex + 1),
                ],
            },
            reservationLoading: false,
            reservationCreated: true,
        };
    }
    return state;
};

const reserveBookPartFail = (state: BooksNamespaceShape, action: PayloadAction<Error>) => {
    return { ...state, reservationLoading: false, error: action.payload };
};

const closeReservationSuccessDialog = (state: BooksNamespaceShape, action: Action) => {
    return { ...state, reservationCreated: false };
};

const initialState: BooksNamespaceShape = {
    entities: { byId: {}, allIds: [] },
    bookDetail: null,
    listLoading: false,
    detailLoading: false,
    reservationLoading: false,
    error: null,
    orderBy: BooksOrder.PROGRESS,
    language: undefined,
    reservationCreated: false,
    page: 0,
    perPage: 20,
};

const reduce = createReducer(initialState, {
    [BooksActionTypes.GET_BOOKS_START]: getBooksStart,
    [BooksActionTypes.GET_BOOKS_SUCCESS]: getBooksSuccess,
    [BooksActionTypes.GET_BOOKS_FAIL]: getBooksFail,

    [BooksActionTypes.RESET_BOOKS]: resetBooks,

    [BooksActionTypes.GET_BOOK_DETAIL_START]: getBookDetailStart,
    [BooksActionTypes.GET_BOOK_DETAIL_SUCCESS]: getBookDetailSuccess,
    [BooksActionTypes.GET_BOOK_DETAIL_FAIL]: getBookDetailFail,
    [BooksActionTypes.CLEAR_BOOK_DETAIL]: clearBookDetail,
    [BooksActionTypes.RESERVE_BOOK_PART_START]: reserveBookPartStart,
    [BooksActionTypes.RESERVE_BOOK_PART_SUCCESS]: reserveBookPartSuccess,
    [BooksActionTypes.RESERVE_BOOK_PART_FAIL]: reserveBookPartFail,
    [BooksActionTypes.CLOSE_RESERVATION_SUCCESS_DIALOG]: closeReservationSuccessDialog,

    [BooksActionTypes.SEARCH_BOOKS]: searchBooks,
    [BooksActionTypes.INIT_BOOKS_LANGUAGE]: setBooksLanguage,
    [BooksActionTypes.SET_BOOKS_LANGUAGE]: setBooksLanguage,
    [BooksActionTypes.SET_BOOKS_ORDER]: setBooksOrder,
});

const namespacePagingReducer = createNamespacedReducer(BOOKS_NAMESPACE_KEY, pagingReducer);

export default reduceReducers(initialState, reduce, namespacePagingReducer);
