import { Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import UiRouterLink from '../../../components/UiRouterLink';
import picture from '../img/stante_sa_sucastou.png';

const useStyles = makeStyles((theme) => ({
    picture: {
        maxWidth: '100%',
    },
    title: {
        marginTop: theme.spacing(3),
    },
    bulletList: {
        listStyle: 'none',
        paddingInlineStart: '30px',
    },
    bulletListItem: {
        padding: theme.spacing(1, 0),
        '&::before': {
            content: '""',
            display: 'inline-block',
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            marginLeft: -30,
            marginRight: 22,
        },
    },
    [theme.breakpoints.only('md')]: {
        root: {
            alignItems: 'center',
        },
        title: {
            marginTop: 0,
        },
    },
}));

const JoinUs: React.FC = () => {
    const styles = useStyles();
    const { t } = useTranslation('home');

    const steps = [
        <Trans
            i18nKey="home:join.step1"
            defaults="Jednoducho <rlink>sa zaregistrujete</rlink> ako interpret"
            components={{ rlink: <UiRouterLink to="/signup" /> }}
        />,
        t('home:join.step2'),
        t('home:join.step3'),
        t('home:join.step4'),
        t('home:join.step5'),
        t('home:join.step6'),
        t('home:join.step7'),
        t('home:join.step8'),
        t('home:join.step9'),
    ];

    return (
        <Grid container spacing={4} className={styles.root}>
            <Grid item xs>
                <Typography className={styles.title} variant="h4" color="primary" gutterBottom>
                    {t('home:join.title')}
                </Typography>
                <ul className={styles.bulletList}>
                    {steps.map((step, index) => (
                        <Typography variant="body2" component="li" key={index} className={styles.bulletListItem}>
                            {step}
                        </Typography>
                    ))}
                </ul>
            </Grid>
            <Hidden only="sm">
                <Grid item xs={12} md={6} lg="auto">
                    <img src={picture} className={styles.picture} alt="" />
                </Grid>
            </Hidden>
        </Grid>
    );
};

export default JoinUs;
