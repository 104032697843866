import { AxiosConfig } from './../models/axios-config';
import { AxiosInstance } from 'axios';
import { useState, useEffect } from 'react';

const useHttpErrorHandler = (httpClient: AxiosInstance): [Error | null, () => void] => {
    const [error, setError] = useState<Error | null>(null);

    const reqInterceptor = httpClient.interceptors.request.use((req) => {
        setError(null);
        return req;
    });
    const resInterceptor = httpClient.interceptors.response.use(
        (res) => res,
        (err) => {
            const { config } = err;
            if ((config as AxiosConfig).handleError) {
                setError(err);
            }
            throw err;
        }
    );

    useEffect(() => {
        return () => {
            httpClient.interceptors.request.eject(reqInterceptor);
            httpClient.interceptors.response.eject(resInterceptor);
        };
    }, [httpClient, reqInterceptor, resInterceptor]);

    const errorConfirmedHandler = () => {
        setError(null);
    };

    return [error, errorConfirmedHandler];
};

export default useHttpErrorHandler;
