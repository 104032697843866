import { makeStyles } from '@material-ui/core';
import React from 'react';
import logo from '../img/logo_full.svg';

const useStyles = makeStyles((theme) => ({
    logo: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '.65rem',
    },
    logoImage: {
        height: '3em',
    },
    [theme.breakpoints.up('md')]: {
        logo: {
            fontSize: '1rem',
        },
    },
}));

const Logo = () => {
    const classes = useStyles();

    return (
        <div className={classes.logo}>
            <img src={logo} className={classes.logoImage} alt="Librixy voice logo" />
        </div>
    );
};

export default Logo;
