import { EntityId } from './../../../models/entity-id';
import { User } from '../../../models';

export type UserNamespaceShape = User & {
    uploadProgress: number;
    samplesLoading: boolean;
    recordUploading: boolean;
    error: Error | null;
    reservationsLoading: boolean;
};

export enum UserActionTypes {
    GET_USER = '@@user/GET_USER',
    GET_USER_START = '@@user/GET_USER_START',
    GET_USER_SUCCESS = '@@user/GET_USER_SUCCESS',
    GET_USER_FAIL = '@@user/GET_USER_FAIL',
    GET_SAMPLES = '@@user/GET_SAMPLES',
    GET_SAMPLES_START = '@@user/GET_SAMPLES_START',
    GET_SAMPLES_SUCCESS = '@@user/GET_SAMPLES_SUCCESS',
    GET_SAMPLES_FAIL = '@@user/GET_SAMPLES_FAIL',

    GET_RESERVATIONS = '@@user/GET_RESERVATIONS',
    GET_RESERVATIONS_START = '@@user/GET_RESERVATIONS_START',
    GET_RESERVATIONS_SUCCESS = '@@user/GET_RESERVATIONS_SUCCESS',
    GET_RESERVATIONS_FAIL = '@@user/GET_RESERVATIONS_FAIL',

    UPLOAD_SAMPLE = '@@user/UPLOAD_SAMPLE',
    UPLOAD_SAMPLE_START = '@@user/UPLOAD_SAMPLE_START',
    UPLOAD_SAMPLE_SUCCESS = '@@user/UPLOAD_SAMPLE_SUCCESS',
    UPLOAD_SAMPLE_FAIL = '@@user/UPLOAD_SAMPLE_FAIL',

    UPLOAD_RECORD = '@@user/UPLOAD_RECORD',
    UPLOAD_RECORD_START = '@@user/UPLOAD_RECORD_START',
    UPLOAD_RECORD_FAIL = '@@user/UPLOAD_RECORD_FAIL',
    UPLOAD_RECORD_SUCCESS = '@@user/UPLOAD_RECORD_SUCCESS',

    CANCEL_RESERVATION = '@@user/CANCEL_RESERVATION',
    CANCEL_RESERVATION_SUCCESS = '@@user/CANCEL_RESERVATION_SUCCESS',
    CANCEL_RESERVATION_FAIL = '@@user/CANCEL_RESERVATION_FAIL',

    UPLOAD_FILE_PROGRESS = '@@user/UPLOAD_FILE_PROGRESS',
}

export interface CancelReservationFailPayload {
    reservationId: EntityId;
    error: Error;
}

export interface UploadRecordPayload {
    reservationId: EntityId;
    record: File;
}
