import { Typography, Grid, List, ListItem, ListItemText, Link, Box } from '@material-ui/core';
import React from 'react';
import Title from '../../../components/Title';
import RecordAppItem from './RecordAppItem';
import { androidApps, iosApps, pcApps } from './recordingApps';

import audacity from './img/audacity.png';
import recordMobile from './img/record-mobile.png';
import recordPc from './img/record-pc.png';
import settingsDefaultMic from './img/settings-default-mic.png';

const HardwareSoftware = ({ classes }: { classes: Record<string, string> }) => {
    return (
        <React.Fragment>
            <div className={classes.section}>
                <Title variant="h3" gutterBottom>
                    Hardware a software
                </Title>

                <Typography>
                    Chystáte se načíst audioknihu? V prvé řadě nutně potřebujete nějaké zařízení, na které budete zvuk nahrávat. Ukážeme vám dvě
                    nejsnazší varianty.
                </Typography>
            </div>
            <div className={classes.section}>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <div className={classes.recordOptionImage}>
                            <img src={recordMobile} alt="Record via phone" className={classes.img} />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div className={classes.recordOptionImage}>
                            <img src={recordPc} alt="Record via PC" className={classes.img} />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.section}>
                <Title gutterBottom>Varianta 1 – Nahrávání na chytré zařízení</Title>

                <List>
                    <ListItem disableGutters>
                        <ListItemText>
                            Lze využít <b>mobil nebo tablet</b>
                        </ListItemText>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText>
                            Nahrávat můžete <b>přímo do zařízení</b> s pomocí zabudované aplikace pro nahrávání zvuku
                        </ListItemText>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText>
                            <b>Nezáleží na operačním systému</b> (nahrávat lze s Androidem a také iOS)
                        </ListItemText>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText>
                            Zvuk <b>můžete nahrát a rovnou upravit</b> v mobilu nebo tabletu, případně si jej můžete stáhnout do osobního počítače
                        </ListItemText>
                    </ListItem>
                </List>
            </div>
            <div className={classes.section}>
                <Typography variant="h6" gutterBottom>
                    Další aplikace pro nahrávání zvuku pro systém Android
                </Typography>
                <Grid container spacing={2}>
                    {androidApps.map((app, index) => (
                        <Grid key={index} item xs={12} sm>
                            <RecordAppItem app={app} />
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className={classes.section}>
                <Typography variant="h6" gutterBottom>
                    Další aplikace pro nahrávání zvuku pro systém iOS
                </Typography>
                <Grid container spacing={2}>
                    {iosApps.map((app, index) => (
                        <Grid key={index} item xs={12} sm>
                            <RecordAppItem app={app} />
                        </Grid>
                    ))}
                </Grid>
            </div>

            <div className={classes.section}>
                <Title gutterBottom>Varianta 2 – Nahrávání s pomocí počítače a (externího) mikrofonu</Title>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <div className={classes.audacityImage}>
                            <img className={classes.img} src={audacity} alt="Audacity" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm>
                        <List>
                            <ListItem disableGutters>
                                <ListItemText>
                                    Využít lze <b>zabudovaný mikrofon (v notebooku) nebo například externí mikrofon s USB připojením</b>
                                </ListItemText>
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText>
                                    Potřebujete audio software, který bude zvuk nahrávat, využít můžete například program <b>Audacity</b>, který je ke
                                    stažení zcela zdarma{' '}
                                    <Link href="https://www.audacityteam.org/download/" target="_blank" rel="noreferrer">
                                        ZDE
                                    </Link>
                                </ListItemText>
                            </ListItem>
                            <ListItem disableGutters>
                                <ListItemText>
                                    V případě, že chcete nahrávat na externí mikrofon, <b>vyberte v nastavení hardwaru tento mikrofon jako výchozí</b>{' '}
                                    (je potřeba mít mikrofon připojen k vašemu počítači)
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Box className={classes.centeredImage}>
                    <img className={classes.img} src={settingsDefaultMic} alt="Set microphone as default" />
                </Box>
            </div>

            <div className={classes.section}>
                <Typography variant="h6" gutterBottom>
                    Jaké další programy lze použít pro nahrávání a úpravu zvuku v počítači
                </Typography>
                <Grid container spacing={2}>
                    {pcApps.map((app, index) => (
                        <Grid key={index} item xs={12} sm>
                            <RecordAppItem app={app} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default HardwareSoftware;
