import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { RouteConfig } from '../models/route-config';
import ProtectedRoute from './ProtectedRoute';
import path from 'path';

interface RoutesProps {
    routesList: RouteConfig[];
    noMatchRedirect?: string;
}

const Routes = ({ routesList, noMatchRedirect }: RoutesProps) => {
    const { path: parentPath } = useRouteMatch();

    return (
        <Switch>
            {routesList.map(({ isPrivate = false, ...route }: RouteConfig, index: number) => {
                const RouteComponent = isPrivate ? ProtectedRoute : Route;
                return <RouteComponent key={index} {...route} path={path.join(parentPath, route.path)} />;
            })}

            {noMatchRedirect && <Redirect to={path.join(parentPath, noMatchRedirect)} />}
        </Switch>
    );
};

export default Routes;
