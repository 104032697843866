import { UserActionTypes, CancelReservationFailPayload } from '../auth/user/types';
import { BooksActionTypes } from '../books/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { Reservation } from '../../models';
import { createReducer } from '../../utils/create-reducer';
import { ReservationsActionTypes, ReservationsNamespaceShape } from './types';

const setReservations = (state: ReservationsNamespaceShape, action: PayloadAction<Reservation[]>) => {
    const newById = action.payload.reduce((acc, r: Reservation) => ({ ...acc, [r.Id]: r }), {});
    return {
        ...state,
        entities: {
            ...state.entities,
            byId: newById,
            allIds: Object.keys(newById),
        },
    };
};

const setReservation = (state: ReservationsNamespaceShape, action: PayloadAction<Reservation>) => {
    return {
        ...state,
        entities: {
            ...state.entities,
            byId: {
                ...state.entities.byId,
                [action.payload.Id]: action.payload,
            },
            allIds: Array.from(new Set([...state.entities.allIds, action.payload.Id])),
        },
    };
};

const removeReservationStart = (state: ReservationsNamespaceShape, action: PayloadAction<string>) => {
    const byId = {
        ...state.entities.byId,
        [action.payload]: {
            ...state.entities.byId[action.payload],
            loading: true,
        },
    };

    return {
        ...state,
        entities: {
            ...state.entities,
            byId,
            allIds: Object.keys(byId),
        },
        error: null,
    };
};

const removeReservationSuccess = (state: ReservationsNamespaceShape, action: PayloadAction<string>) => {
    const byId = { ...state.entities.byId };
    delete byId[action.payload];

    return {
        ...state,
        entities: {
            ...state.entities,
            byId,
            allIds: Object.keys(byId),
        },
    };
};

const removeReservationFail = (state: ReservationsNamespaceShape, action: PayloadAction<CancelReservationFailPayload>) => {
    const byId = {
        ...state.entities.byId,
        [action.payload.reservationId]: {
            ...state.entities.byId[action.payload.reservationId],
            loading: false,
        },
    };

    return {
        ...state,
        entities: {
            ...state.entities,
            byId,
            allIds: Object.keys(byId),
        },
        error: action.payload.error,
    };
};

const initialState: ReservationsNamespaceShape = {
    entities: {
        byId: {},
        allIds: [],
    },
    error: null,
    loading: false,
};

const reducer = createReducer(initialState, {
    [ReservationsActionTypes.SET_RESERVATIONS]: setReservations,

    [UserActionTypes.GET_RESERVATIONS_SUCCESS]: setReservations,
    [UserActionTypes.CANCEL_RESERVATION]: removeReservationStart,
    [UserActionTypes.CANCEL_RESERVATION_SUCCESS]: removeReservationSuccess,
    [UserActionTypes.CANCEL_RESERVATION_FAIL]: removeReservationFail,
    [BooksActionTypes.RESERVE_BOOK_PART_SUCCESS]: setReservation,
});

export default reducer;
