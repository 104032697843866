export type PagingNamespaceShape = {
    page: number;
    perPage: number;
};

export enum PagingActionTypes {
    SET_PAGE = '@@paging/SET_PAGE',
    SET_PERPAGE = '@@paging/SET_PERPAGE',
    RESET = '@@paging/RESET',
}
