import { createAction } from '@reduxjs/toolkit';
import { UiActionTypes, SnackbarMessageType, SupportMessagePayload } from './types';

export const setHeaderTitle = createAction<string>(UiActionTypes.SET_HEADER_TITLE);

export const showMessage = createAction(UiActionTypes.SHOW_MESSAGE, (type: SnackbarMessageType, message: string) => ({
    payload: {
        type,
        message,
    },
}));

export const hideMessage = createAction(UiActionTypes.HIDE_MESSAGE);

export const globalLoadingStart = createAction(UiActionTypes.GLOBAL_LOADING_START);
export const globalLoadingEnd = createAction(UiActionTypes.GLOBAL_LOADING_END);

export const initUI = createAction<boolean>(UiActionTypes.INIT);
export const setDrawerOpen = createAction<boolean>(UiActionTypes.SET_DRAWER_OPEN);

export const sendSupportMessage = createAction<SupportMessagePayload>(UiActionTypes.SEND_SUPPORT_MESSAGE);
export const sendSupportMessageStart = createAction(UiActionTypes.SEND_SUPPORT_MESSAGE_START);
export const sendSupportMessageSuccess = createAction(UiActionTypes.SEND_SUPPORT_MESSAGE_SUCCESS);
export const sendSupportMessageFail = createAction(UiActionTypes.SEND_SUPPORT_MESSAGE_FAIL);
