import { PagingNamespaceShape } from './../paging/types';
import { EntityId } from './../../models/entity-id';
import { Book, BookDetail, BooksOrder, Language, StoreEntity } from '../../models';

export type BooksNamespaceShape = PagingNamespaceShape & {
    entities: StoreEntity<Book>;
    bookDetail: BookDetail | null;
    orderBy: BooksOrder;
    language?: Language;
    listLoading: boolean;
    detailLoading: boolean;
    reservationLoading: boolean;
    error: Error | null;
    reservationCreated: boolean;
};

export enum BooksActionTypes {
    INIT_BOOKS_LANGUAGE = '@@books/INIT_BOOKS_LANGUAGE',

    SEARCH_BOOKS = '@@books/SEARCH_BOOKS',

    SET_BOOKS_LANGUAGE = '@@books/SET_BOOKS_LANGUAGE',
    SET_BOOKS_ORDER = '@@books/SET_BOOKS_ORDER',

    GET_BOOKS = '@@books/GET_BOOKS',
    GET_BOOKS_START = '@@books/GET_BOOKS_START',
    GET_BOOKS_SUCCESS = '@@books/GET_BOOKS_SUCCESS',
    GET_BOOKS_FAIL = '@@books/GET_BOOKS_FAIL',

    RESET_BOOKS = '@@books/RESET_BOOKS',

    GET_BOOK_DETAIL = '@@books/GET_BOOK_DETAIL',
    GET_BOOK_DETAIL_START = '@@books/GET_BOOK_DETAIL_START',
    GET_BOOK_DETAIL_SUCCESS = '@@books/GET_BOOK_DETAIL_SUCCESS',
    GET_BOOK_DETAIL_FAIL = '@@books/GET_BOOK_DETAIL_FAIL',
    CLEAR_BOOK_DETAIL = '@@books/CLEAR_BOOK_DETAIL',

    RESERVE_BOOK_PART = '@@books/RESERVE_BOOK_PART',
    RESERVE_BOOK_PART_START = '@@books/RESERVE_BOOK_PART_START',
    RESERVE_BOOK_PART_SUCCESS = '@@books/RESERVE_BOOK_PART_SUCCESS',
    RESERVE_BOOK_PART_FAIL = '@@books/RESERVE_BOOK_PART_FAIL',

    GET_RESERVATIONS = '@@books/GET_RESERVATIONS',
    GET_RESERVATIONS_START = '@@books/GET_RESERVATIONS_START',
    GET_RESERVATIONS_SUCCESS = '@@books/GET_RESERVATIONS_SUCCESS',
    GET_RESERVATIONS_FAIL = '@@books/GET_RESERVATIONS_FAIL',

    CLOSE_RESERVATION_SUCCESS_DIALOG = '@@books/CLOSE_RESERVATION_SUCCESS_DIALOG',

    DOWNLOAD_BOOK_READABLE = '@@books/DOWNLOAD_BOOK_READABLE',
    DOWNLOAD_BOOK_PART_READABLE = '@@books/DOWNLOAD_BOOK_PART_READABLE',
}

export interface GetBooksPayload {
    language: Language;
    page: number;
    perPage: number;
    orderBy: BooksOrder;
    searchQuery?: string;
}

export interface ReserveBookPart {
    partId: EntityId;
    language: Language;
}

export interface DownloadBookPartPayload {
    bookId: EntityId;
    partId: EntityId;
}
