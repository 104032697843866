import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import Title from '../../../components/Title';

import introImage from './img/intro.png';

const Intro = ({ classes }: { classes: Record<string, string> }) => {
    return (
        <React.Fragment>
            <div className={classes.section}>
                <Title variant="h3" gutterBottom>
                    Jak nahrávat audioknihy
                </Title>

                <Grid container>
                    <Grid item xs>
                        <Typography>
                            Pokud jste zažili tu posvátnou chvíli, kdy vám jako malým dětem předčítali rodiče, prarodiče či sourozenci, určitě víte,
                            že předčítání příběhů má své nezapomenutelné kouzlo. S aplikací Librixy lze toto kouzlo znovu lehce objevit. Čtením a
                            nahráváním příběhů, které mohou poslouchat ostatní. Stát se moderním vypravěčem není nic těžkého. Podívejte se na náš
                            jednoduchý a přehledný návod, v němž vás seznámíme se základními principy nahrávání audioknih. Poradíme vám, na jaké
                            zařízení nahrávat, jaký nástroj pro úpravu nahrávek využívat nebo jak vše jednoduše propojit tak, aby na konci celého
                            procesu vzešla nová audiokniha načtená přímo vámi.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div className={classes.sectionImage}>
                            <img src={introImage} alt="Woman with a book" className={classes.img} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default Intro;
