function download(fileName: string, data: any, type = 'application/octet-stream') {
    const blob = new Blob([data], { type });

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        const temp_a = document.createElement('a');
        const windowUrl = window.URL || window.webkitURL;
        const aHref = windowUrl.createObjectURL(blob);

        if (typeof temp_a.download === 'undefined') {
            const error = {
                message: "HTML5 Download not supported in client's browser",
                data: data,
            };
            throw error;
        }

        temp_a.setAttribute('href', aHref);
        temp_a.setAttribute('download', fileName);

        temp_a.style.display = 'none';

        document.body.appendChild(temp_a);
        temp_a.click();

        setTimeout(() => {
            document.body.removeChild(temp_a);
            windowUrl.revokeObjectURL(aHref);
        });
    }
}
export default download;
