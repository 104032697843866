const pathLangDetector = {
    name: 'lbxPathLangDetector',

    lookup(options: any) {
        let found;
        if (typeof window !== 'undefined') {
            const language = window.location.pathname.match(/^\/([a-zA-Z-]{2}\/)/g);
            if (language instanceof Array) {
                found = language[0].replace(/\//g, '');
            }
        }
        return found;
    },
};

export default pathLangDetector;
