import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX } from './../constants';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FormControlState, FormControlsConfig, FormControlsState, FormValidationRules, UseFormApi } from '../models';

function useForm(controls: FormControlsConfig, submitCallback: (formValues: any) => void): UseFormApi {
    const { t, ready } = useTranslation('forms', { useSuspense: false });
    let initialState: FormControlsState = {};
    let initialValidationRules: FormValidationRules = {};

    Object.keys(controls).forEach((key) => {
        initialState = {
            ...initialState,
            [key]: {
                value: controls[key].initialValue || '',
                error: null,
                isValid: false,
                isDirty: false,
            },
        };

        initialValidationRules = {
            ...initialValidationRules,
            [key]: { ...controls[key].rules },
        };
    });

    const [formState, setFormState] = useState({
        isValid: false,
        isSubmitted: false,
        submittedCount: 0,
    });
    const [formControlsState, setFormControlsState] = useState(initialState);
    const [validationRules] = useState(initialValidationRules);

    const validateControl = (controlKey: keyof FormControlsState, control: FormControlState, controls: FormControlsState): string | null => {
        const fcValidationRules = validationRules[controlKey];

        if (ready) {
            if (fcValidationRules.required) {
                if (!control.value) {
                    return t('forms:field_required');
                }
            }

            if (fcValidationRules.type === 'email') {
                if (!EMAIL_REGEX.test(control.value as string)) {
                    return t('forms:email_invalid');
                }
            }

            if (!!fcValidationRules['sameAs']) {
                if (control.value !== controls[fcValidationRules.sameAs].value) {
                    return t('forms:field_incorrect');
                }
            }

            if (fcValidationRules['minLength']) {
                if (typeof control.value === 'string') {
                    if (control.value.length < fcValidationRules['minLength']) {
                        return t('forms:minlength_invalid', { count: fcValidationRules['minLength'] });
                        // return `Value must be at least ${fcValidationRules['minLength']} characters long.`;
                    }
                }
            }

            if (fcValidationRules['maxLength']) {
                if (typeof control.value === 'string') {
                    if (control.value.length > fcValidationRules['maxLength']) {
                        return t('forms:maxlength_invalid', { count: fcValidationRules['maxLength'] });
                        // return `Value must be up to ${fcValidationRules['maxLength']} characters long.`;
                    }
                }
            }
        }

        return null;
    };

    const getFormStateValidity = (formControlsState: FormControlsState): boolean => {
        return Object.values(formControlsState).reduce((isValid: boolean, state: FormControlState) => isValid && state.isValid, true);
    };

    const getFormValues = () => {
        return Object.keys(formControlsState).reduce(
            (acc: any, controlKey: string) => ({
                ...acc,
                [controlKey]: formControlsState[controlKey].value,
            }),
            {}
        );
    };

    const getChangeListener = (key: keyof FormControlsState) => (e: ChangeEvent<HTMLInputElement>) => {
        const updatedControl: FormControlState = {
            ...formControlsState[key],
            value: e.target.value,
            isDirty: true,
        };

        updatedControl.error = validateControl(key, updatedControl, formControlsState);
        updatedControl.isValid = !updatedControl.error;

        const newFormState = {
            ...formControlsState,
            [key]: updatedControl,
        };

        setFormControlsState(newFormState);
        setFormState({ ...formState, isSubmitted: false, isValid: getFormStateValidity(newFormState) });
    };

    const submitForm = (event: FormEvent) => {
        event.preventDefault();

        const newFormState: FormControlsState = Object.entries(formControlsState).reduce<FormControlsState>((acc, [controlKey, controlState]) => {
            const updatedControl = { ...controlState };
            updatedControl.error = validateControl(controlKey, updatedControl, formControlsState);
            updatedControl.isDirty = true;
            updatedControl.isValid = !updatedControl.error;

            return {
                ...acc,
                [controlKey]: updatedControl,
            };
        }, {});

        setFormControlsState(newFormState);
        setFormState({
            ...formState,
            isValid: getFormStateValidity(newFormState),
            isSubmitted: true,
            submittedCount: formState.submittedCount + 1,
        });
    };

    useEffect(() => {
        if (formState.isValid && formState.isSubmitted) {
            submitCallback(getFormValues());
        }
        // eslint-disable-next-line
    }, [formState]);

    return {
        form: formState,
        controls: formControlsState,
        getChangeListener,
        handleSubmit: submitForm,
    };
}

export default useForm;
