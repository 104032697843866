import { FullStoreShape, NamespaceKey, StoreShape } from '../models';

export function getStateAtNamespaceKey<T extends NamespaceKey>(state: StoreShape, namespace: T): FullStoreShape[T] {
    const namespaceState = state[namespace];

    if (typeof state[namespace] === 'undefined') {
        throw new Error(`Attempted to access state for an unregistered namespace at key ${namespace}`);
    }
    // We need to explicitly say this is not undefined because TypeScript doesn't
    // recognize the thrown error will prevent us from ever getting here.
    return namespaceState! as FullStoreShape[T];
}
