import { Record, StoreEntity } from '../../models';

export interface RecordsNamespaceShape {
    entities: StoreEntity<Record>;
    loading: boolean;
    error: Error | null;
}

export enum RecordActionTypes {
    GET_USER_RECORDS = '@@records/GET_USER_RECORDS',
    GET_USER_RECORDS_SUCCESS = '@@records/GET_USER_RECORDS_SUCCESS',
    GET_USER_RECORDS_FAIL = '@@records/GET_USER_RECORDS_FAIL',
    DOWNLOAD_RECORD = '@@records/DOWNLOAD_RECORD',
}
