import { PayloadAction, Reducer } from '@reduxjs/toolkit';
import { ErrorActionTypes, ErrorNamespaceShape } from './types';

const reduce: Reducer<ErrorNamespaceShape | null, any> = (state: ErrorNamespaceShape | null = null, action: PayloadAction<Error>) => {
    if ((/_(FAIL|ERROR)$/.test(action.type) && !action.type.startsWith('@@auth')) || action.type === ErrorActionTypes.SET_ERROR) {
        return {
            ...state,
            fromAction: action.type,
            content: action.payload,
        };
    }

    return state;
};

export default reduce;
