import { UiNamespaceShape } from './../store/ui/types';
import { SamplesNamespaceShape } from './../store/samples/types';
import { BooksNamespaceShape } from './../store/books/types';
import { Action, AnyAction, Reducer, Store } from 'redux';
import { Saga } from 'redux-saga';
import { AuthNamespaceShape } from '../store/auth/types';
import { MetaNamespaceShape } from '../store/meta/types';
import { AdminNamespaceShape } from './../store/admin/types';
import { AudioNamespaceShape } from './../store/audio/types';
import { ErrorNamespaceShape } from '../store/error/types';
import { ReservationsNamespaceShape } from '../store/reservations/types';
import { RecordsNamespaceShape } from '../store/records/types';

export const AUTH_NAMESPACE_KEY = 'auth';
export const ADMIN_NAMESPACE_KEY = 'admin';
export const META_NAMESPACE_KEY = 'meta';
export const AUDIO_NAMESPACE_KEY = 'audio';
export const BOOKS_NAMESPACE_KEY = 'books';
export const SAMPLES_NAMESPACE_KEY = 'samples';
export const UI_NAMESPACE_KEY = 'ui';
export const ERROR_NAMESPACE_KEY = 'error';
export const RESERVATIONS_NAMESPACE_KEY = 'reservations';
export const RECORDS_NAMESPACE_KEY = 'records';

export type FullStoreShape = {
    [AUTH_NAMESPACE_KEY]: AuthNamespaceShape;
    [ADMIN_NAMESPACE_KEY]: AdminNamespaceShape;
    [META_NAMESPACE_KEY]: MetaNamespaceShape;
    [AUDIO_NAMESPACE_KEY]: AudioNamespaceShape;
    [BOOKS_NAMESPACE_KEY]: BooksNamespaceShape;
    [SAMPLES_NAMESPACE_KEY]: SamplesNamespaceShape;
    [UI_NAMESPACE_KEY]: UiNamespaceShape;
    [ERROR_NAMESPACE_KEY]: ErrorNamespaceShape;
    [RESERVATIONS_NAMESPACE_KEY]: ReservationsNamespaceShape;
    [RECORDS_NAMESPACE_KEY]: RecordsNamespaceShape;
    // add all namespaces
};

export type StoreShape = Partial<FullStoreShape>;

export type NamespaceKey = keyof StoreShape;

export type ReducerMap = Partial<{ [k in NamespaceKey]: Reducer<FullStoreShape[k]> }>;

export interface ExtendedStore<S = any, A extends Action<any> = AnyAction> extends Store<S, A> {
    registerSaga(saga: Saga | Saga[]): void;
    registerReducer(newReducers: ReducerMap): void;
}

export interface StoreEntity<T> {
    byId: { [key: string]: T };
    allIds: string[];
}
