import { Sample, SampleState, StoreEntity } from '../../models';

export interface SamplesNamespaceShape {
    error: Error | null;
    entities: StoreEntity<Sample>;
    samplesLoading: boolean;
}

export enum SamplesActionTypes {
    APPEND_SAMPLES = '@@samples/APPEND_SAMPLES',
    UPDATE_SAMPLE_STATE = '@@samples/UPDATE_SAMPLE_STATE',
    SET_SAMPLE_LOADING = '@@samples/SET_SAMPLE_LOADING',
}

export interface UpdateSampleStatePayload {
    sampleId: string;
    newState: SampleState;
}

export interface SetSampleLoadingPayload {
    sampleId: string;
    loading: boolean;
}
