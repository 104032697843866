import { Action, PayloadAction } from '@reduxjs/toolkit';
import { UiNamespaceShape, UiActionTypes, ShowMessagePayload } from './types';
import { createReducer } from './../../utils/create-reducer';

const showMessage = (state: UiNamespaceShape, action: PayloadAction<ShowMessagePayload>) => {
    return {
        ...state,
        snackbarType: action.payload.type || 'info',
        snackbarMessage: action.payload.message,
        snackbarIsOpen: true,
    };
};

const hideMessage = (state: UiNamespaceShape, action: Action) => {
    return {
        ...state,
        snackbarIsOpen: false,
    };
};

const setHeaderTitle = (state: UiNamespaceShape, action: PayloadAction<string>) => {
    return {
        ...state,
        headerTitle: action.payload,
    };
};

const globalLoadingStart = (state: UiNamespaceShape, action: Action) => {
    return {
        ...state,
        uiLoading: true,
    };
};

const globalLoadingEnd = (state: UiNamespaceShape, action: Action) => {
    return {
        ...state,
        uiLoading: false,
    };
};

const setDrawerOpen = (state: UiNamespaceShape, action: PayloadAction<boolean>) => {
    return {
        ...state,
        drawerOpened: action.payload,
    };
};

const sendSupportMessageStart = (state: UiNamespaceShape, action: Action) => {
    return {
        ...state,
        supportMessageLoading: true,
    };
};

const sendSupportMessageSuccess = (state: UiNamespaceShape, action: Action) => {
    return {
        ...state,
        sentSupportMessage: true,
        supportMessageLoading: false,
    };
};

const sendSupportMessageFail = (state: UiNamespaceShape, action: Action) => {
    return {
        ...state,
        supportMessageLoading: false,
    };
};

const initialState: UiNamespaceShape = {
    snackbarMessage: '',
    snackbarIsOpen: false,
    snackbarType: undefined,
    headerTitle: '',
    drawerOpened: false,
    uiLoading: false,
    sentSupportMessage: false,
    supportMessageLoading: false,
};

const reduce = createReducer(initialState, {
    [UiActionTypes.SET_HEADER_TITLE]: setHeaderTitle,
    [UiActionTypes.SHOW_MESSAGE]: showMessage,
    [UiActionTypes.HIDE_MESSAGE]: hideMessage,
    [UiActionTypes.GLOBAL_LOADING_START]: globalLoadingStart,
    [UiActionTypes.GLOBAL_LOADING_END]: globalLoadingEnd,
    [UiActionTypes.SET_DRAWER_OPEN]: setDrawerOpen,
    [UiActionTypes.SEND_SUPPORT_MESSAGE_START]: sendSupportMessageStart,
    [UiActionTypes.SEND_SUPPORT_MESSAGE_SUCCESS]: sendSupportMessageSuccess,
    [UiActionTypes.SEND_SUPPORT_MESSAGE_FAIL]: sendSupportMessageFail,
});

export default reduce;
