import { CircularProgress, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import { MenuBook, Publish } from '@material-ui/icons';
import MoreIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DateFormat from '../../components/DateFormat';
import UploadFile from '../../components/UploadFile';
import { Reservation } from '../../models';

const useStyles = makeStyles((theme) => ({
    actions: {
        paddingRight: theme.spacing(12),
    },
}));

interface ReservationsListProps {
    reservations: Reservation[];
    onDownloadReadable: (reservation: Reservation) => void;
    onUploadRecord: (event: React.ChangeEvent<HTMLInputElement>, reservation: Reservation) => void;
    isInactive?: boolean;
}

const ReservationsList = ({ reservations, onDownloadReadable, onUploadRecord, isInactive = false }: ReservationsListProps) => {
    const { t } = useTranslation(['translation', 'reservations']);
    const classes = useStyles();

    return (
        <List>
            {reservations.map((reservation, index) => (
                <React.Fragment key={reservation.Id}>
                    {index > 0 && <Divider component="li" />}
                    <ListItem className={classes.actions}>
                        <ListItemText
                            primary={t('reservations:reservation_title', {
                                bookName: reservation.Book.Name,
                                chapterName: reservation.BookPart.Name || reservation.BookPart.Sequence,
                            })}
                            secondary={
                                <React.Fragment>
                                    {t('valid_till')}: <DateFormat date={reservation.ValidTill}></DateFormat>
                                </React.Fragment>
                            }
                        />
                        {!isInactive && (
                            <ListItemSecondaryAction>
                                <IconButton edge="end" onClick={() => onDownloadReadable(reservation)}>
                                    <Tooltip title={t('reservations:download_written_version') as string}>
                                        <MenuBook />
                                    </Tooltip>
                                </IconButton>
                                <UploadFile
                                    componentProps={{ edge: 'end', component: 'span' }}
                                    component={IconButton}
                                    accept=".mp3"
                                    fileChange={(event) => onUploadRecord(event, reservation)}>
                                    <Tooltip title={t('reservations:upload_audio') as string}>
                                        <Publish />
                                    </Tooltip>
                                </UploadFile>
                                {/* {!reservation.loading ? (
                                    <IconButton edge="end" aria-label="more" onClick={(event) => onOpenOptions(event, reservation)}>
                                        <MoreIcon />
                                    </IconButton>
                                ) : (
                                    <CircularProgress size={24} />
                                )} */}
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                </React.Fragment>
            ))}
        </List>
    );
};
export default ReservationsList;
