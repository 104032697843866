import { getUserRecordsFail, getUserRecordsSuccess } from './actions';
import { AxiosError, AxiosResponse } from 'axios';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put } from 'redux-saga/effects';
import { Record } from '../../models';
import api from '../../utils/api';
import { RecordActionTypes } from './types';
import download from '../../utils/download';
import getFilenameFromContentDisposition from '../../utils/get-filename';

const recordEndpoint = '/Record';

function* getUserRecorsSaga(action: Action) {
    const url = recordEndpoint;
    try {
        const response: AxiosResponse<Record[]> = yield api.get(url);
        yield put(getUserRecordsSuccess(response.data));
    } catch (error) {
        yield put(getUserRecordsFail(error));
    }
}

function* downloadRecordSaga(action: PayloadAction<Record>) {
    const record = action.payload;
    try {
        // yield put(actions.getSampleFileStart(action.payload));
        const url = `${recordEndpoint}/${record.Id}`;
        const response: AxiosResponse<Blob> = yield api.get(url, { responseType: 'blob' });
        // yield put(actions.getSampleFileEnd());

        const filename = yield getFilenameFromContentDisposition(response.headers['content-disposition']);
        download(filename, response.data);
    } catch (error) {
        // yield put(actions.getSampleFileEnd(error));
    }
}

export function* watchRecords() {
    yield takeEvery(RecordActionTypes.GET_USER_RECORDS, getUserRecorsSaga);
    yield takeEvery(RecordActionTypes.DOWNLOAD_RECORD, downloadRecordSaga);
}
