import { Container, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import NumberedList from '../../components/NumberedList';
import Title from '../../components/Title';
import UiRouterLink from '../../components/UiRouterLink';

const useStyles = makeStyles((theme) => ({
    root: {
        '& ol': {
            margin: theme.spacing(1, 0, 3),

            '&$topList': {
                marginTop: theme.spacing(3),
            },

            '& > li': {
                position: 'relative',
                marginBottom: theme.spacing(2),
            },

            '& > li::before': {
                position: 'absolute',
                left: 0,
                transform: 'translateX(-120%)',
            },
        },
    },
    topList: {
        '& > li': {
            fontWeight: 'bold',
        },
        '& > li > *': {
            fontWeight: 'normal',
        },
    },
}));

const TermsOfUse = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="md">
            <Paper className={'lbx-paper ' + classes.root}>
                <Title>Smluvní podmínky LIBRIXY VOICE (dále jen „Podmínky“)</Title>

                <NumberedList className={classes.topList}>
                    <Typography component="li">
                        Úvodní ustanovení
                        <NumberedList>
                            <Typography component="li">
                                Společnost GROOP CZ s.r.o. IČ: 24806731, se sídlem Vršovická 927/56, Vršovice, 101 00 Praha 10 (dále jen „
                                <b>Provozovatel</b>“) provozuje technologickou platformu „LIBRIXY“, přístupnou prostřednictvím mobilní aplikace (dále
                                jen „<b>Librixy</b>“), která mimo jiné umožňuje registrovaným uživatelům aplikace užívat digitalizovaná autorská díla
                                (e-knihy, audioknihy) zpřístupněná v rozhraní aplikace.
                            </Typography>
                            <Typography component="li">
                                Provozovatel dále provozuje s aplikací Librixy propojenou webovou platformu „Librixy Voice“ (dále jen „
                                <b>Librixy Voice</b>“), která umožňuje registrovaným uživatelům (dále jen „<b>Interpret</b>“) a na základě těchto
                                Podmínek, namlouvat autorská díla (knihy) do podoby audioknih a po schválení Provozovatelem poskytovat vytvořené
                                audiozáznamy uživatelům aplikace Librixy.
                            </Typography>
                            <Typography component="li">
                                Těmito Podmínkami se řídí používání platformy Librixy Voice a veškerých s ní spojených práv a povinností. Tyto
                                Podmínky doplňují, nikoliv nahrazují Všeobecné a obchodní podmínky upravující používání mobilní aplikace LIBRIXY,
                                vztahující se na uživatele aplikace Librixy, které nejsou zněním těchto Podmínek dotčeny.
                            </Typography>
                            <Typography component="li">
                                Interpret prohlašuje, že při uzavírání smluvních vztahů s Provozovatelem na základě těchto Podmínek nevystupuje jako
                                spotřebitel.
                            </Typography>
                        </NumberedList>
                    </Typography>
                    <Typography component="li">
                        Seznam knih
                        <NumberedList>
                            <Typography component="li">
                                Provozovatel prohlašuje, že je na základě licenční či jiné smlouvy uzavřené s autorem knižního díla v tištěné či
                                digitalizované podobě či osobou autorem oprávněnou k výkonu majetkových autorských práv, anebo na základě zákona ve
                                vztahu k tzv. volným autorským dílům, oprávněn k užití autorských děl zpřístupněných prostřednictvím Účtu Interpreta
                                (dále jen „<b>Knihy</b>“) a umožnění užití Knih dalšími osobami včetně Interpreta způsobem uvedeným v těchto
                                Podmínkách.
                            </Typography>
                            <Typography component="li">
                                Seznam Knih v Librixy Voice je Provozovatel oprávněn kdykoliv měnit a aktualizovat i bez souhlasu či předchozího
                                upozornění uživatelů. V případě vyřazení určitého díla ze seznamu Knih v Librixy Voice platí, že k uvedenému okamžiku
                                Provozovatel ukončil licenci k užívání příslušné Knihy a Interpreti již nejsou oprávněni Knihy užívat ani v případě,
                                že budou řádně registrováni, mají rezervovanou Knihu či poskytli Provozovateli licenci k audioknize v souladu
                                s článkem 6 Podmínek. Provozovatel nezaručuje časové trvání licence k užívání Knih a neodpovídá za jakékoliv škody
                                způsobené zánikem licence k jejich užívání.
                            </Typography>
                        </NumberedList>
                    </Typography>

                    <Typography component="li">
                        Registrace
                        <NumberedList>
                            <Typography component="li">
                                Podmínkou používání platformy Librixy Voice je registrace Interpreta v platformě Librixy Voice, dostupné na adrese
                                https://voice.librixy.com/. Registrací v platformě Librixy Voice vyjadřuje Interpret souhlas s těmito Podmínkami,
                                které upravují smluvní vztah mezi ním a Provozovatelem.
                            </Typography>
                            <Typography component="li">
                                Po registraci vytvoří Provozovatel Interpretovi samostatný účet (dále jen „<b>Účet Interpreta</b>“), prostřednictvím
                                kterého bude Interpretovi zpřístupněna rezervovaná Kniha (Knihy) nebo její části, v rozsahu a množství povoleném
                                Provozovatelem, a Interpret splní povinnosti poskytnout své plnění dle těchto Podmínek.
                            </Typography>
                            <Typography component="li">
                                Veškeré údaje je Interpret povinen uvést správně, úplně a pravdivě. Změnu/aktualizaci údajů uvedených Interpretem při
                                registraci může Interpret provést kdykoliv, a to přímo prostřednictvím svého účtu.
                            </Typography>
                            <Typography component="li">
                                Každý Interpret je povinen chránit svoje údaje uvedené v rámci registrace před zneužitím a/nebo ztrátou. Provozovatel
                                neodpovídá za zneužití registračních/přihlašovacích údajů Interpreta ze strany třetích osob.
                            </Typography>
                            <Typography component="li">
                                Interpret se po vytvoření Účtu Interpreta zavazuje nahrát prostřednictvím Účtu Interpreta testovací nahrávku mluvené
                                řeči Interpreta (čtený obsah vhodného charakteru a přiměřené délky), za účelem posouzení kvality a případného
                                schválení ze strany Provozovatele. V případě neschválení testovací nahrávky Provozovatelem bude Interpretovi umožněno
                                nahrát další testovací nahrávku.
                            </Typography>
                        </NumberedList>
                    </Typography>
                    <Typography component="li">
                        Rezervace Knih
                        <NumberedList>
                            <Typography component="li">
                                Po schválení testovací nahrávky Interpreta dle odst. 3.5 Podmínek je Interpret prostřednictvím funkce rezervace
                                oprávněn si rezervovat ze seznamu Knih konkrétní autorské dílo (autorská díla) nebo jeho část, jehož interpretaci bude
                                provádět v souladu s těmito Podmínkami. Rezervací konkrétní Knihy či její částí se Interpret zavazuje, že provede
                                činnosti uvedené v článku 5 Podmínek, a poskytne Provozovateli licenci uvedenou v článku 6 Podmínek. Rezervace
                                nevylučuje právo Provozovatele poskytnout licenci k užití Knih nebo dotčených částí shodným způsobem třetím osobám,
                                zejména dalším interpretům.
                            </Typography>
                            <Typography component="li">
                                Provozovatel tímto poskytuje Interpretovi nevýhradní a časově omezené právo bezúplatného užití Knih nebo jejich
                                vybraných částí v rozsahu nezbytném pro splnění těchto Podmínek, a to namluvení vybraných Knih či jejich částí, které
                                si Interpret rezervuje výhradně za tímto účelem prostřednictvím platformy Librixy Voice. Užití Knih Interpretem pro
                                tento účel je limitováno dobou, po kterou bude příslušná Kniha nabízena Provozovatelem prostřednictvím platformy
                                Librixy Voice.
                            </Typography>
                            <Typography component="li">
                                Interpretům nejsou poskytována žádná práva k využití jakýchkoliv ochranných známek, obchodních názvů a dalších log
                                Poskytovatele ke komerčním účelům.
                            </Typography>
                            <Typography component="li">
                                Interpret není oprávněn využít přístup ke Knihám k jiným účelům, než stanoveným v těchto Podmínkách. Interpret je
                                povinen nakládat s obsahem Knih zpřístupněných mu prostřednictvím Librixy Voice nebo jiným způsobem v souladu
                                s právními předpisy. Nestanoví-li tyto Podmínky jinak, Interpret není oprávněn Knihy či jejich části jakkoliv
                                rozšiřovat, rozmnožovat, pronajímat či půjčovat, nebo jiným způsobem sdělovat veřejnosti.
                            </Typography>
                            <Typography component="li">
                                Interpret není oprávněn obcházet jakékoliv technologie používané Provozovatelem k ochraně obsahu Knih a aplikace
                                Librixy.
                            </Typography>
                            <Typography component="li">
                                Provozovatel je oprávněn v případě zjištění, že došlo k porušení těchto Podmínek nebo právních předpisů, přístup
                                Interpreta do platformy Librixy Voice omezit či zakázat, a to bez nároku na jakoukoli náhradu. Současně je
                                Provozovatel oprávněn po Interpretovi požadovat náhradu veškeré případně vzniklé újmy.
                            </Typography>
                        </NumberedList>
                    </Typography>
                    <Typography component="li">
                        Nahrávání audioknih
                        <NumberedList>
                            <Typography component="li">
                                Po rezervaci Knihy v Librixy Voice se Interpret zavazuje k výkonu umělecké činnosti, spočívající v osobním namluvení
                                vybrané Knihy či její části a zaznamenání výsledku v digitálním formátu mp3 s kvalitou (bitrate) minimálně 256kbps
                                (dále jen „<b>Nahrávka</b>“). Interpret se poté zavazuje Nahrávku vložit do platformy Librixy Voice prostřednictvím
                                Účtu Interpreta nejpozději do 1 měsíce od rezervace Knihy dle odst. 4.1 Podmínek.
                            </Typography>
                            <Typography component="li">
                                Vložením Nahrávky Interpret prohlašuje, že
                                <ul>
                                    <Typography component="li">
                                        Nahrávka není zatížena právy třetích osob, která by omezovala jeho využití Provozovatelem v souladu s těmito
                                        Podmínkami, zejména že Nahrávka je jeho vlastním (osobním) výkonem;
                                    </Typography>
                                    <Typography component="li">
                                        Interpret je jedinou osobou oprávněnou k majetkovým právům výkonného umělce k Nahrávce.
                                    </Typography>
                                </ul>
                            </Typography>
                        </NumberedList>
                    </Typography>
                    <Typography component="li">
                        Licenční smlouva
                        <NumberedList>
                            <Typography component="li">
                                Vložením Nahrávky do platformy Librixy Voice poskytuje Interpret Poskytovateli teritoriálně neomezenou, výhradní
                                licenci po dobu 40 let ke všem způsobům užití Nahrávky v její původní či upravené podobě Provozovatelem, a to zejména
                                právo:
                                <ul>
                                    <Typography component="li">
                                        rozmnožování Nahrávky, rozšiřování rozmnoženin Nahrávky, pronájem či půjčování Nahrávky, sdělování Nahrávky
                                        veřejnosti (uživatelům Librixy);
                                    </Typography>
                                    <Typography component="li">
                                        veřejného sdílení vybraných částí Nahrávky jako marketingových ukázek v souvislosti s propagací aplikace
                                        Librixy (uživatelům Librixy i dalším osobám). Za tímto účelem je Provozovatel oprávněn použít a zveřejnit i
                                        registrační údaje Interpreta v rozsahu jméno, příjmení a případně další údaje v popisu o Interpretovi;
                                    </Typography>
                                    <Typography component="li">upravovat Nahrávku v souladu s odst. 6.5 Podmínek</Typography>
                                </ul>
                                a Provozovatel se za poskytnutí této licence zavazuje Interpretovi zaplatit Odměnu stanovenou v článku 7 Podmínek
                                (dále jen „<b>Licenční smlouva</b>“).
                            </Typography>

                            <Typography component="li">
                                Provozovatel je oprávněn zejména k poskytování práva užití Nahrávky v původní či upravené podobě uživatelům aplikace
                                Librixy (podlicence) za odměnu a za podmínek stanovených dle rozhodnutí Provozovatele.
                            </Typography>
                            <Typography component="li">
                                Po vložení Nahrávky posoudí Provozovatel její kvalitu a vhodnost pro užití v aplikaci Librixy. Rozhodování o vhodnosti
                                a kvalitě Nahrávky je na úvaze Poskytovatele, odmítnutím Nahrávky z jakéhokoliv důvodu nevznikají Interpretovi žádné
                                nároky a Licenční smlouva odmítnutím Nahrávky ve vztahu k dotčené Nahrávce zaniká. Rozhodnutí Provozovatele o
                                schválení či odmítnutí Nahrávky je zobrazováno v Účtu Interpreta. Provozovatel není povinen Nahrávku schválit, a ani
                                po jejím schválení Nahrávku dále užívat.
                            </Typography>
                            <Typography component="li">
                                Nahrávka může být Interpretem vložena prostřednictvím Účtu Interpreta ve dvou podobách:
                                <ul>
                                    <Typography component="li">
                                        Nahrávka, která byla již Interpretem postprodukčně upravena do stavu vhodného pro využití v platformě Librixy,
                                        a nevyžaduje tak další úpravy ze strany Provozovatele (dále jen „<b>Upravená Nahrávka</b>“);
                                    </Typography>
                                    <Typography component="li">
                                        Nahrávka, která nebyla Interpretem před Nahráním postprodukčně upravena do stavu vhodného pro využití
                                        v platformě Librixy, a proto vyžaduje další úpravy ze strany Provozovatele (dále jen „
                                        <b>Neupravená Nahrávka</b>“).
                                    </Typography>
                                </ul>
                            </Typography>

                            <Typography component="li">
                                Interpret souhlasí s tím, aby Provozovatel provedl technické a jiné vhodné úpravy Upravené Nahrávky i Neupravené
                                Nahrávky (zvukové úpravy, střih, úprava názvu apod.) tak, aby byla vhodná pro další použití v Librixy. Vložení
                                Upravené Nahrávky nebrání Provozovateli provést další, případně potřebné úpravy Nahrávky. V případě nutnosti provedení
                                úprav Nahrávky ze strany Provozovatele, která byla Interpretem vložena jako Upravená Nahrávka, se taková Nahrávka pro
                                účely výpočtu odměny považuje za Neupravenou Nahrávku.
                            </Typography>
                            <Typography component="li">
                                Provozovatel je oprávněn Licenční smlouvu kdykoliv vypovědět s okamžitou účinností. Právo Interpreta na vzniklou
                                Odměnu do okamžiku ukončení Licenční smlouvy není dotčeno.
                            </Typography>
                            <Typography component="li">
                                Licenční smlouva se uzavírá na dobu 40 let. S ohledem na charakter plnění, očekávaný způsob využití Nahrávky a vztahy
                                Provozovatele s třetími stranami není Interpret oprávněn Licenční smlouvu vypovědět před uplynutím doby jejího trvání,
                                s výjimkou uvedenou v odst. 6.8 Podmínek.
                            </Typography>
                            <Typography component="li">
                                Interpret je oprávněn Licenční smlouvu ve vztahu k jednotlivým Nahrávkám vypovědět pouze v případě, že mu nebude řádně
                                uhrazena Odměna po dobu delší 30 dnů od obdržení výzvy Provozovateli učiněné prostřednictvím Účtu Interpreta, a
                                nebude-li to z důvodu zrušení či znepřístupnění Účtu Interpreta možné, pak písemně na adresu Provozovatele. Okamžikem
                                uplynutí výpovědní lhůty dle tohoto odstavce znepřístupní Poskytovatel danou Nahrávku uživatelům Librixy.
                            </Typography>
                        </NumberedList>
                    </Typography>
                    <Typography component="li">
                        Odměna Interpreta
                        <NumberedList>
                            <Typography component="li">
                                Za poskytnutí licenčních práv a provedení výkonu Interpreta ve vztahu k příslušné Nahrávce vzniká Interpretovi právo
                                výlučně na odměnu stanovenou v těchto Podmínkách (dále jen „<b>Odměna</b>“). Odměna je stanovena následovně:
                                <ul>
                                    <Typography component="li">
                                        v případě Upravené Nahrávky ve výši 60% z částky bez DPH, za kterou Provozovatel poskytne uživatelům Librixy
                                        podlicenci k Nahrávce v souladu s Licenční smlouvou
                                    </Typography>
                                    <Typography component="li">
                                        v případě Neupravené Nahrávky ve výši 40% z částky bez DPH, za kterou Provozovatel poskytne uživatelům Librixy
                                        podlicenci k Nahrávce v souladu s Licenční smlouvou
                                    </Typography>
                                </ul>
                            </Typography>

                            <Typography component="li">
                                V případě, že Nahrávka bude tvořit jen část Knihy (např. jednu kapitolu), bude Odměna dle předchozího odstavce
                                vypočtena jako poměrná částka z částky bez DPH, za kterou Provozovatel poskytne uživatelům Librixy podlicenci k celé
                                Knize, a to v poměru odpovídajícímu podílu Nahrávky na celé Knize. Pro tento účel se podíl Nahrávky na celé Knize
                                vypočte jako poměr počtu kapitol obsažených v Nahrávce k celkovému počtu kapitol v celé Knize.
                            </Typography>
                            <Typography component="li">
                                Výše Odměny, včetně přehledu částek, z kterých je Odměna vypočtena, je Interpretovi přístupna prostřednictvím Účtu
                                Interpreta.
                            </Typography>
                            <Typography component="li">
                                Odměna bude uhrazena převodem na bankovní účet Interpreta uvedený v okamžiku doručení žádosti Interpreta o vyplacení
                                vzniklé části Odměny převyšující částku 500Kč prostřednictvím Účtu Interpreta. Odměna nižší než 500Kč není splatná,
                                s výjimkou ukončení Licenční smlouvy. Splatnost Odměny je nejméně 15 dnů po doručení žádosti Provozovateli.
                            </Typography>
                            <Typography component="li">
                                Odměna zahrnuje veškeré nároky Interpreta vyplývající z uzavření Licenční smlouvy a plnění těchto Podmínek. Interpret
                                nemá nárok na náhradu žádných dalších nákladů či dodatečné odměny v souvislosti s poskytnutím licence či vytvořením
                                Nahrávky.
                            </Typography>
                            <Typography component="li">
                                V případě, že je Interpret plátcem DPH, je povinen o této skutečnosti Provozovatele nejpozději při registraci, jinak
                                bez zbytečného odkladu informovat.
                            </Typography>
                            <Typography component="li">
                                Provozovatel upozorňuje Interpreta na zákonné povinnosti vyplývající z poskytnutí licence dle Licenční smlouvy,
                                týkající se zejména srážkové daně, vyplývající z mezinárodních smluv o zamezení dvojího zdanění mezi Českou republikou
                                a zemí, v které je Interpret daňovým rezidentem. Poskytovatel Interpreta tímto výslovně upozorňuje, že Provozovatel
                                jako plátce licenční odměny dle Licenční smlouvy může být ze zákona povinen provést srážku z Odměny. Pokud tak stanoví
                                právní předpis, Interpret obdrží Odměnu sníženou o příslušnou část odpovídající srážkové dani. Interpret bere na
                                vědomí, že je povinen splnit své zákonné, zejména daňové povinnosti ve vztahu k Licenční smlouvě samostatně a
                                Provozovatel nenese odpovědnost za splnění zákonných povinností Interpreta.
                            </Typography>
                        </NumberedList>
                    </Typography>
                    <Typography component="li">
                        Zřeknutí se odpovědnosti; omezení ručení; odškodnění.
                        <NumberedList>
                            <Typography component="li">
                                Provozovatel se zříká jakýchkoliv prohlášení a záruk, ať už výslovných, nepřímých či zákonných, které nejsou výslovně
                                uvedeny v těchto Podmínkách. Provozovatel navíc nečiní žádná prohlášení, záruky nebo závazky týkající se
                                spolehlivosti, včasnosti, kvality, vhodnosti či dostupnosti platformy Librixy Voice nebo aplikace Librixy.
                            </Typography>
                            <Typography component="li">
                                Provozovatel nezaručuje, že uživatelé Librixy po zveřejnění Nahrávky budou mít zájem o využití Nahrávky. Vznik Odměny
                                a její výše je podmíněna úplatným využitím příslušné Nahrávky uživateli Librixy a nevzniká samotným zveřejněním
                                Nahrávky v aplikaci Librixy či platformě Librixy Voice.
                            </Typography>
                            <Typography component="li">
                                Provozovatel není odpovědný za jakékoliv jednání uživatelů v souvislosti s využíváním nahrávky prostřednictvím
                                aplikace Librixy. Dojde-li k porušení podlicenční smlouvy uživatelem aplikace Librixy, není Provozovatel povinen
                                vymáhat práva z porušení licenčního ujednání vůči uživatelům Librixy, a nenese odpovědnost vůči Interpretovi za taková
                                porušení. Tím není dotčeno právo Interpreta vymáhat své nároky vůči uživatelům přímo.
                            </Typography>
                        </NumberedList>
                    </Typography>
                    <Typography component="li">
                        Závěrečná ustanovení
                        <NumberedList>
                            <Typography component="li">
                                Na základě těchto Podmínek nevzniká pracovní poměr ani dohoda o pracích konaných mimo pracovní poměr podle zákona č.
                                262/2006 Sb., zákoníku práce.
                            </Typography>
                            <Typography component="li">
                                Pokud by se stalo některé ustanovení těchto Podmínek neplatným, neúčinným nebo nevymahatelným, zůstává platnost,
                                účinnost nebo vymahatelnost ostatních ustanovení nedotčena.
                            </Typography>
                            <Typography component="li">
                                Zánik právního vztahu Smluvních stran dle těchto Podmínek, zánik, zrušení či znepřístupnění Účtu Interpreta, nemá vliv
                                na existenci, trvání či právní účinky již poskytnuté Licence a uzavřených Licenčních smluv.
                            </Typography>
                            <Typography component="li">Podmínky nabývají platnosti a účinnosti dne 1.3.2021</Typography>
                            <Typography component="li">
                                Provozovatel je oprávněn Podmínky kdykoli jednostranně změnit. Změny Podmínek budou předem oznámeny na webových
                                stránkách Provozovatele na internetové adrese{' '}
                                <UiRouterLink to="/terms-of-use">https://voice.librixy.com/terms-of-use</UiRouterLink>.
                            </Typography>
                        </NumberedList>
                    </Typography>
                </NumberedList>
            </Paper>
        </Container>
    );
};

export default TermsOfUse;
