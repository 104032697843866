import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import recordImg from '../img/citanie_copy.png';
import downloadImg from '../img/stiahnut.png';
import HomeButton from './HomeButton';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: 1100,
        margin: 'auto',
    },
    title: {
        textAlign: 'center',
        fontWeight: 600,
    },
    stepImg: {
        maxWidth: '170px',
        height: 'auto',
        width: '100%',
    },
    stepsHolder: {
        justifyContent: 'center',
    },
    step: {
        textAlign: 'center',
        maxWidth: 350,
        margin: 'auto',
    },
    stepText: {
        fontSize: 18,
        marginBottom: 20,
    },
    add: {
        fontSize: theme.spacing(9),
        color: theme.palette.primary.main,
        fontWeight: 800,
        margin: '0 10px',
        lineHeight: 1,
    },
    stepSeparator: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 50,
    },
    line: {
        flexGrow: 1,
        backgroundColor: '#ececec',
        height: 5,
    },
    [theme.breakpoints.down('sm')]: {
        stepsHolder: {
            flexDirection: 'column',
        },
        line: {
            display: 'none',
        },
        stepSeparator: {
            marginTop: 0,
            justifyContent: 'center',
        },
    },
}));

const Requirements: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation('home');

    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h3" gutterBottom>
                {t('home:requirements.title')}
            </Typography>
            <Typography className={classes.title} variant="h5">
                {t('home:requirements.subtitle')}
            </Typography>
            <Grid container spacing={4} className={classes.stepsHolder}>
                <Grid item>
                    <div className={classes.step}>
                        <img className={classes.stepImg} src={downloadImg} alt="" />
                        <div>
                            <Typography className={classes.stepText}>
                                <Trans ns="home" i18nKey="home:requirements.step_registered" components={{ b: <b /> }} />
                            </Typography>
                            <HomeButton component={Link} to="/signup" color="primary" variant="contained" disableElevation>
                                {t('home:requirements.i_want_to_sign_up')}
                            </HomeButton>
                        </div>
                    </div>
                </Grid>
                <Grid item xs>
                    <div className={classes.stepSeparator}>
                        <div className={classes.line}></div>
                        <Typography className={classes.add}>+</Typography>
                        <div className={classes.line}></div>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.step}>
                        <img className={classes.stepImg} src={recordImg} alt="" />
                        <div>
                            <Typography className={classes.stepText}>
                                <Trans ns="home" i18nKey="home:requirements.step_tech" components={{ b: <b /> }} />
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Requirements;
