import {
    Avatar,
    Backdrop,
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    Grid,
    makeStyles,
    Menu,
    MenuItem,
    Paper,
    Typography,
} from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import MessageDialog from '../../components/MessageDialog';
import Title from '../../components/Title';
import UploadFile from '../../components/UploadFile';
import { Record, Reservation } from '../../models';
import { DistributionMethod } from '../../models/distribution-method';
import { selectAuth } from '../../store/auth/selectors';
import { cancelReservation, uploadRecord } from '../../store/auth/user/actions';
import { downloadBookPartReadable, downloadBookReadable } from '../../store/books/actions';
import { setError } from '../../store/error/actions';
import { downloadRecord, getUserRecords } from '../../store/records/actions';
import { selectRecordsByState, selectRecordsLoading } from '../../store/records/selectors';
import { selectGrouppedReservations } from '../../store/reservations/selectors';
import { MAX_RECORD_SIZE } from '../../constants';
import RecordsList from './RecordsList';
import ReservationsList from './ReservationsList';

const useStyles = makeStyles((theme) => ({
    sectionDivider: {
        margin: theme.spacing(4, 0),
    },
    filterContainer: {
        margin: theme.spacing(2, 0),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

enum RESERVATIONS_FILTER {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    TIMED_OUT = 'TIMED_OUT',
    DELETED = 'DELETED',
}

const Reservations = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { user } = useSelector(selectAuth);
    const groupped = useSelector((state) => selectGrouppedReservations(state, user!.Reservations));
    const recordsLoading = useSelector(selectRecordsLoading);
    const recordsByState = useSelector(selectRecordsByState);

    const [selectedFilter, setSelectedFilter] = useState<RESERVATIONS_FILTER>(RESERVATIONS_FILTER.ACTIVE);
    const [isListReservations, setIsListReservations] = useState<boolean>(true);

    const [printedBookMessageOpen, setPrintedBookMessageOpen] = useState<boolean>(false);

    const { t } = useTranslation(['translation', 'reservations', 'forms']);

    useEffect(() => {
        dispatch(getUserRecords());
    }, [dispatch]);

    const filters = Object.keys(RESERVATIONS_FILTER) as RESERVATIONS_FILTER[];
    const lists = {
        [RESERVATIONS_FILTER.ACTIVE]: groupped.ACTIVE,
        [RESERVATIONS_FILTER.TIMED_OUT]: groupped.PAST,
        [RESERVATIONS_FILTER.APPROVED]: recordsByState.APPROVED,
        [RESERVATIONS_FILTER.REJECTED]: recordsByState.DISAPPROVED,
        [RESERVATIONS_FILTER.PENDING]: recordsByState.PENDING,
        [RESERVATIONS_FILTER.DELETED]: [
            ...recordsByState.BOOK_DELETED,
            ...recordsByState.BOOK_PART_DELETED,
            ...groupped.BOOK_DELETED,
            ...groupped.BOOK_PART_DELETED,
        ],
    };

    const handleFilterChange = (newFilter: RESERVATIONS_FILTER) => {
        setSelectedFilter(newFilter);
        setIsListReservations([RESERVATIONS_FILTER.ACTIVE, RESERVATIONS_FILTER.TIMED_OUT].includes(newFilter));
    };

    const handleDownloadRecord = (record: Record) => {
        dispatch(downloadRecord(record));
    };

    const handleUploadAudio = (event: ChangeEvent<HTMLInputElement>, reservation: Reservation) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.size > MAX_RECORD_SIZE) {
                const message = t('forms:file_oversize', { sizeLimit: Math.floor(MAX_RECORD_SIZE / 1000000) });
                dispatch(setError({ message }));
            } else {
                dispatch(uploadRecord({ reservationId: reservation.Id, record: event.target.files[0] }));
            }
        }
    };

    const handleDownloadWrittenVersion = (reservation: Reservation) => {
        if (reservation.Book.DistributionMethod === DistributionMethod.ebook) {
            dispatch(downloadBookReadable(reservation.Book.BookId));
        } else if (reservation.Book.DistributionMethod === DistributionMethod.ebook_parts) {
            dispatch(downloadBookPartReadable({ bookId: reservation.Book.BookId, partId: reservation.BookPart.RecordableBookPartId }));
        } else {
            setPrintedBookMessageOpen(true);
        }
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className="lbx-paper">
                        <Title gutterBottom>{t('reservations:my_reservations')}</Title>

                        {user?.reservationsLoading || recordsLoading ? (
                            <Loader size={32} />
                        ) : (
                            <React.Fragment>
                                <div className={classes.filterContainer}>
                                    {filters.map((filter) => (
                                        <Chip
                                            key={filter}
                                            color={filter === selectedFilter ? 'primary' : 'default'}
                                            // size="small"
                                            label={t('reservations:filter', { context: filter })}
                                            avatar={<Avatar>{lists[filter].length}</Avatar>}
                                            onClick={() => handleFilterChange(filter)}
                                        />
                                    ))}
                                </div>

                                {isListReservations ? (
                                    <ReservationsList
                                        isInactive={selectedFilter === RESERVATIONS_FILTER.TIMED_OUT}
                                        reservations={lists[selectedFilter] as Reservation[]}
                                        onDownloadReadable={handleDownloadWrittenVersion}
                                        onUploadRecord={handleUploadAudio}
                                    />
                                ) : (
                                    <RecordsList records={lists[selectedFilter] as Record[]} onDownloadRecord={handleDownloadRecord} />
                                )}
                                {lists[selectedFilter].length === 0 && (
                                    <Typography color="textSecondary">{t('reservations:no_reservations')}</Typography>
                                )}
                            </React.Fragment>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <Backdrop open={Boolean(user!.recordUploading)} className="lbx-backdrop-top">
                <Box position="relative" display="inline-flex">
                    <CircularProgress variant="determinate" color="inherit" value={user!.uploadProgress} size={120} />
                    <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="h4" component="div" color="inherit">{`${user!.uploadProgress}%`}</Typography>
                    </Box>
                </Box>
            </Backdrop>

            <MessageDialog
                open={printedBookMessageOpen}
                onClose={() => setPrintedBookMessageOpen(false)}
                dialogTitle={t('reservations:printed_book_dialog.title')}
                dialogContent={t('reservations:printed_book_dialog.content')}>
                <Button color="primary" onClick={() => setPrintedBookMessageOpen(false)}>
                    {t('translation:ok_got_it')}
                </Button>
            </MessageDialog>
        </Container>
    );
};

export default Reservations;
