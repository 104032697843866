import { getStateAtNamespaceKey } from '../../utils/selectors';
import { StoreShape, BOOKS_NAMESPACE_KEY } from '../../models';

export const selectBooks = (state: StoreShape) => getStateAtNamespaceKey(state, BOOKS_NAMESPACE_KEY);

export const selectBooksLanguage = (state: StoreShape) => {
    const books = selectBooks(state);
    return books.language;
};

export const selectBooksPage = (state: StoreShape) => {
    const books = selectBooks(state);
    return books.page;
};

export const selectBooksPerPage = (state: StoreShape) => {
    const books = selectBooks(state);
    return books.perPage;
};

export const selectBooksOrderBy = (state: StoreShape) => {
    const books = selectBooks(state);
    return books.orderBy;
};

export const selectAllBooksIds = (state: StoreShape) => selectBooks(state).entities.allIds;

export const selectBookById = (state: StoreShape, bookId: string) => {
    const books = selectBooks(state).entities;

    if (!books.byId[bookId]) {
        throw new Error(`Book with id ${bookId} does not exist in books list.`);
    }
    return books.byId[bookId];
};

export const selectBookDetail = (state: StoreShape) => {
    const booksStore = selectBooks(state);

    return booksStore.bookDetail;
};
