import React from 'react';
import { makeStyles, Typography, TypographyProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 500,
        '&.MuiTypography-gutterBottom': {
            marginBottom: '1em',
        },
    },
}));

const Title = (props: any) => {
    const classes = useStyles();
    return (
        <Typography component="h2" variant="h5" color="primary" {...props} className={[classes.root, props.className].join(' ')}>
            {props.children}
        </Typography>
    );
};

export default Title;
