import { SupportMessagePayload, UiActionTypes } from './types';
import { takeEvery, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { STORAGE } from '../../constants';
import { setDrawerOpen, sendSupportMessageFail, sendSupportMessageSuccess, sendSupportMessageStart, showMessage } from './actions';
import api from '../../utils/api';
import i18n from '../../i18n';

function* initUI(action: PayloadAction<boolean>) {
    const isMobile = action.payload;
    const storageOpened = localStorage.getItem(STORAGE.drawerOpened);

    const parsedStorageOpened = storageOpened ? JSON.parse(storageOpened) : true;
    yield put(setDrawerOpen(!isMobile && parsedStorageOpened));
}

function* setDrawerOpenSaga(action: PayloadAction<boolean>) {
    yield localStorage.setItem(STORAGE.drawerOpened, JSON.stringify(action.payload));
}

function* sendSupportMessage(action: PayloadAction<SupportMessagePayload>) {
    const url = '/Email/Support';

    try {
        yield put(sendSupportMessageStart());
        yield api.post(url, action.payload);
        yield put(sendSupportMessageSuccess());

        yield put(showMessage('success', i18n.t('message_was_sent')));
    } catch (err) {
        yield put(sendSupportMessageFail());
    }
}

export default function* watchUI() {
    yield takeEvery(UiActionTypes.INIT, initUI);
    yield takeEvery(UiActionTypes.SET_DRAWER_OPEN, setDrawerOpenSaga);
    yield takeEvery(UiActionTypes.SEND_SUPPORT_MESSAGE, sendSupportMessage);
}
