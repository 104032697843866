import { Sample } from '../../models';

export enum AudioState {
    PLAYING = 'PLAYING',
    STOPPED = 'STOPPED',
    PAUSED = 'PAUSED',
    ERROR = 'ERROR',
}

export interface AudioNamespaceShape {
    url: string;
    audioName: string;
    state: AudioState;
    totalTime: number;
    currentTime: number;
}

export enum AudioActionTypes {
    SET_STATE = '@@audio/SET_STATE',
    SET_TOTAL_TIME = '@@audio/SET_TOTAL_TIME',
    SET_CURRENT_TIME = '@@audio/SET_CURRENT_TIME',
    PLAY_AUDIO_FILE = '@@audio/PLAY_AUDIO_FILE',
    PLAY_AUDIO_FILE_FAIL = '@@audio/PLAY_AUDIO_FILE_FAIL',
}

export interface PlayAudioPayload {
    audioName: string;
    url: string;
}
