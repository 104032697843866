import { Grid, Typography, Box, List, ListItem, ListItemText, Link } from '@material-ui/core';
import React from 'react';
import Title from '../../../components/Title';

import echo from './img/echo.png';
import readingImage from './img/reading.png';

const Environment = ({ classes }: { classes: Record<string, string> }) => {
    return (
        <React.Fragment>
            <div className={classes.section}>
                <Title variant="h3" gutterBottom>
                    Prostředí
                </Title>
                <Grid container>
                    <Grid item xs>
                        <Typography>
                            Pokud chcete, aby vaše zvukové nahrávky byly dostatečně kvalitní, musíte myslet i na <b>prostředí</b>, ve kterém budete
                            svoji audioknihu nahrávat. Kvalitu zvuku kromě vašeho vybavení totiž zásadně ovlivňuje to, kde zrovna nahráváte. Tím
                            nejdůležitějším, na co byste si měli dát pozor, je <b>ozvěna</b>.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div className={classes.sectionImage}>
                            <img className={classes.img} src={readingImage} alt="Reading a book" />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.section}>
                <Title gutterBottom>Jak vzniká ozvěna?</Title>
                <Typography>
                    Ozvěna vzniká zcela přirozeně. Zvukové vlny se šíří vzduchem a v případě, že narazí na nějakou překážku, odrazí se. Například
                    takto:
                </Typography>
                <Box className={classes.centeredImage}>
                    <img className={classes.img} src={echo} alt="Echo visualization" />
                </Box>
                <Typography>
                    Pro účely nahrávání audioknih se však jedná o negativní jev. Zvuk (reprezentující například přečtenou větu z knihy) totiž
                    v takovém případě mikrofon zachytává víckrát, protože se po cestě ještě odrazí například od stěny či jiné překážky. A právě tomu
                    se chceme vyvarovat.
                </Typography>
            </div>

            <div className={classes.section}>
                <Title gutterBottom>Jak na ozvěnu?</Title>
                <Typography>Vyhnout se ozvěně můžete hned několika způsoby:</Typography>
                <List>
                    <ListItem disableGutters>
                        <ListItemText>
                            V první řadě můžete nahrávat v jiné místnosti, která bude pro tyto účely lepší. Může se jednat například o menší místnost,
                            umožní nahrávat bez zbytečného odrážení zvukových vln, které v nahrávce slyšet nechcete.
                        </ListItemText>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText>
                            Upravit rozložení a nábytek v dané místnosti tak, aby docházelo k co nejmenšímu odrazu zvukových vln. Můžete také využít
                            různých paravanů, které vám příliš velkou místnost rozdělí a zároveň budou do určité míry zvuk pohlcovat. A pokud to
                            budete myslet vážně, lze koupit i{' '}
                            <Link href="https://cs.qaz.wiki/wiki/Acoustic_foam" target="_blank" rel="noreferrer">
                                akustickou pěnu
                            </Link>
                            , která je přímo určená pro tyto účely.
                        </ListItemText>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText>
                            Nikoliv stoprocentně, ale přitom do určité míry můžete ozvěnu z nahrávky odstranit až v postprodukci, tedy v době, kdy
                            budete nahrávku stříhat a dál upravovat. Spousta programů dnes již umí alespoň v základu odstranit efekt ozvěny tak, aby
                            nahrávku příliš nepoznamenala (zvuk totiž může někdy znít „plechově“).
                        </ListItemText>
                    </ListItem>
                </List>
            </div>
        </React.Fragment>
    );
};

export default Environment;
