import { Typography, Grid } from '@material-ui/core';
import React from 'react';
import Title from '../../../components/Title';
import { audioBookProcess } from './audioBookProcess';

const RecordingProcess = ({ classes }: { classes: Record<string, string> }) => {
    return (
        <React.Fragment>
            <div className={classes.section}>
                <Title variant="h3" gutterBottom>
                    Tvorba audioknihy
                </Title>
                <Typography>
                    Pokud již víte, jaký hardware a software chcete pro nahrávání audioknihy využít, máte připravené prostředí a představu, jak budete
                    s nahrávkou pracovat, dovolíme si vám na závěr představit pár konkrétních tipů k samotné tvorbě audioknihy. Zde je naše desatero
                    dobré audio tvorby.
                </Typography>
            </div>
            {audioBookProcess.map((step, index) => (
                <Grid container key={index} direction={index % 2 === 0 ? 'row' : 'row-reverse'} spacing={10} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h4" gutterBottom>
                            {step.title}
                        </Typography>
                        <Typography>{step.description}</Typography>
                    </Grid>
                    <Grid item>
                        <img className={classes.img} src={step.image} style={{ maxWidth: 150, maxHeight: 150 }} alt="" />
                    </Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
};

export default RecordingProcess;
