import { AppBar, Box, Hidden, IconButton, makeStyles, Menu, MenuItem, Toolbar } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import HomeButton from 'features/home/components/HomeButton';
import React, { MouseEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAuth } from '../../store/auth/selectors';
import Logo from '../Logo';
import UiRouterLink from '../UiRouterLink';
// import Button from '../Button';

const useStyles = (drawerWidth: number) =>
    makeStyles((theme) => ({
        toolbar: {
            paddingRight: 0, // keep right padding when drawer closed
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(4.5),
            [theme.breakpoints.down('xs')]: {
                marginRight: theme.spacing(1),
            },
        },
        menuButtonHidden: {
            display: 'none',
        },
        title: {
            flexGrow: 1,
        },
        rightMenu: {
            display: 'flex',
            alignSelf: 'stretch',
        },
        button: {
            fontSize: 12,
            height: 'auto',
        },
        accButton: {
            backgroundColor: theme.palette.primary.dark,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
    }))();

interface AppHeaderProps {
    isMobile: boolean;
    drawerWidth: number;
    drawerIsOpen: boolean;
    toggleDrawer: () => void;
    title: string;
}

const AppHeader = (props: AppHeaderProps) => {
    const classes = useStyles(props.drawerWidth);
    const [anchorEl, setAnchorEl]: [any, SetStateAction<any>] = useState(null);
    const { t } = useTranslation('translation', { useSuspense: false });

    const { isAuthenticated } = useSelector(selectAuth);

    const handleTopProfileMenuOpen = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTopProfileMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, isAuthenticated && !props.isMobile && props.drawerIsOpen && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <Box display="flex" alignItems="center">
                    {isAuthenticated && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label={t('open_drawer')}
                            onClick={() => props.toggleDrawer()}
                            className={clsx(classes.menuButton, props.drawerIsOpen && classes.menuButtonHidden)}>
                            <MenuIcon />
                        </IconButton>
                    )}

                    <UiRouterLink color="inherit" underline="none" to="/">
                        <Logo />
                    </UiRouterLink>
                </Box>
                <Box flex="1 1 auto"></Box>

                <div className={classes.rightMenu}>
                    {isAuthenticated ? (
                        <React.Fragment>
                            <Hidden only="xs">
                                <HomeButton
                                    className={clsx(classes.button, classes.accButton)}
                                    color="inherit"
                                    onClick={handleTopProfileMenuOpen}
                                    endIcon={<AccountCircleIcon />}>
                                    {t('account')}
                                </HomeButton>
                            </Hidden>
                            <Hidden smUp>
                                <IconButton color="inherit" onClick={handleTopProfileMenuOpen}>
                                    <AccountCircleIcon />
                                </IconButton>
                            </Hidden>
                        </React.Fragment>
                    ) : (
                        <HomeButton
                            className={clsx(classes.button, classes.accButton)}
                            component={Link}
                            color="inherit"
                            to="/login"
                            endIcon={<AccountCircleIcon />}>
                            {t('sign_in_btn')}
                        </HomeButton>
                    )}
                    <Hidden only="xs">
                        <HomeButton className={classes.button} color="inherit" component={Link} to="/help" endIcon={<Help />}>
                            {t('help')}
                        </HomeButton>
                    </Hidden>

                    <Menu
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleTopProfileMenuClose}>
                        <MenuItem component={Link} to="/change-password" onClick={handleTopProfileMenuClose}>
                            {t('change_password')}
                        </MenuItem>
                        <MenuItem component={Link} to="/logout" onClick={handleTopProfileMenuClose}>
                            {t('logout')}
                        </MenuItem>
                    </Menu>

                    {/* <Button color="inherit" component={Link} to="/logout"></Button> */}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default AppHeader;
