import { compose } from 'redux';
import { __PROD__ } from '../constants';

export const composeEnhancers =
    (!__PROD__ &&
        window &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25,
        })) ||
    compose;
