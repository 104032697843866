import microphoneVoice from './img/microphone-voice.png';
import cutScissor from './img/cut-scissor.png';
import filters from './img/filters.png';
import readBook from './img/read-book.png';
import settingsLine from './img/settings-line.png';
import song from './img/song.png';
import textDocumentsLine from './img/text-documents-line.png';
import waterGlass from './img/water-glass.png';
import textFileLine from './img/text-file-line.png';
import copyright from './img/copyright.png';

export const audioBookProcess = [
    {
        title: '„Zkouška sirén“',
        description:
            'Než začnete se samotným nahráváním, vyzkoušejte veškerou používanou techniku. Prověřte, zda daný software či využívaná aplikace fungují, jak mají a jestli je nahrávka v takové kvalitě, s kterou jste spokojení. V případě, že využíváte externí mikrofon, ujistěte se, že jej máte nejen zapojený do zařízení, ale že jej máte v nastavení využívaného programu také aktivně zvolený. Může se totiž stát, že právě toto budete muset manuálně zajistit vy sami.',
        image: microphoneVoice,
    },
    {
        title: 'Příjem tekutin',
        description:
            'Nepodceňujte pravidelný a dostatečný příjem tekutin. Vyvarujte se pití kávy, sladkých či ochucených přípravků. Káva vás zbytečně dehydratuje a sladké pití naopak lepí jazyk k patru, což se v nahrávce může projevovat přítomností nepříjemného mlaskavého zvuku. Doporučujeme tedy pít vodu. V průběhu načítání vás tak nebude sužovat vyprahlé hrdlo ani žádné další neduhy.',
        image: waterGlass,
    },
    {
        title: 'Začněte s krátkým textem',
        description:
            'Neberte si příliš velké sousto. Pokud teprve začínáte s načítáním své první audioknihy, vyberte si takový text, který bude krátký. Pouze ostřílení řečníci nebudou mít problém číst dlouhé pasáže bez jakékoliv únavy. A věřte nám, unavený hlas je v nahrávce opravdu poznat. Vyberte si zároveň takovou knihu, kterou plánujete skutečně dokončit, k čemuž pomůže výběr něčeho kratšího.',
        image: textFileLine,
    },
    {
        title: 'První a druhé čtení',
        description:
            'Pokud to čas dovolí, aplikujte metodu takzvaného druhého čtení. Tedy že si nahrávání necháte až po prvním čtení, během něhož se s textem seznámíte. To vám pomůže správně intonovat, předpovídat pokles či zvýšení hlasu a podobně. Zkrátka a dobře budete vědět, co při předčítání od textu očekávat a jak správně příběh svým vyprávěním posluchači podat.',
        image: readBook,
    },
    {
        title: 'Načítejte obsahově celistvé části',
        description:
            'Z hlediska posluchačského zážitku je nejlepší nahrát celistvou obsahovou část. To znamená například celou kapitolu. Pokud budete tuto kapitolu nahrávat třeba natřikrát, je veliká pravděpodobnost, že jednotlivé nahrávky budou mít jiné (například) akustické vlastnosti, váš hlas bude posazený mírně odlišně a podobně. Vymezte si tedy ideálně takový čas, abyste celou kapitolu nejlépe načetli v rámci jedné nahrávky. Výhodu tento přístup skýtá také ve snadnější úpravě. Jedna nahrávka (tedy jedna kapitola) se vám bude zpracovávat snadněji než třeba pět různých nahrávek za sebou.',
        image: textDocumentsLine,
    },
    {
        title: 'Dbejte na stejné nastavení mikrofonu',
        description:
            'S předchozím bodem souvisí i upozornění na nutnost mít stejné nastavení mikrofonu pro nahrávání celé audioknihy. Pokud máte u mikrofonu nastavenou konkrétní citlivost (tedy to, jak bude mikrofon zachycovat váš hlas), nijak ji po načtení několika kapitol neupravujte. Zbytečně pak budete muset řešit, aby byly nahrávky stejně hlasité.',
        image: settingsLine,
    },
    {
        title: 'Přirozený střih',
        description:
            'Je jasné, že předčítat celou knihu či byť jen jednu kapitolu bez chyby je takřka nemožné. Právě z toho důvodu budete využívat editační software či aplikaci zdaleka nejvíce. Pokud se přeřeknete nebo obecně uděláte jakoukoliv chybu, zkuste se chvíli odmlčet a poté načíst větu znovu. Poté si stačí nahrávku zpětně pustit a chybu jednoduše vystřihnout. Při střihu však pamatujte na to, že by neměl posluchač poznat, že dříve v těchto místech nějaká chyba existovala. Snažte se tak střihem docílit co nejpřirozenější pauzy.',
        image: cutScissor,
    },
    {
        title: 'Jednotná postprodukce',
        description:
            'Velmi doporučujeme upravovat všechny nahrávky k dané audioknize jednotně. Pokud budete audio stopy upravovat například prostřednictvím efektů pro odšumění či pro zvýšení nebo snížení hlasitosti, dělejte tak pro všechny nahrávky stejně. Nechcete přeci, aby byl poslech nekonzistentní a tím pádem i rušící. Pro takovou úpravu je nejlepší audioknihu upravovat až v době, kdy ji máte načtenou celou. Pak efekty aplikujete na všechny nahrávky a máte vystaráno.',
        image: filters,
    },
    {
        title: 'Jak s hudbou a zvukovými efekty',
        description:
            'Říká se, že více je někdy méně. U tvorby své první audioknihy můžete jistě začít pouze s mluveným slovem. Nahrávka tak sice může působit „suše“ a nezáživně, ale bude se vám celkově upravovat mnohem snáze. S přibývajícími zkušenostmi můžete ke své audioknize připojit podkresovou hudbu či doplňující zvukové efekty, které budou dotvářet atmosféru celého načteného příběhu. Nejdříve můžete zkusit k nahrávce přidat úvodní hudební znělku. A jestli se vám experimentování zalíbí, můžete pokračovat dále.',
        image: song,
    },
    {
        title: 'Pozor na autorská práva',
        description:
            'Bylo by skvělé, kdybychom mohli využít jakoukoliv hudbu, kterou na internetu nalezneme. Realita je ovšem jiná. Ať už budete chtít načíst konkrétní audioknihu, využít nějaký hudební podkres či zvukové efekty, vždy se ujistěte, že nic z toho nepodléhá autorským právům. Využívejte vše, co je přístupné v rámci takzvané volné licence. Nejlépe pod licencí Creative Commons, která v určitých případech zajišťuje, že můžete hudbu či cokoliv dalšího převzít, upravit a z komerčního hlediska využít.',
        image: copyright,
    },
];
