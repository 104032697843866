import React from 'react';
import Logout from '../features/auth/Logout';
import SignIn from '../features/auth/SignIn';
import SignUp from '../features/auth/Signup';
import BookDetail from '../features/books/BookDetail';
import ChangePassword from '../features/change-password/ChangePassword';
import Contact from '../features/contact/Contact';
import Dashboard from '../features/dashboard/Dashboard';
import Help from '../features/help/Help';
import Home from '../features/home/Home';
import Reservations from '../features/reservations/Reservations';
import TermsOfUse from '../features/terms-of-use/TermsOfUse';
import { UserRole } from '../models';
import { RouteConfig } from './../models/route-config';

const Admin = React.lazy(() => import('../features/admin'));

const routes: RouteConfig[] = [
    { path: '/', exact: true, component: Home, isPrivate: false },
    { path: '/login', exact: true, component: SignIn, isPrivate: false },
    { path: '/signup', exact: true, component: SignUp, isPrivate: false },
    { path: '/logout', exact: true, component: Logout, isPrivate: false },
    { path: '/terms-of-use', exact: true, component: TermsOfUse, isPrivate: false },
    { path: '/contact', exact: true, component: Contact, isPrivate: false },
    { path: '/help', exact: true, component: Help, isPrivate: false },
    { path: '/change-password', exact: true, component: ChangePassword, isPrivate: true },
    { path: '/book/:id', exact: true, component: BookDetail, isPrivate: true },
    { path: '/reservations', role: [UserRole.ADMIN, UserRole.NARRATOR], component: Reservations, isPrivate: true },
    { path: '/dashboard', exact: true, component: Dashboard, isPrivate: true },
    { path: '/admin', role: [UserRole.ADMIN], component: Admin, isPrivate: true },
];

export default routes;
