import { Divider, Drawer, IconButton, List, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems, confirmedUserItems, adminListItems } from './ListItems';
import { selectAuth } from '../../store/auth/selectors';
import { useSelector } from 'react-redux';
import { UserRole } from '../../models';
import { selectGrouppedReservations, selectReservationsByIds } from '../../store/reservations/selectors';

const useStyles = (drawerWidth: number) =>
    makeStyles((theme) => ({
        drawerPaper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        },
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
    }))();

interface AppDrawerProps {
    isMobile: boolean;
    isOpen: boolean;
    close: () => void;
    widthOpen: number;
    onItemClick: () => void;
}

const AppDrawer = (props: AppDrawerProps) => {
    const { isMobile, isOpen, close, widthOpen, onItemClick } = props;

    const classes = useStyles(widthOpen);
    const { user } = useSelector(selectAuth);
    const grouppedReservations = useSelector((state) => selectGrouppedReservations(state, user!.Reservations));

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            classes={{
                paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
            }}
            onClose={close}
            open={isOpen}>
            <div className={classes.toolbarIcon}>
                <IconButton onClick={close}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                {mainListItems(onItemClick)}
                {user!.Roles.length > 0 && confirmedUserItems(onItemClick, grouppedReservations.ACTIVE.length)}
            </List>
            {user!.Roles.includes(UserRole.ADMIN) && (
                <React.Fragment>
                    <Divider />
                    <List>{adminListItems(onItemClick)}</List>
                </React.Fragment>
            )}
        </Drawer>
    );
};

export default AppDrawer;
