import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Head from '../components/Head';
import InitialLoader from '../components/InitialLoader/InitialLoader';
import Loader from '../components/Loader';
import Routes from '../components/Routes';
import SnackbarMessage from '../components/SnackbarMessage';
import { __PROD__ } from '../constants';
import Layout from '../hoc/Layout';
import withErrorHandler from '../hoc/withErrorHandler';
import useGlobalStyles from '../hooks/global-styles';
import { initAuth } from '../store/auth/actions';
import { selectAuth } from '../store/auth/selectors';
import { selectError } from '../store/error/selectors';
import { selectMeta } from '../store/meta/selectors';
import { initUI, showMessage } from '../store/ui/actions';
import api from '../utils/api';
import routes from './routes';

const App = () => {
    useGlobalStyles();

    const theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const dispatch = useDispatch();
    const { initLoading, isAuthenticated } = useSelector(selectAuth);
    const meta = useSelector(selectMeta);
    const error = useSelector(selectError);

    useEffect(() => {
        dispatch(initAuth());
        dispatch(initUI(matchesMobile));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            let message = error.content.message;
            if (error.content.response?.data.Message) {
                message = error.content.response?.data.Message.replace(/\./, '');
            }

            dispatch(showMessage('error', message));
        }
    }, [dispatch, error]);

    if (initLoading) {
        return <InitialLoader />;
    }

    return (
        <React.Fragment>
            <Head meta={meta} />
            <Layout isAuthenticated={isAuthenticated} isMobile={matchesMobile}>
                <Suspense fallback={<Loader />}>
                    <Routes routesList={routes} noMatchRedirect="/dashboard" />
                </Suspense>
            </Layout>
            <Suspense fallback={null}>
                <SnackbarMessage />
            </Suspense>
        </React.Fragment>
    );
};

export default withRouter(withErrorHandler(App, api));
