import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '20px 0',
    },
    listMarker: {
        width: 55,
        height: 55,
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontSize: theme.spacing(3),
        fontWeight: 600,
        flexShrink: 0,
    },
    listContent: {
        paddingLeft: theme.spacing(5),
    },
    listDescription: {
        fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
        listItem: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        listContent: {
            padding: 0,
            paddingTop: theme.spacing(1.5),
        },
    },
}));

interface LargeOrderedItemProps {
    index: number;
    title: string;
    description: string;
}

const LargeOrderedItem: React.FC<LargeOrderedItemProps> = (props) => {
    const { index, title, description } = props;
    const classes = useStyles();

    return (
        <div className={classes.listItem}>
            <div className={classes.listMarker}>{index}.</div>
            <div className={classes.listContent}>
                <Typography variant="h4" gutterBottom>
                    {title}
                </Typography>
                <Typography className={classes.listDescription}>{description}</Typography>
            </div>
        </div>
    );
};

export default LargeOrderedItem;
