import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
    root: {
        counterReset: 'item',
        listStyleType: 'none',

        '& > li': {
            counterIncrement: 'item',
        },
        '& > li::before': {
            content: "counters(item, '.') '. '",
        },
    },
});

interface NumberedListProps {
    className?: string;
}

const NumberedList: React.FC<NumberedListProps> = (props) => {
    const classes = useStyles();
    const { className } = props;

    return <ol className={classes.root + ' ' + className}>{props.children}</ol>;
};

export default NumberedList;
