import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/App';
import i18n from './i18n';
import { Language } from './models';
import store from './store';
import { initBooksLanguage } from './store/books/actions';
import localizedTheme from './theme/theme';
import history from './utils/history';

const bootstrap = () => {
    store.dispatch(initBooksLanguage(new Intl.Locale(i18n.language).language as Language));

    const app = (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <StylesProvider>
                    <ThemeProvider theme={localizedTheme(i18n.language)}>
                        <CssBaseline />
                        <App />
                    </ThemeProvider>
                </StylesProvider>
            </ConnectedRouter>
        </Provider>
    );

    const rootElement = document.querySelector('#root');

    if (rootElement!.hasChildNodes()) {
        hydrate(app, rootElement);
    } else {
        render(app, rootElement);
    }
};

if (i18n.isInitialized) {
    bootstrap();
} else {
    i18n.on('initialized', () => bootstrap());
}
