import red from '@material-ui/core/colors/red';
import * as locales from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const palette = {
    // type: 'dark',
    common: {
        black: '#14201c',
        white: '#fff',
    },
    primary: {
        light: '#0dd08c',
        main: '#17ad79',
        dark: '#129165',
    },
    secondary: {
        main: '#19857b',
    },
    error: {
        main: red.A400,
    },
    background: {
        // default: '#fff',
    },
    contrastThreshold: 2.5,
};

const localizedTheme = (language: string) => {
    const resolvedLanguage = Object.keys(locales).find((localeKey) => localeKey.startsWith(language)) || '';

    return createTheme(
        {
            typography: {
                fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'sans-serif'].join(','),
                h4: {
                    fontSize: '2rem',
                    fontWeight: 600,
                },
                h3: {
                    textAlign: 'center',
                    fontWeight: 600,
                    fontSize: '2rem',
                    [defaultTheme.breakpoints.up('sm')]: {
                        fontSize: '2.5rem',
                    },
                    [defaultTheme.breakpoints.up('md')]: {
                        fontSize: '3rem',
                    },
                },
            },
            props: {
                MuiButton: {
                    disableElevation: true,
                },
                MuiTextField: {
                    variant: 'filled',
                },
                MuiSelect: {
                    variant: 'filled',
                },
            },
            overrides: {
                MuiButton: {
                    root: {
                        fontWeight: 600,
                    },
                    containedPrimary: {
                        backgroundImage: `linear-gradient(to top, ${palette.primary.main}, ${palette.primary.light})`,
                        '&$disabled': {
                            backgroundImage: 'none',
                        },
                    },
                },
                MuiToolbar: {
                    regular: {
                        minHeight: 100,
                    },
                },
            },
            mixins: {
                toolbar: {
                    minHeight: 100,
                },
            },
            palette,
        },
        (locales as Record<string, locales.Localization>)[resolvedLanguage]
    );
};

export default localizedTheme;
