import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import Environment from './Environment/Environment';
import HardwareSoftware from './HardwareSoftware/HardwareSoftware';
import Intro from './Intro/Intro';
import RecordingProcess from './RecordingProcess/RecordingProcess';
import Workflow from './Workflow/Workflow';

const useStyles = makeStyles((theme) => ({
    recordOptionImage: {
        maxWidth: 500,
    },
    audacityImage: {
        maxWidth: 250,
    },
    sectionImage: {
        maxWidth: 300,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    centeredImage: {
        padding: theme.spacing(2),
        maxWidth: 600,
        margin: 'auto',
    },
    section: {
        '&:not(:last-child)': {
            paddingBottom: theme.spacing(5),
        },
    },
    sectionBack: {
        padding: theme.spacing(6),
        '&:nth-child(2n)': {
            backgroundColor: theme.palette.background.paper,
        },
    },
}));

const Help = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.sectionBack}>
                <Container maxWidth="md">
                    <Intro classes={classes} />
                </Container>
            </div>
            <div className={classes.sectionBack}>
                <Container maxWidth="md">
                    <HardwareSoftware classes={classes} />
                </Container>
            </div>
            <div className={classes.sectionBack}>
                <Container maxWidth="md">
                    <Environment classes={classes} />
                </Container>
            </div>
            <div className={classes.sectionBack}>
                <Container maxWidth="md">
                    <Workflow classes={classes} />
                </Container>
            </div>
            <div className={classes.sectionBack}>
                <Container maxWidth="md">
                    <RecordingProcess classes={classes} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Help;
