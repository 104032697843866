import { Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@material-ui/core';
import React, { PropsWithChildren, ReactNode } from 'react';

interface MessageDialogProps extends PropsWithChildren<DialogProps> {
    dialogTitle: ReactNode | string;
    dialogContent?: ReactNode | string;
}

const MessageDialog = (props: MessageDialogProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>{props.children}</DialogActions>
        </Dialog>
    );
};

export default MessageDialog;
