import { createAction } from '@reduxjs/toolkit';
import { Book, BookDetail, Error, Reservation } from '../../models';
import { BooksOrder } from './../../models/books-order';
import { EntityId } from './../../models/entity-id';
import { Language } from './../../models/language';
import { BooksActionTypes, DownloadBookPartPayload } from './types';

export const initBooksLanguage = createAction<Language>(BooksActionTypes.INIT_BOOKS_LANGUAGE);

export const getBooks = createAction(BooksActionTypes.GET_BOOKS);
export const searchBooks = createAction<string>(BooksActionTypes.SEARCH_BOOKS);

export const getBooksStart = createAction(BooksActionTypes.GET_BOOKS_START);
export const getBooksSuccess = createAction<Book[]>(BooksActionTypes.GET_BOOKS_SUCCESS);
export const getBooksFail = createAction<Error>(BooksActionTypes.GET_BOOKS_FAIL);

export const resetBooks = createAction(BooksActionTypes.RESET_BOOKS);

export const getBookDetail = createAction<string>(BooksActionTypes.GET_BOOK_DETAIL);
export const getBookDetailStart = createAction(BooksActionTypes.GET_BOOK_DETAIL_START);
export const getBookDetailSuccess = createAction<BookDetail>(BooksActionTypes.GET_BOOK_DETAIL_SUCCESS);
export const getBookDetailFail = createAction<Error>(BooksActionTypes.GET_BOOK_DETAIL_FAIL);

export const clearBookDetail = createAction(BooksActionTypes.CLEAR_BOOK_DETAIL);

export const createReservation = createAction<string>(BooksActionTypes.RESERVE_BOOK_PART);

export const createReservationStart = createAction(BooksActionTypes.RESERVE_BOOK_PART_START);
export const createReservationSuccess = createAction<Reservation>(BooksActionTypes.RESERVE_BOOK_PART_SUCCESS);
export const createReservationFail = createAction<Error>(BooksActionTypes.RESERVE_BOOK_PART_FAIL);

export const closeReservationSuccessDialog = createAction(BooksActionTypes.CLOSE_RESERVATION_SUCCESS_DIALOG);

export const downloadBookReadable = createAction<EntityId>(BooksActionTypes.DOWNLOAD_BOOK_READABLE);
export const downloadBookPartReadable = createAction<DownloadBookPartPayload>(BooksActionTypes.DOWNLOAD_BOOK_PART_READABLE);

export const setBooksLanguage = createAction<Language>(BooksActionTypes.SET_BOOKS_LANGUAGE);
export const setBooksOrder = createAction<BooksOrder>(BooksActionTypes.SET_BOOKS_ORDER);
