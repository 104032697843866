import { EntityId } from './../../../models/entity-id';
import { getUserRecords } from './../../records/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { all, put, takeEvery } from 'redux-saga/effects';
import { Reservation, Sample, SampleDto, UserDto } from '../../../models';
import api from '../../../utils/api';
import getSagaEmitter from '../../../utils/get-saga-emitter';
import throttle from '../../../utils/throttle';
import { setReservations } from '../../reservations/actions';
import { appendSamples } from '../../samples/actions';
import * as actions from './actions';
import { UploadRecordPayload, UserActionTypes } from './types';
import { batch } from 'react-redux';

const samplesURL = `/Sample`;

const sagaEmitter = getSagaEmitter();

function* getUserInfoSaga() {
    yield put(actions.getUserStart());

    try {
        const getUserInfoURL = `/Account/UserInfo`;
        const response: AxiosResponse<UserDto> = yield api.get(getUserInfoURL);

        yield all([
            put(appendSamples(response.data.Samples as Sample[])),
            put(setReservations(response.data.Reservations)),
            put(actions.getUserSuccess(response.data)),
        ]);
    } catch (e) {
        yield put(actions.getUserFail(e));
    }
}

function* uploadProgressSaga(data: any) {
    yield put(actions.uploadFileProgress(data.loaded, data.total));
}

function* getSamplesSaga() {
    yield put(actions.getUserSamplesStart());

    try {
        const response: AxiosResponse<SampleDto[]> = yield api.get(samplesURL);
        yield put(appendSamples(response.data as Sample[])); // todo handle in parent reducer by catching single success action
        yield put(actions.getUserSamplesSuccess(response.data));
    } catch (error) {
        yield put(actions.getUserSamplesFail(error));
    }
}

function* getReservationsSaga() {
    const url = `/Reservation`;
    yield put(actions.getUserReservationsStart());

    try {
        const response: AxiosResponse<Reservation[]> = yield api.get(url);
        yield put(actions.getUserReservationsSuccess(response.data));
    } catch (error) {
        yield put(actions.getUserReservationsFail(error));
    }
}

function* uploadUserSampleSaga(action: PayloadAction<File>) {
    yield put(actions.uploadSampleStart());

    try {
        const file: File = action.payload;
        const formData = new FormData();
        yield formData.append('file', file);

        yield api.post(samplesURL, formData, {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: throttle((event: ProgressEvent) => sagaEmitter.emit(event), 50),
        });
        yield put(actions.uploadSampleSuccess());
        yield put(actions.getUserSamples());
    } catch (error) {
        yield put(actions.uploadSampleFail(error));
    }
}

function* cancelReservationSaga(action: PayloadAction<EntityId>) {
    const url = `/Reservation/${action.payload}`;

    try {
        yield api.delete(url);
        yield put(actions.cancelReservationSuccess(action.payload));
    } catch (error) {
        yield put(actions.cancelReservationFail(action.payload, error));
    }
}

function* uploadRecordSaga(action: PayloadAction<UploadRecordPayload>) {
    yield put(actions.uploadRecordStart());

    const url = `/Record`;
    const params = {
        reservationId: action.payload.reservationId,
    };
    try {
        const file: File = action.payload.record;
        const formData = new FormData();
        yield formData.append('file', file);

        yield api.post(url, formData, {
            params,
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: throttle((event: ProgressEvent) => sagaEmitter.emit(event), 50),
        });

        yield put(actions.uploadRecordSuccess());
        yield put(actions.getUserReservations());
        yield put(getUserRecords());
    } catch (error) {
        yield put(actions.uploadRecordFail(error));
    }
}

export function* watchUser() {
    yield takeEvery(sagaEmitter.channel, uploadProgressSaga);
    yield takeEvery(UserActionTypes.GET_USER, getUserInfoSaga);
    yield takeEvery(UserActionTypes.GET_SAMPLES, getSamplesSaga);
    yield takeEvery(UserActionTypes.GET_RESERVATIONS, getReservationsSaga);
    yield takeEvery(UserActionTypes.UPLOAD_SAMPLE, uploadUserSampleSaga);
    yield takeEvery(UserActionTypes.UPLOAD_RECORD, uploadRecordSaga);
    yield takeEvery(UserActionTypes.CANCEL_RESERVATION, cancelReservationSaga);
}
