import { InjectableModuleStore } from '../../models';
import reducer from './reducer';
import { watchRecords } from './sagas';

const mod: InjectableModuleStore = {
    reducer: reducer,
    sagas: [watchRecords],
};

export default mod;
