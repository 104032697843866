import { NamespacedAction } from './../../models/namespaced-action';
import { Action, EntityId, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, select, takeEvery } from 'redux-saga/effects';
import { Book, BookDetail, BooksOrder, BOOKS_NAMESPACE_KEY, Reservation } from '../../models';
import api from '../../utils/api';
import download from '../../utils/download';
import getFilenameFromContentDisposition from '../../utils/get-filename';
import { globalLoadingEnd, globalLoadingStart } from '../ui/actions';
import {
    createReservationFail,
    createReservationStart,
    createReservationSuccess,
    getBookDetailFail,
    getBookDetailStart,
    getBookDetailSuccess,
    getBooksFail,
    getBooksStart,
    getBooksSuccess,
} from './actions';
import { selectBooksLanguage, selectBooksOrderBy, selectBooksPage, selectBooksPerPage } from './selectors';
import { BooksActionTypes, DownloadBookPartPayload, ReserveBookPart } from './types';
import { PagingActionTypes } from '../paging/types';

const getBooksUrl = '/Book';

export function* getBooksSaga(action: PayloadAction<string>) {
    const language = yield select(selectBooksLanguage);
    const page = yield select(selectBooksPage);
    const perPage = yield select(selectBooksPerPage);
    const orderBy = yield select(selectBooksOrderBy);

    const params = {
        language: language,
        page: page,
        perPage: perPage,
        order: orderBy || BooksOrder.DEFAULT,
    };

    try {
        yield put(getBooksStart());

        const response: AxiosResponse<Book[]> = yield api.get(getBooksUrl, { params });
        yield put(getBooksSuccess(response.data));
    } catch (error) {
        yield put(getBooksFail(error));
    }
}

function* searchBooksSaga(action: PayloadAction<string>) {
    const language = yield select(selectBooksLanguage);
    const page = yield select(selectBooksPage);
    const perPage = yield select(selectBooksPerPage);
    const prefix = action.payload;

    const url = '/Book/searchByPrefix';
    const params = {
        page,
        perPage,
        language,
        prefix,
    };

    try {
        yield put(getBooksStart());
        const response: AxiosResponse<Book[]> = yield api.get(url, { params });
        yield put(getBooksSuccess(response.data));
    } catch (e) {
        yield put(getBooksFail(e));
    }
}

export function* getBookDetailSaga(action: PayloadAction<string>) {
    const url = `${getBooksUrl}/${action.payload}`;
    try {
        yield put(getBookDetailStart());
        const response: AxiosResponse<BookDetail> = yield api.get(url);
        yield put(getBookDetailSuccess(response.data));
    } catch (error) {
        yield put(getBookDetailFail(error));
    }
}

function* reserveBookPartSaga(action: PayloadAction<ReserveBookPart>) {
    const url = `/Reservation/${action.payload}`;

    try {
        yield put(createReservationStart());
        const response: AxiosResponse<Reservation> = yield api.post(url);
        yield put(createReservationSuccess(response.data));
    } catch (error) {
        yield put(createReservationFail(error));
    }
}

function* downloadBookReadableSaga(action: PayloadAction<EntityId>) {
    const bookId = action.payload;
    try {
        yield put(globalLoadingStart());
        const url = `/Book/${bookId}/download`;
        const response: AxiosResponse<Blob> = yield api.get(url, { responseType: 'blob' });
        yield put(globalLoadingStart());

        const filename = yield getFilenameFromContentDisposition(response.headers['content-disposition']);
        download(filename, response.data);
    } catch (error) {
        yield put(globalLoadingStart());
    }
}

function* downloadBookPartReadableSaga(action: PayloadAction<DownloadBookPartPayload>) {
    const { bookId, partId } = action.payload;
    try {
        yield put(globalLoadingStart());
        const url = `/Book/${bookId}/${partId}/download`;
        const response: AxiosResponse<Blob> = yield api.get(url, { responseType: 'blob' });
        yield put(globalLoadingEnd());

        const filename = yield getFilenameFromContentDisposition(response.headers['content-disposition']);
        download(filename, response.data);
    } catch (error) {
        yield put(globalLoadingStart());
    }
}

export function* watchBooks() {
    yield takeEvery(
        [
            BooksActionTypes.GET_BOOKS,
            BooksActionTypes.SET_BOOKS_LANGUAGE,
            BooksActionTypes.SET_BOOKS_ORDER,
            (action: NamespacedAction<any>) => Object.values(PagingActionTypes).includes(action.type) && action.namespace === BOOKS_NAMESPACE_KEY,
        ],
        getBooksSaga
    );
    yield takeEvery(BooksActionTypes.SEARCH_BOOKS, searchBooksSaga);
    yield takeEvery(BooksActionTypes.GET_BOOK_DETAIL, getBookDetailSaga);
    yield takeEvery(BooksActionTypes.RESERVE_BOOK_PART, reserveBookPartSaga);
    yield takeEvery(BooksActionTypes.DOWNLOAD_BOOK_READABLE, downloadBookReadableSaga);
    yield takeEvery(BooksActionTypes.DOWNLOAD_BOOK_PART_READABLE, downloadBookPartReadableSaga);
}
