import { Avatar, Button, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import BookIcon from '@material-ui/icons/Book';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BookPart } from '../models';

const useStyles = makeStyles((theme) => ({
    listItemContent: {
        minHeight: theme.spacing(9),
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
}));

interface BookPartItemProps {
    part: BookPart;
    actionButton: ReactNode;
}

const BookPartItem = ({ part, actionButton }: BookPartItemProps) => {
    const classes = useStyles();
    const { t } = useTranslation('book');

    return (
        <ListItem className={classes.listItemContent}>
            <ListItemAvatar>
                <Avatar variant="rounded" className={classes.avatar}>
                    <BookIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('book:chapter', { name: part.Sequence })} secondary={part.Name} />
            <ListItemSecondaryAction>{actionButton}</ListItemSecondaryAction>
        </ListItem>
    );
};

export default BookPartItem;
