import { makeStyles, Box, Grid } from '@material-ui/core';
import { Forward } from '@material-ui/icons';
import React from 'react';
import StepCard, { StepCardProps } from './StepCard';

const useStyles = makeStyles((theme) => ({
    stepArrowHolder: {
        height: '100%',
        fontSize: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stepArrow: {
        [theme.breakpoints.down('xs')]: {
            transform: 'rotate(90deg)',
        },
    },
}));

interface StepsGroupProps {
    steps: StepCardProps[];
}

const StepsGroup = (props: StepsGroupProps) => {
    const { steps } = props;
    const classes = useStyles();

    const stepArrow = (
        <div className={classes.stepArrowHolder}>
            <Forward color="action" fontSize="inherit" className={classes.stepArrow} />
        </div>
    );

    return (
        <Grid container>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    {index > 0 && (
                        <Grid item sm="auto" xs={12}>
                            {stepArrow}
                        </Grid>
                    )}
                    <Grid item sm xs={12}>
                        <StepCard key={index} {...step} />
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export default StepsGroup;
