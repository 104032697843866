import React, { ChangeEvent, PropsWithChildren } from 'react';

type UploadFileProps = PropsWithChildren<{
    accept: string;
    fileChange: (e: ChangeEvent<HTMLInputElement>) => void;
    component?: React.ComponentType;
    componentProps?: any;
}>;

const UploadFile = ({ accept, fileChange, component: Component, componentProps, ...props }: UploadFileProps) => {
    return (
        <React.Fragment>
            <label>
                <input type="file" style={{ display: 'none' }} accept={accept} onChange={fileChange} />
                {Component ? <Component {...componentProps}>{props.children}</Component> : props.children}
            </label>
        </React.Fragment>
    );
};

export default UploadFile;
