import { Error } from './../../models/request-error';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { Record } from '../../models';
import { createReducer } from '../../utils/create-reducer';
import { RecordActionTypes, RecordsNamespaceShape } from './types';

const setRecords = (state: RecordsNamespaceShape, action: PayloadAction<Record[]>) => {
    const newById = action.payload.reduce((acc, r: Record) => ({ ...acc, [r.Id]: r }), {});
    return {
        ...state,
        loading: false,
        entities: {
            ...state.entities,
            byId: newById,
            allIds: Object.keys(newById),
        },
    };
};

const getRecordsStart = (state: RecordsNamespaceShape, action: Action) => {
    return {
        ...state,
        loading: true,
    };
};

const getRecordsFail = (state: RecordsNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
};

const initialState: RecordsNamespaceShape = {
    entities: {
        byId: {},
        allIds: [],
    },
    error: null,
    loading: false,
};

const reducer = createReducer(initialState, {
    [RecordActionTypes.GET_USER_RECORDS_SUCCESS]: setRecords,
    [RecordActionTypes.GET_USER_RECORDS]: getRecordsStart,
    [RecordActionTypes.GET_USER_RECORDS_FAIL]: getRecordsFail,
});

export default reducer;
