import { EntityId } from './../../models/entity-id';
import { Reservation, ReservationState, RESERVATIONS_NAMESPACE_KEY, StoreShape } from '../../models';
import { getStateAtNamespaceKey } from '../../utils/selectors';

export const selectReservations = (state: StoreShape) => getStateAtNamespaceKey(state, RESERVATIONS_NAMESPACE_KEY);

export const selectReservationsByIds = (state: StoreShape, reservationIds: EntityId[]): Reservation[] => {
    const reservations = selectReservations(state).entities;
    return reservationIds.map((id: EntityId) => {
        if (!reservations.byId[id]) {
            throw Error(`Reservation id: ${id} not found in reservations collection!`);
        }
        return reservations.byId[id];
    });
};

export const selectReservationsByBookId = (state: StoreShape, bookId: EntityId): Reservation[] => {
    const reservations = selectReservations(state).entities;
    return reservations.allIds
        .filter((id) => reservations.byId[id].Book.BookId === bookId)
        .map((id: EntityId) => {
            if (!reservations.byId[id]) {
                throw Error(`Reservation id: ${id} not found in reservations collection!`);
            }
            return reservations.byId[id];
        });
};

export const selectGrouppedReservations = (state: StoreShape, reservationIds: EntityId[]) => {
    const reservations = selectReservationsByIds(state, reservationIds);
    const dateNow = new Date();
    return reservations.reduce(
        (
            acc: {
                COMPLETE: Reservation[];
                ACTIVE: Reservation[];
                PAST: Reservation[];
                BOOK_DELETED: Reservation[];
                BOOK_PART_DELETED: Reservation[];
            },
            reservation: Reservation
        ) => {
            if ([ReservationState.COMPLETE, ReservationState.BOOK_DELETED, ReservationState.BOOK_PART_DELETED].includes(reservation.State)) {
                return {
                    ...acc,
                    [reservation.State]: [
                        ...acc[reservation.State as ReservationState.COMPLETE | ReservationState.BOOK_DELETED | ReservationState.BOOK_PART_DELETED],
                        reservation,
                    ],
                };
            }
            if (new Date(reservation.ValidTill) < dateNow) return { ...acc, PAST: [...acc.PAST, reservation] };
            return { ...acc, ACTIVE: [...acc.ACTIVE, reservation] };
        },
        { COMPLETE: [], ACTIVE: [], PAST: [], BOOK_DELETED: [], BOOK_PART_DELETED: [] }
    );
};
