import { EntityId } from './../../../models/entity-id';
import { createAction } from '@reduxjs/toolkit';
import { Reservation, SampleDto, UserDto } from '../../../models';
import { UploadRecordPayload, UserActionTypes } from './types';

export const getUser = createAction(UserActionTypes.GET_USER);
export const getUserStart = createAction(UserActionTypes.GET_USER_START);
export const getUserFail = createAction<Error>(UserActionTypes.GET_USER_FAIL);
export const getUserSuccess = createAction<UserDto>(UserActionTypes.GET_USER_SUCCESS);

export const getUserSamples = createAction(UserActionTypes.GET_SAMPLES);
export const getUserSamplesStart = createAction(UserActionTypes.GET_SAMPLES_START);
export const getUserSamplesFail = createAction<Error>(UserActionTypes.GET_SAMPLES_FAIL);
export const getUserSamplesSuccess = createAction<SampleDto[]>(UserActionTypes.GET_SAMPLES_SUCCESS);

export const getUserReservations = createAction(UserActionTypes.GET_RESERVATIONS);
export const getUserReservationsStart = createAction(UserActionTypes.GET_RESERVATIONS_START);
export const getUserReservationsFail = createAction<Error>(UserActionTypes.GET_RESERVATIONS_FAIL);
export const getUserReservationsSuccess = createAction<Reservation[]>(UserActionTypes.GET_RESERVATIONS_SUCCESS);

export const uploadSample = createAction<File>(UserActionTypes.UPLOAD_SAMPLE);
export const uploadSampleStart = createAction(UserActionTypes.UPLOAD_SAMPLE_START);
export const uploadSampleFail = createAction<Error>(UserActionTypes.UPLOAD_SAMPLE_FAIL);
export const uploadSampleSuccess = createAction(UserActionTypes.UPLOAD_SAMPLE_SUCCESS);

export const uploadRecord = createAction<UploadRecordPayload>(UserActionTypes.UPLOAD_RECORD);
export const uploadRecordStart = createAction(UserActionTypes.UPLOAD_RECORD_START);
export const uploadRecordFail = createAction<Error>(UserActionTypes.UPLOAD_RECORD_FAIL);
export const uploadRecordSuccess = createAction(UserActionTypes.UPLOAD_RECORD_SUCCESS);

export const cancelReservation = createAction<EntityId>(UserActionTypes.CANCEL_RESERVATION);
export const cancelReservationSuccess = createAction<EntityId>(UserActionTypes.CANCEL_RESERVATION_SUCCESS);
export const cancelReservationFail = createAction(UserActionTypes.CANCEL_RESERVATION_FAIL, (reservationId: EntityId, error: Error) => ({
    payload: {
        reservationId,
        error,
    },
}));

export const uploadFileProgress = createAction(UserActionTypes.UPLOAD_FILE_PROGRESS, (loaded: number, total: number) => ({
    payload: {
        loaded,
        total,
    },
}));
