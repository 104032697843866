import { Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 200,
        margin: 'auto',
        padding: theme.spacing(1),
        textAlign: 'center',
        display: 'block',

        '& > img': {
            width: '100%',
        },
    },
}));

interface RecordAppItemProps {
    app: { url: string; name: string; image: string };
}

const RecordAppItem = ({ app }: RecordAppItemProps) => {
    const classes = useStyles();
    return (
        <Link className={classes.root} href={app.url} target="_blank" rel="noreferrer">
            <img src={app.image} alt={app.name} />
            <Typography>{app.name}</Typography>
        </Link>
    );
};

export default RecordAppItem;
