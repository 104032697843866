import { EntityId } from './../../../models/entity-id';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { Reservation, Sample, SampleDto, UploadProgressPayload, UserDto } from '../../../models';
import { createReducer } from '../../../utils/create-reducer';
import { BooksActionTypes } from '../../books/types';
import { UserActionTypes, UserNamespaceShape } from './types';

const samplesLoadingStart = (state: UserNamespaceShape, action: Action) => {
    return {
        ...state,
        samplesLoading: true,
    };
};

const uploadFileProgress = (state: UserNamespaceShape, action: PayloadAction<UploadProgressPayload>) => {
    return {
        ...state,
        uploadProgress: Math.round(100 * (action.payload.loaded / action.payload.total)),
    };
};

const uploadSampleStart = (state: UserNamespaceShape, action: Action) => {
    return {
        ...state,
        samplesLoading: true,
        uploadProgress: 0,
        error: null,
    };
};

const uploadSampleSuccess = (state: UserNamespaceShape, action: Action) => {
    return {
        ...state,
        samplesLoading: false,
        uploadProgress: 100,
    };
};

const uploadSampleFail = (state: UserNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        error: action.payload,
        samplesLoading: false,
    };
};

const uploadRecordStart = (state: UserNamespaceShape, action: Action) => {
    return {
        ...state,
        recordUploading: true,
        uploadProgress: 0,
        error: null,
    };
};

const uploadRecordSuccess = (state: UserNamespaceShape, action: Action) => {
    return {
        ...state,
        recordUploading: false,
        uploadProgress: 100,
    };
};

const uploadRecordFail = (state: UserNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        error: action.payload,
        samplesLoading: false,
    };
};

const getUserSamplesSuccess = (state: UserNamespaceShape, action: PayloadAction<Sample[]>) => {
    return {
        ...state,
        Samples: action.payload.map((sample: Sample) => sample.Id),
        samplesLoading: false,
    };
};

const samplesLoadingFail = (state: UserNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        error: action.payload,
        samplesLoading: false,
    };
};

const getReservationsStart = (state: UserNamespaceShape, action: Action) => {
    return {
        ...state,
        reservationsLoading: true,
    };
};

const getReservationsSuccess = (state: UserNamespaceShape, action: PayloadAction<Reservation[]>) => {
    return {
        ...state,
        Reservations: action.payload.map((r) => r.Id),
        reservationsLoading: false,
    };
};

const getReservationsFail = (state: UserNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        error: action.payload,
        reservationsLoading: false,
    };
};

const getUserStart = (state: UserNamespaceShape, action: Action) => {
    return {
        ...state,
        error: null,
        loading: true,
    };
};

const getUserFail = (state: UserNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
};

const getUserSuccess = (state: UserNamespaceShape, action: PayloadAction<UserDto>) => {
    return {
        ...state,
        ...action.payload,
        Samples: action.payload.Samples.map((sample: SampleDto) => sample.Id),
        Reservations: action.payload.Reservations.map((reservation: Reservation) => reservation.Id),
        loading: false,
        uploadProgress: 0,
    };
};

const reservationCreated = (state: UserNamespaceShape, action: PayloadAction<Reservation>) => {
    return {
        ...state,
        Reservations: [...state.Reservations, action.payload.Id],
    };
};

const cancelReservation = (state: UserNamespaceShape, action: PayloadAction<EntityId>) => {
    const reservationPos = state.Reservations.findIndex((r) => r === action.payload);
    if (reservationPos < 0) {
        return state;
    }

    return {
        ...state,
        Reservations: [...state.Reservations.slice(0, reservationPos), ...state.Reservations.slice(reservationPos + 1)],
    };
};

const reducerMap = {
    [UserActionTypes.GET_USER_START]: getUserStart,
    [UserActionTypes.GET_USER_FAIL]: getUserFail,
    [UserActionTypes.GET_USER_SUCCESS]: getUserSuccess,

    [UserActionTypes.GET_SAMPLES_START]: samplesLoadingStart,
    [UserActionTypes.GET_SAMPLES_SUCCESS]: getUserSamplesSuccess,
    [UserActionTypes.GET_SAMPLES_FAIL]: samplesLoadingFail,

    [UserActionTypes.GET_RESERVATIONS_START]: getReservationsStart,
    [UserActionTypes.GET_RESERVATIONS_SUCCESS]: getReservationsSuccess,
    [UserActionTypes.GET_RESERVATIONS_FAIL]: getReservationsFail,

    [UserActionTypes.UPLOAD_SAMPLE_START]: uploadSampleStart,
    [UserActionTypes.UPLOAD_SAMPLE_SUCCESS]: uploadSampleSuccess,
    [UserActionTypes.UPLOAD_SAMPLE_FAIL]: uploadSampleFail,

    [UserActionTypes.UPLOAD_RECORD_START]: uploadRecordStart,
    [UserActionTypes.UPLOAD_RECORD_SUCCESS]: uploadRecordSuccess,
    [UserActionTypes.UPLOAD_RECORD_FAIL]: uploadRecordFail,

    [UserActionTypes.UPLOAD_FILE_PROGRESS]: uploadFileProgress,

    [UserActionTypes.CANCEL_RESERVATION_SUCCESS]: cancelReservation,

    [BooksActionTypes.RESERVE_BOOK_PART_SUCCESS]: reservationCreated,
};

export default createReducer<UserNamespaceShape | null>(null, reducerMap);
