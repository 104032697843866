import React from 'react';
import { Button as UiButton, ButtonProps, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    relative: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -10,
    },
}));

type LbxButtonProps = ButtonProps & {
    loading?: boolean;
};

const Button = ({ loading = false, ...props }: LbxButtonProps) => {
    const classes = useStyles();

    return (
        // <div className={classes.relative}>
        <UiButton
            {...props}
            className={clsx(props.className, classes.relative)}
            disabled={props.disabled || loading}
            style={{ color: loading ? 'transparent' : '' }}>
            {props.children}
            {loading && <CircularProgress size={20} className={classes.buttonProgress} />}
        </UiButton>
        // </div>
    );
};

export default Button;
