import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { ExtendedStore, ReducerMap, StoreShape } from '../models';
import { composeEnhancers } from './compose-enhancers';
import history from './history';

let rootReducerMap: ReducerMap = {};

const sagaMiddleware = createSagaMiddleware();

function registerReducer(this: ExtendedStore, newReducers: ReducerMap): ExtendedStore<StoreShape> {
    const activeReducerNamespaces = Object.keys(rootReducerMap);
    const newReducerNamespaces = Object.keys(newReducers);

    if (newReducerNamespaces.some((newNamespace) => activeReducerNamespaces.includes(newNamespace))) {
        throw new Error('Tried to activate namespace that is already active!');
    }

    rootReducerMap = { ...rootReducerMap, ...newReducers };

    this.replaceReducer(combineReducers(rootReducerMap));
    return this;
}

function registerSaga(this: ExtendedStore, saga: Saga[] | Saga) {
    if (Array.isArray(saga)) {
        saga.forEach((s) => sagaMiddleware.run(s));
    } else {
        sagaMiddleware.run(saga);
    }
}

export default function configureStore(initialReducerMap: ReducerMap = {}, initialSagas: Saga[] = []): ExtendedStore {
    rootReducerMap = initialReducerMap;

    const rootReducer = combineReducers(rootReducerMap);

    const store: ExtendedStore = createStore(rootReducer, composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)));
    store.registerReducer = registerReducer;
    store.registerSaga = registerSaga;

    initialSagas.forEach((saga) => store.registerSaga(saga));

    return store;
}
