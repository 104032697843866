import { Button, ButtonTypeMap, ExtendButtonBase, withStyles } from '@material-ui/core';

const HomeButton = withStyles({
    root: {
        height: 54,
        fontSize: 16,
        paddingLeft: 30,
        paddingRight: 30,
    },
})(Button);

export default HomeButton as ExtendButtonBase<ButtonTypeMap<unknown, 'button'>>;
