import { Box, IconButton, InputAdornment, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { BooksOrder, Language } from '../models';

const useStyles = makeStyles((theme) => ({
    select: {
        minWidth: 200,
        margin: theme.spacing(2),
    },
}));

interface BooksFilterProps {
    selectedLanguage?: Language;
    orderBy: BooksOrder;
    searchPrefix: string;
    onClearSearch: () => void;
    onOrderChange: (event: ChangeEvent<{ value: unknown }>) => void;
    onLanguageChange: (event: ChangeEvent<{ value: unknown }>) => void;
    onSearchPrefixChange: (event: ChangeEvent<{ value: unknown }>) => void;
}

const BooksFilter = (props: BooksFilterProps) => {
    const { selectedLanguage, orderBy, searchPrefix, onClearSearch, onOrderChange, onLanguageChange, onSearchPrefixChange } = props;
    const { t } = useTranslation(['translation']);
    const classes = useStyles();

    const allLanguages = Object.keys(Language);
    const orderByOptions = Object.keys(BooksOrder);

    return (
        <Box ml={-2} mr={-2} mb={4}>
            <TextField size="small" select label={t('language')} className={classes.select} value={selectedLanguage} onChange={onLanguageChange}>
                {allLanguages.map((lang) => (
                    <MenuItem key={lang} value={lang}>
                        {t('language', { context: lang })}
                    </MenuItem>
                ))}
            </TextField>
            <TextField size="small" select label={t('orderBy')} className={classes.select} value={orderBy} onChange={onOrderChange}>
                {orderByOptions.map((opt) => (
                    <MenuItem key={opt} value={opt}>
                        {t('orderBy', { context: opt })}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                size="small"
                label={t('search_book_by_name')}
                className={classes.select}
                value={searchPrefix}
                onChange={onSearchPrefixChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchPrefix && (
                                <IconButton onClick={onClearSearch} edge="end">
                                    <Close />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default BooksFilter;
