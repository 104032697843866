import asr from './img/asr.png';
import auphonic from './img/auphonic.png';
import evr from './img/evr.png';
import hiq from './img/hiq.png';
import italk from './img/italk.png';
import rev from './img/rev.png';
import hyaWave from './img/hya-wave.png';
import oceanaudio from './img/oceanaudio.png';
import wavepad from './img/wavepad.png';

export const androidApps = [
    { url: 'https://play.google.com/store/apps/details?id=com.nll.asr', name: 'ASR Voice Recorder', image: asr },
    { url: 'https://play.google.com/store/apps/details?id=com.coffeebeanventures.easyvoicerecorder', name: 'Easy Voice Recorder', image: evr },
    { url: 'https://play.google.com/store/apps/details?id=com.hiqrecorder.free', name: 'Hi-Q MP3 Voice Recorder', image: hiq },
];

export const iosApps = [
    { url: 'https://apps.apple.com/us/app/rev-voice-recorder-memos/id598332111', name: 'Rev Voice Recorder & Memos', image: rev },
    { url: 'https://apps.apple.com/cz/app/italk-recorder/id293673304', name: 'iTalk Recorder', image: italk },
    { url: 'https://apps.apple.com/us/app/auphonic-recorder/id1081792712', name: 'Auphonic Recorder', image: auphonic },
];

export const pcApps = [
    { url: 'https://www.ocenaudio.com/en/startpage', name: 'oceanaudio', image: oceanaudio },
    { url: 'https://www.nch.com.au/wavepad/index.html', name: 'WavePad', image: wavepad },
    { url: 'https://hya.io/wave/', name: 'Hya-Wave', image: hyaWave },
];
