import { Box, Container, Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Copyright from '../Copyright';
import UiRouterLink from '../UiRouterLink';

const useStyles = makeStyles((theme) => ({
    footerItems: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    item: {
        padding: theme.spacing(2),
    },
    dividerItem: {
        padding: theme.spacing(2, 0),
    },
    link: {
        fontWeight: 500,
    },
    links: {
        display: 'flex',
    },
}));

const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation('translation', { useSuspense: false });

    const footerLinks = [
        { to: '/terms-of-use', label: t('terms') },
        { to: '/contact', label: t('contact') },
        { to: '/help', label: t('help') },
    ];

    return (
        <Container maxWidth="lg">
            <Box py={2} display="flex" className={classes.footerItems}>
                <Copyright className={[classes.item].join(' ')} />

                <div className={classes.links}>
                    {footerLinks.map((link, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 && (
                                <div className={classes.dividerItem}>
                                    <Divider orientation="vertical" />
                                </div>
                            )}
                            <UiRouterLink className={[classes.link, classes.item].join(' ')} to={link.to} color="textSecondary">
                                {link.label}
                            </UiRouterLink>
                        </React.Fragment>
                    ))}
                </div>
            </Box>
        </Container>
    );
};

export default Footer;
