import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from './../../utils/create-reducer';
import { PagingActionTypes, PagingNamespaceShape } from './types';

const initialState: PagingNamespaceShape = {
    page: 0,
    perPage: 20,
};

const resetPaging = (state: PagingNamespaceShape, action: Action): PagingNamespaceShape => {
    return { ...state, ...initialState };
};

const setPage = (state: PagingNamespaceShape, action: PayloadAction<number>): PagingNamespaceShape => {
    return { ...state, page: action.payload };
};
const setPerPage = (state: PagingNamespaceShape, action: PayloadAction<number>): PagingNamespaceShape => {
    return { ...state, page: 0, perPage: action.payload };
};

const reduce = createReducer(initialState, {
    [PagingActionTypes.SET_PAGE]: setPage,
    [PagingActionTypes.SET_PERPAGE]: setPerPage,
    [PagingActionTypes.RESET]: resetPaging,
});

export default reduce;
