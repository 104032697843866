import { AxiosConfig } from './../models/axios-config';
import { EntityId } from './../models/entity-id';
import axios, { AxiosInstance } from 'axios';
import { __API_URL__, __PROD__ } from '../constants';

const config: AxiosConfig = {
    baseURL: __API_URL__,
    withCredentials: true,
    handleError: true,
};

const api: AxiosInstance = axios.create(config);

export function wakeUp() {
    api.get('/Utility/Ping');
}

export function setAuthorizationHeader(value: string | null): void {
    if (value === null) {
        delete api.defaults.headers.common.Authorization;
    } else {
        api.defaults.headers.common.Authorization = `Bearer ${value}`;
    }
}

export function getUrlByFragments(...fragments: string[]): string {
    return [__API_URL__, ...fragments].join('/');
}

export function getBookCoverUrl(bookId: EntityId): string {
    return `https://librixyrecords.blob.core.windows.net/images/bookCover/${bookId}.jpg`;
}

export default api;
