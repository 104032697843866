import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { __IS_SNAPSHOT__, __PROD__, __DEFAULT_LANGUAGE__ } from './constants';
import pathLangDetector from './utils/path-lang-detector';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(pathLangDetector);

i18n.use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: [pathLangDetector.name, 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
        },
        lng: __IS_SNAPSHOT__ ? __DEFAULT_LANGUAGE__ : undefined,
        load: 'languageOnly',
        fallbackLng: __DEFAULT_LANGUAGE__,
        debug: !__PROD__,
        nonExplicitSupportedLngs: true,
    });

export default i18n;
