import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, fade, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Book } from '../../../models';
import { selectBookById } from '../../../store/books/selectors';
import { getBookCoverUrl } from '../../../utils/api';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 150,
    },
    cardArea: {
        display: 'flex',
        alignItems: 'start',
        height: '100%',
    },
    media: {
        display: 'flex',
        alignItems: 'flex-end',
        flexShrink: 0,
        width: 100,
        alignSelf: 'stretch',
        backgroundColor: theme.palette.grey[300],
    },
    progressWrapper: {
        width: '100%',
        backgroundColor: fade(theme.palette.primary.main, 0.8),
        padding: theme.spacing(1),
        textAlign: 'center',
        color: '#fff',
    },
    progressPercentage: {
        marginBottom: theme.spacing(0.25),
        fontWeight: 500,
    },
    bolder: {
        fontWeight: 500,
    },
    availableChaptersCount: {
        fontSize: '.65rem',
        lineHeight: 'normal',
    },
    content: {
        flex: '1 1 auto',
        padding: theme.spacing(1, 2),
    },
    bookName: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        lineHeight: 1.4,
    },
    authors: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
}));

interface BookListItemProps {
    bookId: string;
}

const BookListItem = (props: BookListItemProps) => {
    const classes = useStyles();
    const book = useSelector((state) => selectBookById(state, props.bookId));
    const { t } = useTranslation('book');

    const getBookProgress = (book: Book) => {
        if (book.BookParts === 0) {
            return 0;
        }
        return Math.round(((book.BookParts - book.PartsAvailableForRecord) / book.BookParts) * 100);
    };
    return (
        <Card className={classes.root} elevation={0}>
            <CardActionArea className={classes.cardArea} component={Link} to={`/book/${book.BookId}`}>
                <CardMedia image={getBookCoverUrl(book.BookId)} className={classes.media}>
                    <Box className={classes.progressWrapper}>
                        <Typography color="inherit" variant="subtitle2" className={classes.progressPercentage}>
                            {getBookProgress(book)}%{' '}
                            <Typography variant="caption" className={classes.bolder}>
                                {t('book:done')}
                            </Typography>
                        </Typography>
                        <Typography component="div" className={classes.availableChaptersCount} color="inherit" variant="caption">
                            {t('book:parts_available_count', { count: book.PartsAvailableForRecord })}
                        </Typography>
                    </Box>
                </CardMedia>
                <CardContent className={classes.content}>
                    <Typography className={classes.bookName} gutterBottom variant="subtitle1" component="h2">
                        {book.Name}
                    </Typography>
                    <Typography className={classes.authors} gutterBottom variant="caption" color="textSecondary" component="p">
                        {book.Authors}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="p">
                        {t('book:pages_count', { count: book.Pages })}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="p">
                        {t('book:chapters_count', { count: book.BookParts })}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default BookListItem;
