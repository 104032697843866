import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: (props: OverlayProps) => props.color,
        opacity: (props: OverlayProps) => (props.opacity! > 1 ? 1 : props.opacity),
    },
}));

interface OverlayProps {
    color: string;
    opacity?: number;
}

const Overlay: React.FC<OverlayProps> = (props) => {
    const { opacity = 0.8 } = props;
    const styles = useStyles({ ...props, opacity });

    return <div className={styles.overlay}></div>;
};

export default Overlay;
