import { Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Record, RecordState } from '../../models';

interface RecordsListProps {
    records: Record[];
    onDownloadRecord: (record: Record) => void;
}

const RecordsList = ({ records, onDownloadRecord }: RecordsListProps) => {
    const { t } = useTranslation(['translation', 'reservations']);

    return (
        <List>
            {records.map((record, index) => (
                <React.Fragment key={record.Id}>
                    {index > 0 && <Divider component="li" />}
                    <ListItem>
                        {[RecordState.BOOK_DELETED, RecordState.BOOK_PART_DELETED].includes(record.State) ? (
                            <ListItemText
                                primary={
                                    <Typography color="textSecondary">{t('reservations:reservation_deleted', { context: record.State })}</Typography>
                                }
                            />
                        ) : (
                            <React.Fragment>
                                <ListItemText
                                    primary={t('reservations:reservation_title', {
                                        bookName: record.Book.Name,
                                        chapterName: record.BookPart.Name || record.BookPart.Sequence,
                                    })}
                                    secondary={
                                        record.State === RecordState.DISAPPROVED &&
                                        record.RefuseReason && (
                                            <Typography color="textSecondary" variant="body2">
                                                {t('reason')}: {record.RefuseReason}
                                            </Typography>
                                        )
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="more" onClick={() => onDownloadRecord(record)}>
                                        <DownloadIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </React.Fragment>
                        )}
                    </ListItem>
                </React.Fragment>
            ))}
        </List>
    );
};

export default RecordsList;
