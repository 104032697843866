import { createAction } from '@reduxjs/toolkit';
import { Error } from '../../models';
import { AuthActionTypes, ChangePasswordPayload } from './types';

export const login = createAction(AuthActionTypes.LOGIN, (email: string, password: string) => ({
    payload: {
        email: email,
        password: password,
    },
}));

export const loginStart = createAction(AuthActionTypes.LOGIN_START);
export const loginSuccess = createAction(AuthActionTypes.LOGIN_SUCCESS);

export const loginFail = (error: Error) => {
    return {
        type: AuthActionTypes.LOGIN_FAIL,
        payload: error,
    };
};

export const signup = createAction(AuthActionTypes.SIGNUP, (userName: string, email: string, password: string, confirmPassword: string) => ({
    payload: {
        userName,
        email,
        password,
        confirmPassword,
    },
}));

export const signupStart = createAction(AuthActionTypes.SIGNUP_START);
export const signupSuccess = createAction(AuthActionTypes.SIGNUP_SUCCESS);
export const signupFail = createAction<Error>(AuthActionTypes.SIGNUP_FAIL);

export const logout = createAction(AuthActionTypes.LOGOUT);
export const logoutDone = createAction(AuthActionTypes.LOGOUT_DONE);
export const getUserInfo = createAction(AuthActionTypes.GET_USER_INFO);

export const setToken = createAction<string>(AuthActionTypes.SET_TOKEN);
export const setTokenExpirationTimeout = createAction<number>(AuthActionTypes.SET_TOKEN_EXP_TIMEOUT);

export const initAuth = createAction(AuthActionTypes.INIT);
export const initAuthStart = createAction(AuthActionTypes.INIT_START);
export const initAuthDone = createAction(AuthActionTypes.INIT_DONE);

export const refreshToken = createAction(AuthActionTypes.REFRESH_TOKEN);

export const changePassword = createAction<ChangePasswordPayload>(AuthActionTypes.CHANGE_PASSWORD);
export const changePasswordStart = createAction(AuthActionTypes.CHANGE_PASSWORD_START);
export const changePasswordDone = createAction(AuthActionTypes.CHANGE_PASSWORD_DONE);

export const clearError = createAction(AuthActionTypes.CLEAR_ERROR);
