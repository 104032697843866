import { makeStyles } from '@material-ui/core';
import React, { PropsWithChildren, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppDrawer from '../components/AppDrawer/AppDrawer';
import AppHeader from '../components/AppHeader/AppHeader';
import Footer from '../components/Footer/Footer';
import { setDrawerOpen } from '../store/ui/actions';
import { selectUI } from '../store/ui/selectors';

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        paddingTop: (props: LayoutProps) => (props.isAuthenticated ? 60 : 0),
    },
    appBarSpacer: theme.mixins.toolbar,
}));

interface LayoutProps {
    isAuthenticated: boolean;
    isMobile: boolean;
}

const Layout = (props: PropsWithChildren<LayoutProps>) => {
    const classes = useStyles(props);

    const dispatch = useDispatch();
    const ui = useSelector(selectUI);

    const handleDrawerOpen = () => {
        dispatch(setDrawerOpen(true));
    };
    const handleDrawerClose = () => {
        dispatch(setDrawerOpen(false));
    };
    const toggleDrawer = () => {
        if (ui.drawerOpened) {
            handleDrawerClose();
        } else {
            handleDrawerOpen();
        }
    };

    const handleDrawerItemClick = () => {
        if (props.isMobile) {
            handleDrawerClose();
        }
    };

    return (
        <div className={classes.root}>
            <Suspense fallback={null}>
                <AppHeader
                    isMobile={props.isMobile}
                    drawerWidth={DRAWER_WIDTH}
                    drawerIsOpen={ui.drawerOpened}
                    toggleDrawer={toggleDrawer}
                    title={ui.headerTitle}
                />
            </Suspense>
            {props.isAuthenticated && (
                <AppDrawer
                    onItemClick={handleDrawerItemClick}
                    isMobile={props.isMobile}
                    widthOpen={DRAWER_WIDTH}
                    isOpen={ui.drawerOpened}
                    close={handleDrawerClose}
                />
            )}

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <div>{props.children}</div>
                <Footer />
            </main>
        </div>
    );
};

export default Layout;
