import { EntityId } from './../../models/entity-id';
import { getStateAtNamespaceKey } from '../../utils/selectors';
import { Sample, SAMPLES_NAMESPACE_KEY, StoreShape } from '../../models';

export const selectSamples = (state: StoreShape) => getStateAtNamespaceKey(state, SAMPLES_NAMESPACE_KEY);

export const selectAllSamples = (state: StoreShape): Sample[] => Object.values(selectSamples(state).entities.byId);

export const selectSamplesByIds = (state: StoreShape, samplesIds: EntityId[]): Sample[] => {
    const samples = selectSamples(state).entities;
    return samplesIds.map((id: EntityId) => {
        if (!samples.byId[id]) {
            throw Error(`Sample id: ${id} not found in samples collection!`);
        }
        return samples.byId[id];
    });
};

export const selectSamplesByUserId = (state: StoreShape, userId: EntityId): Sample[] => {
    const samples = selectSamples(state).entities;
    return samples.allIds.filter((id) => samples.byId[id].UserId === userId).map((id) => samples.byId[id]);
};
