import { Action, createAction, PayloadAction } from '@reduxjs/toolkit';
import { NamespacedAction } from './../models/namespaced-action';

export function createNamespacedAction<P = void, T extends string = string>(
    type: T
): (namespace: string) => (payload?: any, prepare?: any) => NamespacedAction<PayloadAction | Action> {
    return (namespace: string) => {
        const actionCreator = createAction(type) as NamespacedAction<any>;
        return (payload, prepare) => {
            const action = actionCreator(payload, prepare);
            action.namespace = namespace;
            return action;
        };
    };
}
