import { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '../../utils/create-reducer';
import { MetaActionTypes, MetaNamespaceShape } from './types';

const setMeta = (state: MetaNamespaceShape, action: PayloadAction<MetaNamespaceShape>) => {
    return {
        ...state,
        ...action.payload,
    };
};

const initialState = {
    title: 'Librixy Voice',
    description: '',
};

const reducerMap = {
    [MetaActionTypes.SET_META]: setMeta,
};

export default createReducer(initialState, reducerMap);
