import { makeStyles } from '@material-ui/core';

const useGlobalStyles = makeStyles((theme) => ({
    '@global': {
        '.lbx-backdrop-top': {
            zIndex: theme.zIndex.drawer + 200,
            color: '#fff',
        },
        '.lbx-paper': {
            padding: theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),
            },
        },
    },
}));

export default useGlobalStyles;
