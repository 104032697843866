import { Error } from './../../models';

export enum ErrorActionTypes {
    SET_ERROR = '@@error/SET',
}

export interface ErrorNamespaceShape {
    fromAction: string;
    content: Error;
}
