import phoneRecord from './img/phone-record.png';
import phoneProcess from './img/phone-process.png';

import pcRecord from './img/pc-record.png';
import pcProcess from './img/pc-process.png';
import publish from './img/publish.png';

export const recordSteps1 = [
    { image: phoneRecord, title: 'Krok 1', imageAlt: 'Record via phone', description: 'Nahrajte zvuk s pomocí vámi vybrané aplikace' },
    {
        image: phoneProcess,
        title: 'Krok 2',
        imageAlt: 'Process via phone',
        description: 'Upravte zvukovou nahrávku s pomocí editoru přímo v aplikaci',
    },
    {
        image: publish,
        title: 'Krok 3',
        imageAlt: 'Publish record',
        description: 'Upravenou zvukovou nahrávku publikujte na web do systému Librixy Voice',
    },
];

export const recordSteps2 = [
    {
        image: pcRecord,
        title: 'Krok 1',
        imageAlt: 'Record via PC',
        description: 'Nahrajte zvuk s pomocí externího mikrofonu a vámi vybraného audio editoru',
    },
    {
        image: pcProcess,
        title: 'Krok 2',
        imageAlt: 'Process via PC',
        description: 'Upravte zvukovou nahrávku s pomocí editoru přímo v počítači',
    },
    {
        image: publish,
        title: 'Krok 3',
        imageAlt: 'Publish record',
        description: 'Upravenou zvukovou nahrávku publikujte na web do systému Librixy Voice',
    },
];

export const recordSteps3 = [
    { image: phoneRecord, title: 'Krok 1', imageAlt: 'Record via phone', description: 'Nahrajte zvuk s pomocí vámi vybrané aplikace' },
    {
        image: pcProcess,
        title: 'Krok 2',
        imageAlt: 'Process via pc',
        description: 'Zvukovou nahrávku si importujte do počítače a upravte ji s pomocí editoru',
    },
    {
        image: publish,
        title: 'Krok 3',
        imageAlt: 'Publish record',
        description: 'Upravenou zvukovou nahrávku publikujte na web do systému Librixy Voice',
    },
];

export const recordSteps4 = [
    {
        image: pcRecord,
        title: 'Krok 1',
        imageAlt: 'Record via PC',
        description: 'Nahrajte zvuk s pomocí externího mikrofonu a vámi vybraného audio editoru',
    },
    {
        image: phoneProcess,
        title: 'Krok 2',
        imageAlt: 'Process via phone',
        description: 'Importujte zvukovou nahrávku do mobilu a upravte ji s pomocí editoru přímo v aplikaci',
    },
    {
        image: publish,
        title: 'Krok 3',
        imageAlt: 'Publish record',
        description: 'Upravenou zvukovou nahrávku importujte do počítače a publikujte na web do systému Librixy Voice',
    },
];
