import { InjectableModuleStore } from '../../models';
import reduce from './reducer';
import { watchBooks } from './sagas';

const mod: InjectableModuleStore = {
    reducer: reduce,
    sagas: [watchBooks],
};

export default mod;
