import { connectRouter } from 'connected-react-router';
import {
    AUDIO_NAMESPACE_KEY,
    AUTH_NAMESPACE_KEY,
    BOOKS_NAMESPACE_KEY,
    ERROR_NAMESPACE_KEY,
    META_NAMESPACE_KEY,
    RECORDS_NAMESPACE_KEY,
    RESERVATIONS_NAMESPACE_KEY,
    SAMPLES_NAMESPACE_KEY,
    UI_NAMESPACE_KEY,
} from '../models';
import configureStore from '../utils/configure-store';
import history from '../utils/history';
import audioModuleStore from './audio';
import authModuleStore from './auth';
import booksModuleStore from './books';
import errorModuleStore from './error';
import metaModuleStore from './meta';
import recordsModule from './records';
import reservationsModule from './reservations';
import samplesModuleStore from './samples';
import uiModuleStore from './ui';

const initialStore = {
    router: connectRouter(history),

    [AUTH_NAMESPACE_KEY]: authModuleStore.reducer,
    [META_NAMESPACE_KEY]: metaModuleStore.reducer,
    [AUDIO_NAMESPACE_KEY]: audioModuleStore.reducer,
    [BOOKS_NAMESPACE_KEY]: booksModuleStore.reducer,
    [SAMPLES_NAMESPACE_KEY]: samplesModuleStore.reducer,
    [UI_NAMESPACE_KEY]: uiModuleStore.reducer,
    [ERROR_NAMESPACE_KEY]: errorModuleStore.reducer,
    [RESERVATIONS_NAMESPACE_KEY]: reservationsModule.reducer,
    [RECORDS_NAMESPACE_KEY]: recordsModule.reducer,
};

const initialSagas = [...uiModuleStore.sagas, ...authModuleStore.sagas, ...audioModuleStore.sagas, ...booksModuleStore.sagas, ...recordsModule.sagas];

const store = configureStore(initialStore, initialSagas);

export default store;
