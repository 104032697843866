import { eventChannel } from 'redux-saga';
import { SagaEmitter } from './../models';

function getSagaEmitter(): SagaEmitter {
    const emitter: any = {
        emit: () => void 0,
    };

    emitter.channel = eventChannel((emitChange) => {
        emitter.emit = emitChange;
        return () => {
            // do nothing
        };
    });

    return emitter as SagaEmitter;
}

export default getSagaEmitter;
