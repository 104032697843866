import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import libImg from '../img/ConnectionBooks.png';
import recordImg from '../img/ConnectionRecord.png';
import DoubleArrow from './DoubleArrow';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        textAlign: 'center',
        maxWidth: 600,
        fontWeight: 600,
        marginBottom: 60,
    },
    imageCircle: {
        borderRadius: '50%',
        backgroundSize: 'cover',
        width: 350,
        height: 350,
    },
    connectionsHolder: {
        display: 'flex',
        justifyContent: 'center',
    },
    connection: {
        width: 350,
        textAlign: 'center',
    },
    connectionText: {
        marginTop: 30,
        fontSize: '1.125rem',
    },
    arrow: {
        color: theme.palette.primary.main,
        width: 140,
        height: 'auto',
        marginTop: 175,
        transform: 'translateY(-50%)',
    },
    [theme.breakpoints.down('md')]: {
        connection: {
            width: 250,
        },
        imageCircle: {
            width: 250,
            height: 250,
        },
        arrow: {
            marginTop: 125,
        },
    },
    [theme.breakpoints.down('sm')]: {
        connectionsHolder: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        arrow: {
            transform: 'rotate(90deg)',
            marginTop: 0,
        },
    },
}));

const GroupConnections: React.FC = () => {
    const styles = useStyles();
    const { t } = useTranslation('home');

    return (
        <div className={styles.root}>
            <Typography className={styles.title} variant="h3">
                {t('home:connections.title')}
            </Typography>
            <Grid container className={styles.connectionsHolder} spacing={8}>
                <Grid item>
                    <div className={styles.connection}>
                        <div className={styles.imageCircle} style={{ backgroundImage: `url(${libImg})` }}></div>
                        <Typography className={styles.connectionText}>{t('home:connections.book_authors')}</Typography>
                    </div>
                </Grid>
                <Grid item>
                    <DoubleArrow className={styles.arrow} />
                </Grid>
                <Grid item>
                    <div className={styles.connection}>
                        <div className={styles.imageCircle} style={{ backgroundImage: `url(${recordImg})` }}></div>
                        <Typography className={styles.connectionText}>{t('home:connections.narrators')}</Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default GroupConnections;
