import { AxiosInstance } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setError } from '../store/error/actions';
import useHttpErrorHandler from '../hooks/http-error-handler';

function withErrorHandler<P>(WrappedComponent: React.ComponentType<P>, axios: AxiosInstance) {
    return (props: P) => {
        const [error, clearError] = useHttpErrorHandler(axios);
        const dispatch = useDispatch();

        useEffect(() => {
            if (error) {
                dispatch(setError(error));
            }
        }, [dispatch, error]);

        return <WrappedComponent {...props} />;
    };
}

export default withErrorHandler;
