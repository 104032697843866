import { Error } from '../../models';
import { UserNamespaceShape } from './user/types';

export const USER_NAMESPACE_KEY = 'user';

export type AuthNamespaceShape = {
    token: string | null;
    [USER_NAMESPACE_KEY]: UserNamespaceShape | null;
    isAuthenticated: boolean;
    error: Error | null;
    loading: boolean;
    initLoading: boolean;
    changePasswordLoading: boolean;
};

export enum AuthActionTypes {
    SIGNUP = '@@auth/SIGNUP',
    SIGNUP_START = '@@auth/SIGNUP_START',
    SIGNUP_SUCCESS = '@@auth/SIGNUP_SUCCESS',
    SIGNUP_FAIL = '@@auth/SIGNUP_FAIL',
    LOGIN = '@@auth/LOGIN',
    LOGIN_START = '@@auth/LOGIN_START',
    LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
    LOGIN_FAIL = '@@auth/LOGIN_FAIL',
    LOGOUT = '@@auth/LOGOUT',
    LOGOUT_DONE = '@@auth/LOGOUT_DONE',
    GET_USER_INFO = '@@auth/GET_USER_INFO',
    SET_TOKEN = '@@auth/SET_TOKEN',
    SET_TOKEN_EXP_TIMEOUT = '@@auth/SET_TOKEN_EXP_TIMEOUT',
    INIT = '@@auth/INIT',
    INIT_START = '@@auth/INIT_START',
    INIT_DONE = '@@auth/INIT_DONE',
    REFRESH_TOKEN = '@@auth/REFRESH_TOKEN',
    CLEAR_ERROR = '@@auth/CLEAR_ERROR',

    CHANGE_PASSWORD = '@@auth/CHANGE_PASSWORD',
    CHANGE_PASSWORD_START = '@@auth/CHANGE_PASSWORD_START',
    CHANGE_PASSWORD_DONE = '@@auth/CHANGE_PASSWORD_DONE',
}

export interface ChangePasswordPayload {
    OldPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
}

export interface LoginPayload {
    email: string;
    password: string;
}

export interface LoginSuccessPayload {
    token: string;
}

export interface SignupPayload {
    email: string;
    userName: string;
    password: string;
    confirmPassword: string;
}
