import React from 'react';

interface DateFormatProps {
    date: string | Date;
    language?: string;
    format?: Intl.DateTimeFormatOptions;
}

const DateFormat = (props: DateFormatProps) => {
    return <React.Fragment>{new Intl.DateTimeFormat(props.language, props.format).format(new Date(props.date))}</React.Fragment>;
};

DateFormat.defaultProps = {
    language: navigator.language,
    format: { timeStyle: 'short', dateStyle: 'short' },
    timeZone: 'UTC',
};

export default DateFormat;
