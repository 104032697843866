import { Container, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import earnPic from '../img/zarabanie.png';
import LargeOrderedItem from './LargeOrderedItem';

const useStyles = makeStyles((theme: Theme) => ({
    topTitle: {
        marginBottom: 60,
    },
    btnHolder: {
        paddingLeft: 95,
    },
    btn: {
        color: '#8b8f98',
        borderColor: '#8b8f98',
        borderWidth: 2,
    },
    [theme.breakpoints.up('md')]: {
        withImg: {
            backgroundImage: `url(${earnPic})`,
            backgroundPosition: 'bottom right',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            minHeight: 450,
        },
        list: {
            maxWidth: '50%',
        },
    },
    [theme.breakpoints.down('xs')]: {
        btnHolder: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
        },
    },
}));

const WhyJoin: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation('home');

    return (
        <>
            <Container maxWidth="md">
                <Typography variant="h3" className={classes.topTitle}>
                    {t('home:reasons.title')}
                </Typography>
            </Container>
            <Container maxWidth="lg" className={classes.withImg}>
                <div className={classes.list}>
                    <LargeOrderedItem index={1} title={t('home:reasons.reason1.title')} description={t('home:reasons.reason1.description')} />
                    <LargeOrderedItem index={2} title={t('home:reasons.reason2.title')} description={t('home:reasons.reason2.description')} />
                    {/* <div className={classes.btnHolder}>
                        <HomeButton className={classes.btn} variant="outlined">
                            {t('home:reasons.know_more')}
                        </HomeButton>
                    </div> */}
                </div>
            </Container>
        </>
    );
};

export default WhyJoin;
