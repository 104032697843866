import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    List,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';
import { ArrowForward, CheckCircleOutline } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BookPartItem from '../../components/BookPartItem';
import Loader from '../../components/Loader';
import Title from '../../components/Title';
import UiRouterLink from '../../components/UiRouterLink';
import { BookPart } from '../../models';
import { selectAuth } from '../../store/auth/selectors';
import { clearBookDetail, closeReservationSuccessDialog, createReservation, getBookDetail } from '../../store/books/actions';
import { selectBookDetail, selectBooks } from '../../store/books/selectors';
import BookDetailInfo from './components/BookDetailInfo';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 100,
        color: theme.palette.grey[50],
    },
}));

interface BookDetailParams {
    id: string;
}

const BookDetail = () => {
    const { id }: BookDetailParams = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation(['translation', 'book']);
    const { user } = useSelector(selectAuth);
    const bookDetail = useSelector((state) => selectBookDetail(state));
    const { detailLoading } = useSelector(selectBooks);
    const { reservationLoading, reservationCreated } = useSelector(selectBooks);
    const { error } = useSelector(selectBooks);

    const isConfirmed = user!.Roles.length > 0;

    useEffect(() => {
        dispatch(getBookDetail(id));

        return () => {
            dispatch(clearBookDetail());
        };
    }, [dispatch, id]);

    if (detailLoading) {
        return <Loader />;
    }

    if (!bookDetail) {
        return null;
    }

    const handleReservePart = (part: BookPart) => {
        dispatch(createReservation(part.RecordableBookPartId));
    };

    const handleCloseReservationCreated = () => {
        dispatch(closeReservationSuccessDialog());
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className="lbx-paper">
                        <BookDetailInfo book={bookDetail} isUserConfirmed={isConfirmed} />
                    </Paper>
                </Grid>
                {bookDetail.BookParts > 0 && (
                    <Grid item xs={12}>
                        <Paper className="lbx-paper">
                            <Title gutterBottom>{t('book:chapters')}</Title>
                            {isConfirmed ? (
                                <List>
                                    {bookDetail.Parts.map((part: BookPart, index: number) => (
                                        <React.Fragment key={part.RecordableBookPartId}>
                                            {index > 0 && <Divider component="li" />}
                                            <BookPartItem
                                                part={part}
                                                actionButton={
                                                    <Button
                                                        disabled={!part.IsAvailableToReserve}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleReservePart(part)}>
                                                        {t('reserve')}
                                                    </Button>
                                                }
                                            />
                                        </React.Fragment>
                                    ))}
                                </List>
                            ) : (
                                <Typography color="textSecondary">{t('section_for_confirmed')}</Typography>
                            )}
                        </Paper>
                    </Grid>
                )}
            </Grid>

            <Backdrop className={classes.backdrop} open={reservationLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog open={reservationCreated} onClose={handleCloseReservationCreated}>
                <Box display="flex" justifyContent="center" mt={3} color="primary" fontSize="4rem">
                    <CheckCircleOutline fontSize="inherit" color="primary" />
                </Box>
                <DialogTitle style={{ textAlign: 'center' }}>{t('book:reservation_created.title')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        <Trans
                            i18nKey="book:reservation_created.content"
                            defaults="You can find all your reservations and reading material for your reservations in <rlink>My reservations</rlink> section."
                            components={{ rlink: <UiRouterLink onClick={handleCloseReservationCreated} to="/reservations" /> }}></Trans>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReservationCreated}>{t('close')}</Button>
                    <Button onClick={handleCloseReservationCreated} component={Link} to="/reservations" color="primary">
                        {t('book:see_my_reservations')} <ArrowForward fontSize="inherit" />
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default BookDetail;
