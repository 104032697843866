import { Container, Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import Title from '../../components/Title';
import useForm from '../../hooks/form';
import { FormControlsConfig } from '../../models';
import { changePassword } from '../../store/auth/actions';
import { selectAuth } from '../../store/auth/selectors';
import { ChangePasswordPayload } from '../../store/auth/types';

const useStyles = makeStyles((theme) => ({
    form: {
        // display: 'flex',
        // flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const formConfiguration: FormControlsConfig = {
    OldPassword: { rules: { required: true } },
    NewPassword: { rules: { required: true, minLength: 2 } },
    ConfirmPassword: { rules: { required: true, sameAs: 'NewPassword' } },
};

const ChangePassword = () => {
    const { t } = useTranslation(['translation', 'forms']);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { changePasswordLoading } = useSelector(selectAuth);

    const submit = (values: ChangePasswordPayload) => {
        dispatch(changePassword(values));
    };

    const { form, controls, getChangeListener, handleSubmit } = useForm(formConfiguration, submit);

    return (
        <Container maxWidth="xs">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper className="lbx-paper">
                        <Title gutterBottom>{t('change_password')}</Title>

                        <form className={classes.form} onSubmit={handleSubmit} noValidate>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                name="OldPassword"
                                error={(controls.OldPassword.isDirty || form.isSubmitted) && !controls.OldPassword.isValid}
                                helperText={controls.OldPassword.error}
                                value={controls.OldPassword.value}
                                onChange={getChangeListener('OldPassword')}
                                label={t('forms:old_password')}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                name="NewPassword"
                                error={(controls.NewPassword.isDirty || form.isSubmitted) && !controls.NewPassword.isValid}
                                helperText={controls.NewPassword.error}
                                value={controls.NewPassword.value}
                                onChange={getChangeListener('NewPassword')}
                                label={t('forms:new_password')}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                name="ConfirmPassword"
                                error={(controls.ConfirmPassword.isDirty || form.isSubmitted) && !controls.ConfirmPassword.isValid}
                                helperText={controls.ConfirmPassword.error}
                                value={controls.ConfirmPassword.value}
                                onChange={getChangeListener('ConfirmPassword')}
                                label={t('forms:confirm_password')}
                            />

                            <div className={classes.submit}>
                                <Button variant="contained" color="primary" type="submit" fullWidth loading={changePasswordLoading}>
                                    {t('save')}
                                </Button>
                            </div>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ChangePassword;
