import { Box, Container, Grid, Link, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import Title from '../../components/Title';
import useForm from '../../hooks/form';
import { FormControlsConfig } from '../../models';
import { sendSupportMessage } from '../../store/ui/actions';
import { selectUI } from '../../store/ui/selectors';
import { SupportMessagePayload } from '../../store/ui/types';

const useStyles = makeStyles((theme) => ({
    companyInfo: {
        marginTop: theme.spacing(2),
    },
    submitBtn: {
        marginTop: theme.spacing(2),
    },
}));

const formControls: FormControlsConfig = {
    Email: { initialValue: '', rules: { required: true, type: 'email' } },
    Text: { initialValue: '', rules: { required: true } },
};

const Contact = () => {
    const classes = useStyles();
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();

    const handleFormSubmit = (values: SupportMessagePayload) => {
        dispatch(sendSupportMessage(values));
    };

    const { controls, form, getChangeListener, handleSubmit } = useForm(formControls, handleFormSubmit);
    const { sentSupportMessage, supportMessageLoading } = useSelector(selectUI);

    return (
        <Container maxWidth="lg">
            <Paper className="lbx-paper">
                <Title variant="h3" gutterBottom>
                    {t('contact')}
                </Title>

                <Box mb={3}>
                    <Typography gutterBottom>{t('contact_text.first')}</Typography>
                    <Typography gutterBottom>
                        <Trans
                            i18nKey="contact_text.second"
                            defaults="Neváhejte nás kontaktovat pomocí formuláře níže, nebo na emailu <maillink>info@librixy.com</maillink>."
                            components={{ maillink: <Link href="mailto:info@librixy.com" /> }}></Trans>
                    </Typography>
                </Box>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={8}>
                        <form noValidate onSubmit={handleSubmit}>
                            <TextField
                                required
                                type="email"
                                name="Email"
                                error={(controls.Email.isDirty || form.isSubmitted) && !controls.Email.isValid}
                                helperText={controls.Email.error}
                                value={controls.Email.value}
                                onChange={getChangeListener('Email')}
                                label={t('email')}
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                required
                                error={(controls.Text.isDirty || form.isSubmitted) && !controls.Text.isValid}
                                helperText={controls.Text.error}
                                value={controls.Text.value}
                                onChange={getChangeListener('Text')}
                                multiline
                                rows={5}
                                label={t('message_text')}
                                margin="normal"
                                fullWidth
                            />

                            <Button
                                loading={supportMessageLoading}
                                disabled={sentSupportMessage}
                                size="large"
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.submitBtn}>
                                {t('send_message')}
                            </Button>
                        </form>
                    </Grid>

                    <Grid item xs>
                        <div className={classes.companyInfo}>
                            <Typography variant="subtitle2" gutterBottom>
                                {t('responsible_company')}:
                            </Typography>
                            <Typography component="address" variant="body2">
                                GROOP CZ s.r.o.
                                <br />
                                Vršovická 927/56, Vršovice
                                <br />
                                101 00 Praha 10, Česká republika
                                <br />
                                IČ: 24806731
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default Contact;
