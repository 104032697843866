import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import Button from '../../components/Button';
import UiRouterLink from '../../components/UiRouterLink';
import useForm from '../../hooks/form';
import { DefaultLocationState, FormControlsConfig } from '../../models';
import { clearError, login } from '../../store/auth/actions';
import { selectAuth } from '../../store/auth/selectors';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const formConfiguration: FormControlsConfig = {
    email: { rules: { required: true, type: 'email' } },
    password: { rules: { required: true } },
};

export default function SignIn() {
    const location = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation(['translation', 'forms']);

    useEffect(() => {
        return () => {
            dispatch(clearError());
        };
    }, [dispatch]);

    const submit = () => {
        dispatch(login(controls.email.value as string, controls.password.value as string));
    };

    const { form, controls, getChangeListener, handleSubmit } = useForm(formConfiguration, submit);

    const { isAuthenticated, loading, error } = useSelector(selectAuth);

    const { from }: DefaultLocationState = (location.state as DefaultLocationState) || { from: { pathname: '/dashboard' } };

    if (isAuthenticated) {
        return <Redirect to={from} />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('sign_in')}
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <TextField
                        margin="normal"
                        error={(controls.email.isDirty || form.isSubmitted) && !controls.email.isValid}
                        helperText={controls.email.error}
                        required
                        fullWidth
                        value={controls.email.value}
                        onChange={getChangeListener('email')}
                        id="email"
                        label={t('forms:email_address')}
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        error={(controls.password.isDirty || form.isSubmitted) && !controls.password.isValid}
                        required
                        fullWidth
                        value={controls.password.value}
                        onChange={getChangeListener('password')}
                        helperText={controls.password.error}
                        name="password"
                        label={t('forms:password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    {error && <Alert severity="error">{error.message}</Alert>}

                    <div className={classes.submit}>
                        <Button loading={loading} type="submit" fullWidth variant="contained" color="primary">
                            {t('sign_in_btn')}
                        </Button>
                    </div>
                    <Grid container>
                        {/* <Grid item xs>
                            <Link href="#" variant="body2">
                                {t('forgot_password_link')}
                            </Link>
                        </Grid> */}
                        <Grid item>
                            <UiRouterLink to="/signup" variant="body2">
                                {t('dont_have_acc')}
                            </UiRouterLink>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
