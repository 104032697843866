import { Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import appStore from '../img/apple-store-badge.svg';
import gPlay from '../img/google-play-badge.png';
import logo from '../img/group.png';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.common.black,
        paddingTop: 40,
        paddingBottom: 40,
        color: theme.palette.common.white,
    },
    contentHolder: {
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    appImg: {
        display: 'flex',
        width: 170,
        height: 50,
        flexShrink: 0,
        '& > img': {
            width: '100%',
        },
    },
    logoHolder: {
        width: 50,
        height: 50,
        display: 'inline-block',
        marginLeft: 20,
        marginRight: 20,
        verticalAlign: 'middle',
    },
    [theme.breakpoints.down('sm')]: {
        par: {
            textAlign: 'center',
        },
    },
    [theme.breakpoints.down('xs')]: {
        contentHolder: {
            flexDirection: 'column',
        },
        logoHolder: {
            display: 'none',
        },
    },
}));

interface AppsProps {}

const Apps: React.FC<AppsProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('home');

    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                <Grid container spacing={2} className={classes.contentHolder}>
                    <Grid item xs={12} md={'auto'}>
                        <Typography variant="body2" className={classes.par}>
                            <Trans
                                i18nKey="home:download"
                                defaults="Začnite hneď a stiahnite si <image/> aplikáciu"
                                components={{
                                    image: <img className={classes.logoHolder} src={logo} alt="Librixy logo" />,
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <a className={classes.appImg} href="https://apps.apple.com/app/librixy/id1451475063" rel="noreferrer" target="_blank">
                            <img src={appStore} alt="" />
                        </a>
                    </Grid>
                    <Grid item>
                        <a
                            className={classes.appImg}
                            href="https://play.google.com/store/apps/details?id=com.librixy.books"
                            rel="noreferrer"
                            target="_blank">
                            <img src={gPlay} alt="" />
                        </a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Apps;
