import { Avatar, Container, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import Button from '../../components/Button';
import UiRouterLink from '../../components/UiRouterLink';
import useForm from '../../hooks/form';
import { FormControlsConfig } from '../../models';
import { clearError, signup } from '../../store/auth/actions';
import { selectAuth } from '../../store/auth/selectors';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -10,
    },
    relative: {
        position: 'relative',
    },
}));

const formConfiguration: FormControlsConfig = {
    email: { rules: { required: true, type: 'email' } },
    password: { rules: { required: true, minLength: 6 } },
    confirmPassword: { rules: { required: true, sameAs: 'password' } },
    userName: { rules: { required: true, maxLength: 255 } },
};

const SignUp = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation(['translation', 'forms']);

    useEffect(() => {
        return () => {
            dispatch(clearError());
        };
    }, [dispatch]);

    const submit = () => {
        dispatch(
            signup(
                controls.userName.value as string,
                controls.email.value as string,
                controls.password.value as string,
                controls.confirmPassword.value as string
            )
        );
    };

    const { form, controls, getChangeListener, handleSubmit } = useForm(formConfiguration, submit);
    const { isAuthenticated, loading, error } = useSelector(selectAuth);

    if (isAuthenticated) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('sign_up')}
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                error={(controls.userName.isDirty || form.isSubmitted) && !controls.userName.isValid}
                                helperText={controls.userName.error}
                                required
                                fullWidth
                                id="userName"
                                label={t('forms:username')}
                                name="userName"
                                value={controls.userName.value}
                                onChange={getChangeListener('userName')}
                                autoComplete="userName"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={(controls.email.isDirty || form.isSubmitted) && !controls.email.isValid}
                                helperText={controls.email.error}
                                required
                                fullWidth
                                id="email"
                                label={t('forms:email_address')}
                                name="email"
                                value={controls.email.value}
                                onChange={getChangeListener('email')}
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={(controls.password.isDirty || form.isSubmitted) && !controls.password.isValid}
                                helperText={controls.password.error}
                                required
                                fullWidth
                                name="password"
                                value={controls.password.value}
                                onChange={getChangeListener('password')}
                                label={t('forms:password')}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={(controls.confirmPassword.isDirty || form.isSubmitted) && !controls.confirmPassword.isValid}
                                helperText={controls.confirmPassword.error}
                                required
                                fullWidth
                                name="confirmPassword"
                                value={controls.confirmPassword.value}
                                onChange={getChangeListener('confirmPassword')}
                                label={t('forms:confirm_password')}
                                type="password"
                                id="confirmPassword"
                                autoComplete="off"
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                        {error && (
                            <Grid item xs={12}>
                                <Alert severity="error">{error.message}</Alert>
                            </Grid>
                        )}
                    </Grid>

                    <div className={classes.submit}>
                        <Button type="submit" fullWidth variant="contained" color="primary" loading={loading}>
                            {t('sign_up_btn')}
                        </Button>
                    </div>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <UiRouterLink to="/login" variant="body2">
                                {t('already_have_acc')}
                            </UiRouterLink>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};
export default SignUp;
