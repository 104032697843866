import { Container, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { selectAuth } from 'store/auth/selectors';
import About from './components/About';
import Apps from './components/Apps';
import Banner from './components/Banner';
import GroupConnections from './components/GroupConnections';
import JoinUs from './components/JoinUs';
import Requirements from './components/Requirements';
import WhyJoin from './components/WhyJoin';

const useStyles = makeStyles((theme) => ({
    sectionHolder: {
        paddingTop: 60,
        paddingBottom: 60,
        overflowX: 'hidden',
    },
    grayBack: {
        backgroundColor: '#ededed',
    },
    primaryBack: {
        background: `linear-gradient(to top, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        color: 'white',
    },
    darkBack: {
        backgroundColor: '#14201c',
    },
}));

const Home = () => {
    const styles = useStyles();
    const { isAuthenticated } = useSelector(selectAuth);

    if (isAuthenticated) {
        return <Redirect to="/dashboard" />;
    }

    const handleSendInvitation = (email: string) => {
        console.log(email);
    };

    return (
        <div>
            <Banner />
            <Container maxWidth="lg">
                <div className={styles.sectionHolder}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <About />
                        </Grid>
                        <Grid item xs={12}>
                            <GroupConnections />
                        </Grid>
                        <Grid item xs={12}>
                            <JoinUs />
                        </Grid>
                        <Grid item xs={12}>
                            <Requirements />
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <div className={clsx(styles.grayBack, styles.sectionHolder)}>
                <WhyJoin />
            </div>
            {/* <div className={clsx(styles.primaryBack, styles.sectionHolder)}>
                <Invite onSendClick={handleSendInvitation} />
            </div> */}
            <Apps />
        </div>
    );
};

export default Home;
