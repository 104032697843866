import { Error } from '../../models';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '../../utils/create-reducer';
import { AuthActionTypes } from '../auth/types';
import { AudioActionTypes, AudioNamespaceShape, AudioState, PlayAudioPayload } from './types';

const playAudioFile = (state: AudioNamespaceShape, action: PayloadAction<PlayAudioPayload>) => {
    if (state.url) {
        URL.revokeObjectURL(state.url);
    }

    return {
        ...state,
        audioName: action.payload.audioName,
        url: action.payload.url,
        currentTime: 0,
        state: AudioState.PLAYING,
    };
};

const playAudioFileFail = (state: AudioNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        state: AudioState.ERROR,
    };
};

const setState = (state: AudioNamespaceShape, action: PayloadAction<AudioState>) => {
    return {
        ...state,
        state: action.payload,
    };
};

const setTotalTime = (state: AudioNamespaceShape, action: PayloadAction<number>) => {
    return {
        ...state,
        totalTime: action.payload,
    };
};

const setCurrentTime = (state: AudioNamespaceShape, action: PayloadAction<number>) => {
    return {
        ...state,
        currentTime: action.payload,
    };
};

const clearAudio = (state: AudioNamespaceShape, action: Action) => {
    return {
        ...state,
        ...initialState,
    };
};

const initialState: AudioNamespaceShape = {
    audioName: '',
    url: '',
    state: AudioState.STOPPED,
    totalTime: 0,
    currentTime: 0,
};

export default createReducer(initialState, {
    [AuthActionTypes.LOGOUT]: clearAudio,
    [AudioActionTypes.PLAY_AUDIO_FILE]: playAudioFile,
    [AudioActionTypes.SET_STATE]: setState,
    [AudioActionTypes.SET_TOTAL_TIME]: setTotalTime,
    [AudioActionTypes.SET_CURRENT_TIME]: setCurrentTime,
    [AudioActionTypes.PLAY_AUDIO_FILE_FAIL]: playAudioFileFail,
});
