import { Slide, Snackbar, SnackbarCloseReason } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from '../store/ui/actions';
import { selectUI } from '../store/ui/selectors';

const SnackbarMessage = () => {
    const ui = useSelector(selectUI);
    const dispatch = useDispatch();
    const { t } = useTranslation('serverError');

    const handleClose = (event: React.SyntheticEvent, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(hideMessage());
    };

    const message = ui.snackbarMessage ? t(ui.snackbarMessage) : '';

    return (
        <Snackbar
            onClose={handleClose}
            open={ui.snackbarIsOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={4000}
            TransitionComponent={Slide}>
            <Alert severity={ui.snackbarType}>{message}</Alert>
        </Snackbar>
    );
};

export default SnackbarMessage;
