import { InjectableModuleStore } from '../../models';
import reduce from './reducer';
import { watchAuth } from './sagas';
import { watchUser } from './user/sagas';

const moduleStore: InjectableModuleStore = {
    sagas: [watchAuth, watchUser],
    reducer: reduce,
};

export default moduleStore;
