import { Reservation, StoreEntity } from '../../models';

export interface ReservationsNamespaceShape {
    entities: StoreEntity<Reservation>;
    loading: boolean;
    error: Error | null;
}

export enum ReservationsActionTypes {
    SET_RESERVATIONS = '@@reservations/SET_RESERVATIONS',
}
