const throttle = (func: (...funcArgs: any[]) => void, limit: number) => {
    let inThrottle: any;
    return (...args: any[]) => {
        if (!inThrottle) {
            func(...args);
            inThrottle = setTimeout(() => (inThrottle = false), limit);
        }
    };
};

export default throttle;
