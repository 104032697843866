import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from '@material-ui/core';
import { Link as RLink, LinkProps as RouterLinkProps } from 'react-router-dom';

type MergedLinkProps = PropsWithChildren<LinkProps & RouterLinkProps>;

const UiRouterLink = ({ children, ...props }: MergedLinkProps) => {
    return (
        <Link component={RLink} {...props}>
            {children}
        </Link>
    );
};

export default UiRouterLink;
