import React, { PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import { useTranslation, withTranslation } from 'react-i18next';
import { MetaNamespaceShape } from '../store/meta/types';

type HeadProps = { meta: MetaNamespaceShape };

const Head = ({ meta, children }: PropsWithChildren<HeadProps>) => {
    const { i18n } = useTranslation(undefined, { useSuspense: false });
    return (
        <Helmet htmlAttributes={{ lang: i18n.language }}>
            <title>{meta.title}</title>

            <meta charSet="utf-8" />
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />

            <meta name="description" content={meta.description} />
            {children}
        </Helmet>
    );
};

export default Head;
