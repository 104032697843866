import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import picture from '../img/vsetky_knihy_pod_palcem.png';
import HomeButton from './HomeButton';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center',
        },
    },
    aboutText: {
        fontSize: '1.125rem',
        marginBottom: 50,
    },
    picture: {
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 500,
        },
    },
}));

const About: React.FC = () => {
    const styles = useStyles();
    const { t } = useTranslation('home');

    return (
        <Grid container spacing={4} className={styles.root}>
            <Grid item sm={4} md={7} lg="auto">
                <img className={styles.picture} src={picture} alt="" />
            </Grid>
            <Grid item xs>
                <Typography variant="h4" color="primary" gutterBottom>
                    {t('home:about.what_is_it')}
                </Typography>
                <Typography variant="body1" className={styles.aboutText}>
                    {t('home:about.description')}
                </Typography>
                <HomeButton component={Link} to="/help" variant="contained" color="primary" disableElevation>
                    {t('home:about.how_to_record')}
                </HomeButton>
            </Grid>
        </Grid>
    );
};

export default About;
