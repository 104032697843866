import { makeStyles } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

interface GridListProps {
    columnSize: number;
    spacing: number;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: (props: GridListProps) => `repeat(auto-fill, minmax(${props.columnSize}px, auto))`,
        gridGap: (props: GridListProps) => theme.spacing(props.spacing),
    },
}));

const GridList = (props: PropsWithChildren<any & GridListProps>) => {
    const { columnSize, spacing, className, ...restProps } = props;
    const classes = useStyles(props);
    const listClass = clsx(classes.root, className);
    return (
        <div className={listClass} {...restProps}>
            {props.children}
        </div>
    );
};

GridList.defaultProps = { columnSize: 250, spacing: 2 };

export default GridList;
