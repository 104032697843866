import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Overlay from '../../../components/Overlay';
import back from '../img/Background.png';
import HomeButton from './HomeButton';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 390,
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: '40%',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingTop: 60,
        paddingBottom: 60,
    },
    content: {
        position: 'relative',
        zIndex: 1,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    bannerTitle: {
        fontSize: 58,
        fontWeight: 600,
        marginBottom: 10,
    },
    bannerSub: {
        marginBottom: 20,
        fontWeight: 500,
    },
    bannerButton: {
        width: 274,
    },
}));

interface BannerProps {
    //
}

const Banner: React.FC<BannerProps> = (props) => {
    const styles = useStyles();
    const { t } = useTranslation('home');

    return (
        <div className={styles.root} style={{ backgroundImage: 'url(' + back + ')' }}>
            <Overlay color="#14201b" />
            <div className={styles.content}>
                <Typography className={styles.bannerTitle} variant="h2">
                    Librixy Voice
                </Typography>
                <Typography variant="h4" className={styles.bannerSub}>
                    {t('home:banner.title')}
                </Typography>
                <HomeButton component={Link} className={styles.bannerButton} variant="contained" color="primary" to="/signup" disableElevation>
                    {t('home:banner.create_account')}
                </HomeButton>
            </div>
        </div>
    );
};

export default Banner;
