import { Typography } from '@material-ui/core';
import React from 'react';
import Title from '../../../components/Title';
import { recordSteps1, recordSteps2, recordSteps3, recordSteps4 } from './recordSteps';
import StepsGroup from './StepsGroup';

const Workflow = ({ classes }: { classes: Record<string, string> }) => {
    return (
        <React.Fragment>
            <div className={classes.section}>
                <Title variant="h3" gutterBottom>
                    Workflow neboli průběh práce
                </Title>
                <Typography>
                    Postupem času si jistě najdete ten nejlepší pracovní postup, který se vám osvědčí. Pro ilustraci vám popíšeme základní scénáře,
                    jak byste mohli svoji tvorbu efektivně nastavit.
                </Typography>
            </div>

            <div className={classes.section}>
                <Title gutterBottom>Scénář 1 – Vše z mobilu</Title>
                <StepsGroup steps={recordSteps1} />
            </div>

            <div className={classes.section}>
                <Title gutterBottom>Scénář 2 – Vše z PC</Title>
                <StepsGroup steps={recordSteps2} />
            </div>

            <div className={classes.section}>
                <Title gutterBottom>Scénář 3 – Kombinace smartphonu a PC</Title>
                <Typography variant="h6" gutterBottom>
                    Varianta 1
                </Typography>
                <StepsGroup steps={recordSteps3} />
            </div>

            <div className={classes.section}>
                <Typography variant="h6" gutterBottom>
                    Varianta 2
                </Typography>
                <StepsGroup steps={recordSteps4} />
            </div>

            <div className={classes.section}>
                <Typography>
                    Importování jednotlivých zvukových nahrávek můžete zajistit s pomocí jednak s pomocí kabelového propojení, nebo například díky
                    nahráním zvukové stopy do cloudového úložiště, z něhož si poté do druhého zařízení nahrávku stáhnete.
                </Typography>
            </div>
        </React.Fragment>
    );
};

export default Workflow;
