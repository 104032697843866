import { Link, Typography, TypographyProps } from '@material-ui/core';
import React from 'react';

const Copyright = (props: TypographyProps) => {
    return (
        <Typography variant="body2" color="textSecondary" display="inline" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.librixy.com/">
                Librixy
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
};

export default Copyright;
