import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GridList from '../../components/GridList';
import Loader from '../../components/Loader';
import BookListItem from '../books/components/BookListItem';

interface AvailableBooksProps {
    bookIds: string[];
    onLoadMore: () => void;
    showLoadMore: boolean;
    isLoading: boolean;
    listClassName: any;
}

const AvailableBooks = (props: AvailableBooksProps) => {
    const { t } = useTranslation('translation');
    return (
        <React.Fragment>
            <GridList spacing={3} className={props.listClassName}>
                {props.bookIds.map((id) => (
                    <BookListItem key={id} bookId={id} />
                ))}
            </GridList>

            {props.isLoading ? (
                <Loader size={32} />
            ) : props.showLoadMore ? (
                <Button variant="contained" color="primary" disableElevation onClick={props.onLoadMore}>
                    {t('load_more')}
                </Button>
            ) : null}
        </React.Fragment>
    );
};

export default AvailableBooks;
