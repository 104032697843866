import { Action, PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '../../utils/create-reducer';
import { AuthActionTypes, AuthNamespaceShape, LoginSuccessPayload, USER_NAMESPACE_KEY } from './types';
import reducer from './user/reducer';
import { UserActionTypes } from './user/types';

const initAuthStart = (state: AuthNamespaceShape, action: Action) => ({
    ...state,
    initLoading: true,
});

const initAuthDone = (state: AuthNamespaceShape, action: Action) => ({
    ...state,
    isAuthenticated: Boolean(!!state.token && state.user && state.user.Id),
    initLoading: false,
});

const authStart = (state: AuthNamespaceShape, action: Action) => {
    return {
        ...state,
        error: null,
        loading: true,
    };
};

const authSuccess = (state: AuthNamespaceShape, action: PayloadAction<LoginSuccessPayload>) => {
    return {
        ...state,
        loading: false,
        isAuthenticated: true,
    };
};

const authFail = (state: AuthNamespaceShape, action: PayloadAction<Error>) => {
    return {
        ...state,
        error: action.payload,
        loading: false,
    };
};

const logoutDone = (state: AuthNamespaceShape, action: Action) => {
    return {
        ...state,
        isAuthenticated: false,
        [USER_NAMESPACE_KEY]: null,
        token: null,
    };
};

const setToken = (state: AuthNamespaceShape, action: PayloadAction<string>) => {
    return {
        ...state,
        token: action.payload,
    };
};

const changePasswordStart = (state: AuthNamespaceShape, action: Action) => {
    return {
        ...state,
        changePasswordLoading: true,
    };
};

const changePasswordDone = (state: AuthNamespaceShape, action: Action) => {
    return {
        ...state,
        changePasswordLoading: false,
    };
};

const clearError = (state: AuthNamespaceShape, action: Action) => {
    return {
        ...state,
        error: null,
    };
};

const initialState: AuthNamespaceShape = {
    token: null,
    [USER_NAMESPACE_KEY]: null,
    error: null,
    loading: false,
    initLoading: true,
    isAuthenticated: false,
    changePasswordLoading: false,
};

const reducerMap = {
    [AuthActionTypes.LOGIN_START]: authStart,
    [AuthActionTypes.SIGNUP_START]: authStart,
    [AuthActionTypes.LOGIN_SUCCESS]: authSuccess,
    [AuthActionTypes.SIGNUP_SUCCESS]: authSuccess,
    [AuthActionTypes.SET_TOKEN]: setToken,
    [AuthActionTypes.LOGIN_FAIL]: authFail,
    [AuthActionTypes.SIGNUP_FAIL]: authFail,
    [AuthActionTypes.LOGOUT_DONE]: logoutDone,
    [AuthActionTypes.INIT_START]: initAuthStart,
    [AuthActionTypes.INIT_DONE]: initAuthDone,
    [AuthActionTypes.CLEAR_ERROR]: clearError,

    [AuthActionTypes.CHANGE_PASSWORD_START]: changePasswordStart,
    [AuthActionTypes.CHANGE_PASSWORD_DONE]: changePasswordDone,
};

const authReducer = createReducer<AuthNamespaceShape>(initialState, reducerMap);

const reduce = (state = initialState, action: Action) => {
    const newAuthState = authReducer(state, action);

    return {
        ...newAuthState,
        [USER_NAMESPACE_KEY]: reducer(newAuthState[USER_NAMESPACE_KEY], action),
    };
};

export default reduce;
