import { Reducer } from 'redux';
import { NamespacedAction } from '../models/namespaced-action';

export const createNamespacedReducer = (namespace: string, reduce: Reducer) => (state: any, action: NamespacedAction<any>) => {
    if (!action.namespace || !action.namespace.startsWith(namespace)) {
        return state;
    }

    return reduce(state, action);
};
