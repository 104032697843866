export const __PROD__ = process.env.NODE_ENV === 'production';
export const __API_URL__ = process.env.REACT_APP_API_URL;

export const __IS_SNAPSHOT__ = navigator.userAgent === 'ReactSnap';

export const __DEFAULT_LANGUAGE__ = 'cs';

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MAX_SAMPLE_SIZE = 50 * 1000 * 1000;
export const MAX_RECORD_SIZE = 500 * 1000 * 1000;

export const STORAGE = {
    token: '_t',
    expirationDate: '_ed',
    drawerOpened: '_do',
};
