import { getStateAtNamespaceKey } from '../../utils/selectors';
import { RecordState, StoreShape, RECORDS_NAMESPACE_KEY, Record } from '../../models';

export const selectRecords = (state: StoreShape) => getStateAtNamespaceKey(state, RECORDS_NAMESPACE_KEY);

export const selectRecordsLoading = (state: StoreShape) => {
    return selectRecords(state).loading;
};

export const selectRecordsAsList = (state: StoreShape) => {
    const recordsState = selectRecords(state);
    return recordsState.entities.allIds.map((id) => recordsState.entities.byId[id]);
};

export const selectRecordsByState = (state: StoreShape) => {
    const records = selectRecordsAsList(state);
    const groupping = Object.keys(RecordState).reduce((acc: any, state) => ({ ...acc, [state]: [] }), {});

    return records.reduce((acc: typeof groupping, record: Record) => ({ ...acc, [record.State]: [...(acc[record.State] || []), record] }), groupping);
};
