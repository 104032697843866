import { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '../../utils/create-reducer';
import { Sample } from './../../models';
import { SamplesActionTypes, SamplesNamespaceShape, SetSampleLoadingPayload, UpdateSampleStatePayload } from './types';

const appendSamples = (state: SamplesNamespaceShape, action: PayloadAction<Sample[]>) => {
    const updatedSamples = action.payload.reduce(
        (acc, sample: Sample) => ({
            ...acc,
            [sample.Id]: sample,
        }),
        { ...state.entities.byId }
    );

    return {
        ...state,
        entities: {
            ...state.entities,
            byId: updatedSamples,
            allIds: Object.keys(updatedSamples),
        },
    };
};

const updateSampleState = (state: SamplesNamespaceShape, action: PayloadAction<UpdateSampleStatePayload>) => {
    const updatedSample = { ...state.entities.byId[action.payload.sampleId], State: action.payload.newState };
    return {
        ...state,
        entities: {
            ...state.entities,
            byId: {
                ...state.entities.byId,
                [action.payload.sampleId]: updatedSample,
            },
        },
    };
};

const setSampleLoading = (state: SamplesNamespaceShape, action: PayloadAction<SetSampleLoadingPayload>) => {
    const updatedSample = { ...state.entities.byId[action.payload.sampleId], loading: action.payload.loading };
    return {
        ...state,
        entities: {
            ...state.entities,
            byId: {
                ...state.entities.byId,
                [action.payload.sampleId]: updatedSample,
            },
        },
    };
};

const initialState: SamplesNamespaceShape = {
    entities: {
        byId: {},
        allIds: [],
    },
    error: null,
    samplesLoading: false,
};

const reducerMap = {
    [SamplesActionTypes.APPEND_SAMPLES]: appendSamples,
    [SamplesActionTypes.UPDATE_SAMPLE_STATE]: updateSampleState,
    [SamplesActionTypes.SET_SAMPLE_LOADING]: setSampleLoading,
};

export default createReducer(initialState, reducerMap);
