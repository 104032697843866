import { createAction } from '@reduxjs/toolkit';
import { Sample, SampleState } from '../../models';
import { SamplesActionTypes } from './types';

export const updateSampleState = createAction(SamplesActionTypes.UPDATE_SAMPLE_STATE, (sampleId: string, newState: SampleState) => ({
    payload: { sampleId, newState },
}));

export const setSampleLoading = createAction(SamplesActionTypes.SET_SAMPLE_LOADING, (sampleId: string, loading: boolean) => ({
    payload: { sampleId, loading },
}));

export const appendSamples = createAction<Sample[]>(SamplesActionTypes.APPEND_SAMPLES);
