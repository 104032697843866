import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { selectAuth } from '../store/auth/selectors';
import { UserRole } from '../models';

type ProtectedRouteProps = { role?: UserRole | UserRole[] } & RouteProps;

const ProtectedRoute = ({ component: Component, role, ...routeProps }: ProtectedRouteProps) => {
    const { user, isAuthenticated } = useSelector(selectAuth);

    if (!Component) {
        return null;
    }

    const render = (props: any) => {
        if (!isAuthenticated) {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        if (role) {
            if (Array.isArray(role)) {
                if (!user?.Roles.some((r) => role.includes(r))) {
                    return <Redirect to="/unauthorized" />;
                }
            } else if (!user?.Roles.includes(role as UserRole)) {
                return <Redirect to="/unauthorized" />;
            }
        }
        return <Component {...routeProps} {...props} />;
    };

    return <Route {...routeProps} render={render} />;
};

export default ProtectedRoute;
