import { Badge } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Assignment, AssignmentInd, RecordVoiceOver } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import PeopleIcon from '@material-ui/icons/People';
import React from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const mainListItems = (onClick: () => void) => (
    <div>
        <ListItem button component={Link} to="/dashboard" onClick={onClick}>
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={<Translation>{(t) => t('dashboard')}</Translation>} />
        </ListItem>
    </div>
);

export const confirmedUserItems = (onClick: () => void, newReservationsCount: number) => (
    <div>
        <ListItem button component={Link} to="/reservations" onClick={onClick}>
            <ListItemIcon>
                <Badge badgeContent={newReservationsCount} color="error">
                    <Assignment />
                </Badge>
            </ListItemIcon>
            <ListItemText primary={<Translation>{(t) => t('my_reservations')}</Translation>} />
        </ListItem>
    </div>
);

export const adminListItems = (onClick: () => void) => (
    <div>
        <ListSubheader inset>
            <Translation>{(t) => t('admin')}</Translation>
        </ListSubheader>
        <ListItem onClick={onClick} button component={Link} to="/admin">
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={<Translation>{(t) => t('users')}</Translation>} />
        </ListItem>
        <ListItem onClick={onClick} button component={Link} to="/admin/books">
            <ListItemIcon>
                <LibraryBooks />
            </ListItemIcon>
            <ListItemText primary={<Translation>{(t) => t('books')}</Translation>} />
        </ListItem>
        <ListItem onClick={onClick} button component={Link} to="/admin/records">
            <ListItemIcon>
                <RecordVoiceOver />
            </ListItemIcon>
            <ListItemText primary={<Translation>{(t) => t('records')}</Translation>} />
        </ListItem>
        <ListItem onClick={onClick} button component={Link} to="/admin/reservations">
            <ListItemIcon>
                <AssignmentInd />
            </ListItemIcon>
            <ListItemText primary={<Translation>{(t) => t('reservations')}</Translation>} />
        </ListItem>
    </div>
);
