import { SvgIcon } from '@material-ui/core';
import React from 'react';

interface DoubleArrowProps {
    className?: string;
    width?: number;
    height?: number;
}

const DoubleArrow: React.FC<DoubleArrowProps> = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 135 54" preserveAspectRatio="xMidYMid meet">
            <path d="M0 27 27 0v13.5h81V0l27 27-27 27V40.5H27V54z" fillRule="evenodd" />
        </SvgIcon>
    );
};

export default DoubleArrow;
