import { Button, ButtonBaseProps, ButtonProps, Hidden, IconButton, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BookDetail } from '../../../models';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'start',
        margin: theme.spacing(-1),
        position: 'relative',
        '& > *': {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    media: {
        flexShrink: 0,
        alignSelf: 'stretch',
        backgroundColor: theme.palette.grey[300],
        backgroundSize: 'cover',
        width: 150,
        height: 150 * 1.5,
    },
    content: {
        flex: '1 1 auto',
    },
    downloadBtn: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    bookName: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    authors: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    bookMeta: {
        marginBottom: theme.spacing(4),
    },
}));

interface BookDetailInfoProps {
    book: BookDetail;
    isUserConfirmed: boolean;
}

const BookDetailInfo = ({ book, isUserConfirmed }: BookDetailInfoProps) => {
    const classes = useStyles();
    const { t } = useTranslation('book');

    return (
        <div className={classes.root}>
            <div>
                <div
                    className={classes.media}
                    style={{ backgroundImage: `url(https://librixyrecords.blob.core.windows.net/images/bookCover/${book.BookId}.jpg)` }}
                />
            </div>
            <div className={classes.content}>
                <Typography className={classes.bookName} gutterBottom variant="h5" component="h2">
                    {book.Name} {book.ReleaseYear && `(${book.ReleaseYear})`}
                </Typography>
                <div className={classes.bookMeta}>
                    <Typography className={classes.authors} gutterBottom variant="body2" color="textSecondary" component="p">
                        {book.Authors}
                    </Typography>
                    <Typography className={classes.authors} gutterBottom variant="body2" color="textSecondary" component="p">
                        {t('book:pages_count', { count: book.Pages })}, {t('book:chapters_count', { count: book.Parts.length })}
                    </Typography>
                </div>

                <Typography variant="body1" color="textSecondary" component="p">
                    {book.Description}
                </Typography>
            </div>
        </div>
    );
};

export default BookDetailInfo;
