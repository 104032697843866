import { InjectableModuleStore } from './../../models';
import reduce from './reducer';
import watchUI from './sagas';

const mod: InjectableModuleStore = {
    reducer: reduce,
    sagas: [watchUI],
};

export default mod;
